import { ChangeEvent, ChangeEventHandler, createRef, useEffect, useState } from "react";
import FormInput, { Feedback } from "./FormInput";
import Select from "./FormSelect";
import ExtendedInputHTMLAttributes from "./ExtendedInputHTMLAttributes";
import { useTranslation } from "react-i18next";
import simulateChangeEvent from "./simulateChangeEvent";
import FormRequired from "./FormRequired";


const PhoneNumberCountryCodes = [
  { id: 'pl', cultures: ['pl'], label: '+48', value: '48', length: [9], pattern: '^[0-9]{9}$' },
]

interface PhoneNumberFormComponents extends ExtendedInputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  selectClassName?: string;
  inputClassName?: string;
}

const PhoneNumberForm = (props: PhoneNumberFormComponents) => {
  const {
    name, value, placeholder, disabled, readOnly, required, onChange,
    containerClassName, selectClassName, inputClassName,
    ...otherProps
  } = props;
  const { t, i18n } = useTranslation();
  const [countryCode, setCountryCode] = useState<string>();
  const [number, setNumber] = useState<string>();
  const inputRef = createRef<HTMLInputElement>();

  const onChangeCountryCode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCountryCode(e.target.value);
    onChangeMiddleware(e);
  }

  const onChangeNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNumber(e.target.value);
    onChangeMiddleware(e);
  }

  const getFormattedValue = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target.name !== 'number' && e?.target.name !== 'countryCode') return value;
    if ((e && e.target.name === 'number' && !e.target.value) || (!number)) return '';
    if (!e) return (countryCode || '') + (number || '');
    else if (e.target.name === 'countryCode') return e.target.value + (number || '');
    else if (e.target.name === 'number') return (countryCode || '') + e.target.value;
  }

  const decomposeValue = (composedNumber?: string) => {
    if (!composedNumber) return ['', ''];
    let newNumber = '';
    let newCountryCode = '';
    const tmp = composedNumber
      .trim()
      .replace('+', '')
      .replace(' ', '');
    PhoneNumberCountryCodes.map(cc => {
      if (!tmp.startsWith(cc.value)) return false;
      const t = tmp.substring(cc.value.length);
      if (cc.length.length == 1 && t.length != cc.length[0]) return false;
      if (cc.length.length == 2 && (t.length < cc.length[0] || t.length > cc.length[1])) return false;
      newCountryCode = cc.value;
      newNumber = t;
      return true;
    });
    return [newCountryCode || '', newNumber || ''];
  }

  const onChangeMiddleware = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const formattedValue = getFormattedValue(e);
      if (formattedValue === value) return;
      const event = { ...e, target: { ...e.target, name: name!, id: name!, value: formattedValue } } as ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
  }

  useEffect(() => {
    if (!countryCode && i18n.resolvedLanguage) {
      const cultureCountryCode = PhoneNumberCountryCodes.find(cc => cc.cultures.includes(i18n.resolvedLanguage!));
      if (cultureCountryCode) {
        setCountryCode(cultureCountryCode.value);
        return;
      }
      setCountryCode(PhoneNumberCountryCodes.find(_cc => true)?.value);
    }
  }, [i18n.resolvedLanguage, countryCode]);

  useEffect(() => {
    const [newCountryCode, newNumber] = decomposeValue(String(value));
    if (newCountryCode && newNumber) {
      setCountryCode(newCountryCode);
      setNumber(newNumber);
      simulateChangeEvent(inputRef.current, `${newCountryCode}${newNumber}`);
    }
  }, [value]);

  const currentCountryCode = PhoneNumberCountryCodes.find(cc => cc.value === countryCode);

  return (
    <>
      <div className={containerClassName}>
        <label htmlFor="number" className="block text-xs font-medium text-gray-900">{placeholder}<FormRequired required={required} /></label>
        <div className="flex" >
          <div className="flex-initial w-24" >
            <Select
              name="countryCode"
              value={countryCode}
              onChange={onChangeCountryCode}
              options={PhoneNumberCountryCodes}
              className={selectClassName}
              disabled={disabled}
            />
          </div>
          <div className="flex-initial w-48" >
            <FormInput.WithoutLabel
              name="number"
              type="text"
              min={0}
              step={1}
              value={number}
              onChange={onChangeNumber}
              className={inputClassName}
              placeholder={t('auth.fields.phoneNumber')}
              minLength={currentCountryCode?.length ? currentCountryCode.length[0] : undefined}
              maxLength={currentCountryCode?.length ? (currentCountryCode.length.length == 2 ? currentCountryCode.length[1] : currentCountryCode.length[0]) : undefined}
              disabled={disabled}
              required={required}
              readOnly={readOnly}
              pattern={currentCountryCode?.pattern}
            />
          </div>
          <input ref={inputRef} type="hidden" name={name} onChange={onChangeMiddleware} />
        </div>
        <Feedback {...otherProps} />
      </div>
    </>
  )
}

export const PhoneNumberFormInset = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  return <PhoneNumberForm
    {...props}
    containerClassName="bg-white rounded-md px-3 pb-1.5 pt-2.5 shadow-sm relative ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-[--color-primary-600]"
    selectClassName="ring-0 border-0"
    inputClassName="ring-0 border-0"
  />;
}

export default PhoneNumberForm;