import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Settings, SettingsClient } from "src/api/core/Core";
import { CurrenciesClient } from "src/api/financial/Accountancy";
import { HttpQueryFilter, Rider, RidersClient } from "src/api/stable/Stable";
import Button from "src/components/Actions/Button";
import BookingWorkingHours, { daysOfWeek } from "src/components/Booking/BookingWorkingHours";
import { PromptDialog } from "src/components/Dialogs/Dialog";
import Alert from "src/components/Feedback/Alert";
import Spinner from "src/components/Feedback/Spinner";
import Toast from "src/components/Feedback/Toast";
import FormInput from "src/components/Form/FormInput";
import { TextOptionProps } from "src/components/Form/FormOptions";
import Select from "src/components/Form/FormSelect";
import FormSwitch from "src/components/Form/FormSwitch";
import getCurrencyName from "src/components/i18n/getCurrencyName";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useConfigurationDispatch from "src/hooks/useConfigurationDispatch";
import useConfigurationState from "src/hooks/useConfigurationState";

import { setConfiguration } from "src/store/configuration/actions";
import { AuthorizationScope } from "src/store/configuration/state";

const BookingSettingsSection = () => {
  const { t, i18n } = useTranslation();
  const configurationDispatch = useConfigurationDispatch();
  const configurationState = useConfigurationState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);
  const ridersApiClient = new RidersClient(apiConfiguration);
  const currenciesApiClient = new CurrenciesClient(apiConfiguration);
  const [ currencies, setCurrencies] = useState<TextOptionProps[]>([]);
  const [verifyAllPromptOpen, verifyAllPromptSetOpen] = useState(false);
  const [allowedToCancel, setAllowedToCancel] = useState(false);

  useEffect(() => {
    setAllowedToCancel(configurationState?.booking?.cancelTimeFrame !== undefined && configurationState.booking.cancelTimeFrame > 0);
  }, [configurationState.booking.cancelTimeFrame ]);

  useEffect(() => {
    console.log(allowedToCancel);
  }, [ allowedToCancel]);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const onSaveSuccess = () => {
    Toast.success(t("common.status.success"), t("common.feedback.saved"));
  }

  const onSaveFailure = () => {
    setError(true);
    Toast.error(t("common.status.error"), t("common.errors.500"));
  }

  const fetchCurrencies = () => {
    currenciesApiClient.get(undefined, undefined, undefined, undefined, undefined, undefined)
      .then((response) => setCurrencies(response.items?.map(c => ({ id: c.id, value: c.id, label: getCurrencyName(c.id, i18n.resolvedLanguage) ?? '' } as TextOptionProps)) ?? []))
      .catch(() => setCurrencies([]));
  }

  const onSave = () => {
    setLoading(true);
    setError(false);
    apiClient.set('booking', undefined, { name: 'booking', value: configurationState.booking } as Settings)
      .catch(onSaveFailure)
      .then(onSaveSuccess)
      .finally(() => setLoading(false));
  }

  const verifyAllRiders = async () => {
    setLoading(true);
    try
    {
      const unverified = await ridersApiClient.get([{ property: 'isVerified', value: '', type: 'isNull' }] as HttpQueryFilter[], undefined, 1000, undefined, undefined, undefined);
      for(const rider of unverified.items ?? [])
      {
        await ridersApiClient.update(rider.id!, { ...rider, isVerified: true } as Rider);
      }
      Toast.success(t("common.status.success"), t("common.feedback.updated"));
    }
    catch
    {
      Toast.error(t("common.status.error"), t("common.errors.500"));
    }
    finally
    {
      setLoading(false);
    }
  }

  const authorizationScope = [
    { id: String(AuthorizationScope.Any), value: AuthorizationScope.Any, label: t('accessScope.any'), disabled: !configurationState.booking.guests },
    { id: String(AuthorizationScope.Users), value: AuthorizationScope.Users, label: t('accessScope.users') },
    { id: String(AuthorizationScope.Noone), value: AuthorizationScope.Noone, label: t('accessScope.noone') },
  ];

  return (
    <>
      {error && <Alert.Error title={t('common.errors.500')} noClose />}
      <PromptDialog
        open={verifyAllPromptOpen}
        setOpen={verifyAllPromptSetOpen}
        title={t('stable.riders.verifyAll')}
        onClickYes={() => verifyAllRiders()}
        closeOnFeedback
      >
        <div className="flex flex-col gap-y-5">
          <p>{t('stable.riders.verifyAllPrompt.description')}</p>
          <p className="text-gray-500">{t('stable.riders.verifyAllPrompt.info')}</p>
        </div>
      </PromptDialog>
      <dl className="my-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('auth.section')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.guests}
                onChange={(e) => configurationDispatch(setConfiguration('booking.guests', e.target.checked))}
                placeholder={t('panel.settings.booking.guests.title')}
                aria-description={t('panel.settings.booking.guests.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.requireVerification}
                onChange={(e) => configurationDispatch(setConfiguration('booking.requireVerification', e.target.checked))}
                placeholder={t('panel.settings.booking.requireVerification.title')}
                aria-description={t('panel.settings.booking.requireVerification.description')}
              />
              <p className="text-xs text-gray-500">{t('panel.settings.booking.requireVerification.information')}</p>
            </div>
            <Button type="button" onClick={() => verifyAllPromptSetOpen(true)} className="px-3 py-2 text-sm ml-auto">
              {t('stable.riders.verifyAll')}
            </Button>
            {loading && <Spinner />}
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">
            {t('wizard.header')}
            </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.requireEquestrianCentreAgreements}
                onChange={(e) => configurationDispatch(setConfiguration('booking.requireEquestrianCentreAgreements', e.target.checked))}
                placeholder={t('panel.settings.booking.requireEquestrianCentreAgreements.title')}
                aria-description={t('panel.settings.booking.requireEquestrianCentreAgreements.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.requirePhoneNumber}
                onChange={(e) => configurationDispatch(setConfiguration('booking.requirePhoneNumber', e.target.checked))}
                placeholder={t('panel.settings.booking.requirePhoneNumber.title')}
                aria-description={t('panel.settings.booking.requirePhoneNumber.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.isHorseOccupiedBeforeEnabled}
                onChange={(e) => configurationDispatch(setConfiguration('booking.isHorseOccupiedBeforeEnabled', e.target.checked))}
                placeholder={t('panel.settings.booking.isHorseOccupiedBeforeEnabled.title')}
                aria-description={t('panel.settings.booking.isHorseOccupiedBeforeEnabled.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.automaticHorseAssignment}
                onChange={(e) => configurationDispatch(setConfiguration('booking.automaticHorseAssignment', e.target.checked))}
                placeholder={t('panel.settings.booking.automaticHorseAssignment.title')}
                aria-description={t('panel.settings.booking.automaticHorseAssignment.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.granularity.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                name="trainingType"
                options={[
                  { id: '30', value: '30', label: t('panel.settings.booking.granularity.30') },
                  { id: '60', value: '60', label: t('panel.settings.booking.granularity.60') },
                ]}
                value={configurationState.booking.granularity ?? 30}
                placeholder={t('panel.settings.booking.granularity.title')}
                aria-description={t('panel.settings.booking.granularity.description')}
                onChange={(e) => configurationDispatch(setConfiguration('booking.granularity', Number(e.target.value)))}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.instructorSelection.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                name="instructor"
                options={authorizationScope}
                value={configurationState.booking.selectInstructor}
                placeholder={t('panel.settings.booking.instructorSelection.title')}
                aria-description={t('panel.settings.booking.instructorSelection.description')}
                onChange={(e) => configurationDispatch(setConfiguration('booking.selectInstructor', e.target.value))}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.horseSelection.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                name="horse"
                options={authorizationScope}
                value={configurationState.booking.selectHorse}
                placeholder={t('panel.settings.booking.horseSelection.title')}
                aria-description={t('panel.settings.booking.horseSelection.description')}
                onChange={(e) => configurationDispatch(setConfiguration('booking.selectHorse', e.target.value))}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.workingHours.title')}</dt>
          <dd className="mt-1 mb-6 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <table className="max-w-full">
              {daysOfWeek
                .map((d, i) => <BookingWorkingHours
                  key={i}
                  dayOfWeek={d}
                  value={_.get(configurationState.booking.hours, d)}
                  onChange={(value) => configurationDispatch(setConfiguration(`booking.hours.${d}`, value))}
                />)}
            </table>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.currency.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="flex flex-col gap-y-3 mb-3">
              <div>
                <Select
                  name="currency"
                  options={currencies}
                  value={configurationState.booking.currency}
                  placeholder={t('panel.settings.booking.currency.title')}
                  aria-description={t('panel.settings.booking.currency.description')}
                  onChange={(e) => configurationDispatch(setConfiguration('booking.currency', e.target.value))}
                  className="w-auto"
                />
              </div>
              <p className="text-gray-500 text-xs">
                {t('panel.settings.booking.currency.exclamation')}
              </p>
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.cancel.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="flex flex-col gap-y-3 mb-3">
              <div>
                <FormSwitch.Decorated
                  name="allowedToCancel"
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setAllowedToCancel(checked);
                    if (!checked) {
                      configurationDispatch(setConfiguration('booking.cancelTimeFrame', undefined));
                    }
                  }}
                  checked={allowedToCancel}
                  placeholder={t('panel.settings.booking.cancel.title')}
                  aria-description={t('panel.settings.booking.cancel.description')}
                />
              </div>
              <div className={allowedToCancel ? 'opacity-100' : 'opacity-50'}>
                <FormInput.Default
                  name="cancelTimeFrame"
                  type="number"
                  value={configurationState.booking.cancelTimeFrame}
                  placeholder={t('panel.settings.booking.cancelTimeFrame.title')}
                  onChange={(e) => configurationDispatch(setConfiguration('booking.cancelTimeFrame', Number(e.target.value)))}
                  className="w-auto"
                  min={1}
                  required={allowedToCancel}
                  readOnly={!allowedToCancel}
                />
              </div>
              <p className="text-gray-500 text-xs">
                {t('panel.settings.booking.cancelTimeFrame.description')}
              </p>
            </div>
          </dd>
        </div>
      </dl>
      <div className="flex justify-end">
        <Button colorName="primary" onClick={onSave} disabled={loading}><FontAwesomeIcon icon={faSave} className="h-5" /> {t('common.actions.save')}</Button>
      </div>
    </>
  );
}

export default BookingSettingsSection;