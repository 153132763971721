import useForm from "src/hooks/useForm";
import FormInput from "../../components/Form/FormInput";
import { AuthClient, SignUpRequest } from "src/api/access/Auth";
import { useTranslation } from "react-i18next";
import useToken from "src/hooks/useToken";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { setToken } from "src/store/application/actions";
import { useEffect } from "react";
import FormAlert from "src/components/Form/FormAlert";
import { Dictionary } from "lodash";
import Spinner from "src/components/Feedback/Spinner";
import Alert from "src/components/Feedback/Alert";
import classNames from "src/components/Utilities/classNames";
import useLocalizedNavigate from "src/hooks/useNavigate";
import Button from "src/components/Actions/Button";
import useEventTracking from "src/hooks/useEventTracking";
import PageLink from "src/components/Cms/PageLink";
import FormCheckbox from "src/components/Form/FormCheckbox";
import { PhoneNumberFormInset } from "src/components/Form/PhoneNumberForm";
import useConfigurationState from "src/hooks/useConfigurationState";
import { MetaHead } from "src/components/Meta/MetaHead";


export interface SignUpRequestForm extends SignUpRequest {
  repeatPassword: string;
}

export default () => {
  const { t, i18n } = useTranslation();
  const form = useForm<SignUpRequestForm>({ culture: i18n.resolvedLanguage, source: 0 } as SignUpRequestForm);
  const token = useToken();

  const navigate = useLocalizedNavigate();
  const applicationDispatch = useApplicationDispatch();
  const configurationState = useConfigurationState();

  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const signUpTracking = useEventTracking('Form Submit', 'Submitted Sign Up', 'Sign up');

  const onSubmit = () => {
    signUpTracking();
    authClient
      .signUp(form.data)
      .then(t => applicationDispatch(setToken(t)))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    if (form.data.password !== form.data.repeatPassword) {
      result["repeatPassword"] = ["MustBeTheSame"];
    }
    return result;
  }

  useEffect(() => {
    if (token) {
      navigate("/user/confirm");
    }
  }, [token]);

  return (
    <>
      <MetaHead title={t('auth.signUp.header')} />
      <div>
        <h2 className="mt-8 text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('auth.signUp.header')}
        </h2>
      </div >

      <FormAlert
        code={form.error}
        errorMessages={{
          ...t("common.errors", { returnObjects: true }),
          ...t("auth.errors", { returnObjects: true })
        }}
      />

      {token && <Alert.Success title="Signed up, redirecting to user panel" />}

      <div className={classNames(["mt-10", token && "hidden"])}>
        <div>
          <form onSubmit={e => form.onSubmit(e, onSubmit, validate)} className="space-y-6">
            <div className="flex flex-row gap-3 justify-between">
              <FormInput.Inset {...form.input('givenName', 'text', { placeholder: t('users.fields.givenName'), autoComplete: 'given-name', minLength: 2, required: true })} />
              <FormInput.Inset {...form.input('surname', 'text', { placeholder: t('users.fields.surname'), autoComplete: 'family-name', minLength: 2, required: true })} />
            </div>

            <FormInput.Inset {...form.input('email', 'email', { placeholder: t('users.fields.email'), autoComplete: 'email', minLength: 8, required: true })} />
            <PhoneNumberFormInset {...form.input('phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber'), required: configurationState.booking.requirePhoneNumber })} />
            <FormInput.Inset {...form.input('password', 'password', { placeholder: t('auth.fields.password'), autoComplete: 'new-password', minLength: 8, required: true, title: t('validation.passwordRules') })} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" />
            <FormInput.Inset {...form.input('repeatPassword', 'password', { placeholder: t('auth.fields.repeatPassword'), autoComplete: 'new-password', minLength: 8, required: true })} />

            <div className="mt-10 text-gray-600 text-sm flex">
              <FormCheckbox.Input {...form.input('agreements', 'checkbox', { value: "true", required: true })} />
              <div className="ml-3 pt-0.5">
                {t('auth.signUp.agreements')} <PageLink id="3b9f58a4-2682-4fe3-b395-6313e8d1cf56" className="text-[--color-primary-700]" />.
              </div>
            </div>

            {configurationState.booking.requireVerification && <p className="text-gray-500 text-sm text-justify">{t('stable.trainings.unverified.signUp')}</p>}

            <div className="flex justify-between">
              <Button colorName="primary" className="w-full justify-center" disabled={form.pending}>
                {t('auth.signUp.action')}
              </Button>
            </div>
            {form.pending && <Spinner />}
          </form>
        </div >
      </div >
    </>
  )
}