import { faShieldTimes } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function Guard() {
  const { t } = useTranslation();
  return (
    <div className="relative max-w-sm md:max-w-xl mx-auto px-4 md:px-0 mb-8 md:py-16 flex flex-col gap-y-5">
      <h1 className="text-2xl text-amber-700">{t('stable.trainings.unverified.title')}</h1>
      <p className="text-lg text-amber-700">{t('stable.trainings.unverified.description')}</p>
      <div className="text-center md:mt-8">
        <FontAwesomeIcon icon={faShieldTimes} className="text-8xl text-amber-700 opacity-25" />
      </div>
    </div>
  );
}