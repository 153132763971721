import { FC, useEffect, useRef, useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PeriodicBookingDeleteRequest, PeriodicBookingRepeatingType, PeriodicUpdateDecision, Training, TrainingsClient } from 'src/api/stable/Booking';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import moment from 'moment';
import { Dictionary } from 'lodash';
import { AccountancyProvider } from 'src/store/accountancy/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import PeriodicOperationModal, { PeriodicOperation, PeriodicOperationModalRef } from 'src/components/Booking/PeriodicOperationModal';

const TrainingsForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new TrainingsClient(apiConfiguration);
  const form = useForm({ source: 3 } as Training);
  const authGuard = useAuthGuard('/panel/', ['TrainingsRead']);
  const TrainingsUpdateClaim = useClaim('TrainingsUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();
  const [valid, setValid] = useState<boolean | undefined>();
  const updateModal = useRef<PeriodicOperationModalRef>(null);
  const deleteModal = useRef<PeriodicOperationModalRef>(null);

  const onDelete = () => {
    if (form.data.periodic) {
      deleteModal.current?.open();
      return;
    }
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id, {} as PeriodicBookingDeleteRequest)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/stable/workers/tasks/`)
      })
  }

  const validation = () => {
    const result = {} as Dictionary<string[]>;
    if (form.data.periodic?.repeating?.type !== undefined) {
      if (form.data.periodic.period?.end === undefined) {
        result['periodic.period.end'] = [t('validation.NotEmptyValidator')];
      }
      if (form.data.periodic.period?.start === undefined) {
        result['periodic.period.start'] = [t('validation.NotEmptyValidator')];
      }
      if (form.data.periodic.repeating.type === PeriodicBookingRepeatingType._1) {
        if (form.data.periodic.repeating.dayOfWeek === undefined || form.data.periodic.repeating.dayOfWeek.length === 0) {
          result['periodic.repeating.dayOfWeek'] = [t('validation.NotEmptyValidator')];
        }
      }
    }
    if (moment.duration(moment(form.data.end).diff(form.data.start)).asMinutes() < 5) {
      result.end = ['TrainingMustHaveAtLeast5Minutes'];
    }
    return result;
  }

  const getDto = () => {
    const data = { ...form.data } as Training;
    if (data.paymentMethodId === 'null') {
      delete data.paymentMethodId;
    }
    return data;
  }

  const onSubmit = () => {
    if (form.data.id && form.data.periodic && (!form.data.periodicUpdateDate || !form.data.periodicUpdateDecision)) {
      updateModal.current?.open();
      form.setPending(false);
      return;
    }
    form.setPending(true);
    const data = getDto();
    if (!data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/booking/trainings/`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.trainings.group", href: "/panel/booking/trainings/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/booking/trainings/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    if (!form.data.riderId || !form.data.instructorId || !form.data.horseId || !form.data.placeId) return;
    form.setValidation({});
    apiClient
      .isPossible(form.data)
      .then(() => {
        setValid(true)
      })
      .catch(e => {
        setValid(false);
        form.catchAnyException(e);
      });
  }, [form.data.riderId, form.data.instructorId, form.data.horseId, form.data.placeId, form.data.start, form.data.end]);


  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    let data = { ...form.data } as Training;
    if (query.get('date')) {
      data = {
        ...data,
        start: new Date(query.get('date')!),
        end: moment(new Date(query.get('date')!)).add(1, 'hour').toDate()
      } as Training;
    }
    if (query.get('instructorId')) data.instructorId = query.get('instructorId')!;
    if (query.get('riderId')) data.riderId = query.get('riderId')!;
    if (query.get('horseId')) data.horseId = query.get('horseId')!;
    form.setData(data);
  }, [window.location.search]);

  useEffect(() => {
    form.setReadOnly(!TrainingsUpdateClaim);
  }, [TrainingsUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  const onConfirmPeriodicUpdate = (decision: PeriodicUpdateDecision, date: Date) => {
    form.setPending(true);
    const data = getDto();
    apiClient.update(id!, { ...data, periodicUpdateDate: date, periodicUpdateDecision: decision } as Training)
      .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  const onConfirmPeriodicDelete = (decision: PeriodicUpdateDecision, date: Date) => {
    form.setPending(true);
    apiClient.delete(id!, { periodicUpdateDate: date, periodicUpdateDecision: decision } as PeriodicBookingDeleteRequest)
      .then(() => {
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/stable/workers/tasks/`);
      })
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <AccountancyProvider>
        <form onSubmit={e => form.onSubmit(e, onSubmit, validation)}>
          <Header training={form.data} onDelete={onDelete} form={form} valid={valid} />
          <GeneralForm form={form} />
          <div className="mt-8 pt-8 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            {valid === false && <FontAwesomeIcon icon={faTimesCircle} className="text-rose-600 size-8 m-2" title={t('stable.trainings.invalid')} />}
            {valid === true && <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-600 size-8 m-2 opacity-25" title={t('stable.trainings.valid')} />}
            <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
              {t('common.actions.save')}
            </Button>
          </div>
        </form >
      </AccountancyProvider>
      <PeriodicOperationModal ref={updateModal} onConfirm={onConfirmPeriodicUpdate} operation={PeriodicOperation.Update} />
      <PeriodicOperationModal ref={deleteModal} onConfirm={onConfirmPeriodicDelete} operation={PeriodicOperation.Delete} />
    </>
  )
}

export default TrainingsForm;