import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TenantRequest, TenantRequestStatus } from 'src/api/licensing/Licensing';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormTextarea from 'src/components/Form/FormTextarea';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<TenantRequest>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  const statuses = Object.values(TenantRequestStatus)
    .filter(status => !isNaN(Number(status)))
    .map((status) => ({
      id: status,
      label: t(`licensing.enums.tenantRequestStatus.${status}`),
      value: new Number(status)
    })) as TextOptionProps[];

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <FormInput.Default
        {...form.input('licenseId', 'text', {
          placeholder: t('licensing.licenses.item'),
          pattern: '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
        })} />
      <FormInput.Default {...form.input('tenantId', 'text', {
        placeholder: t('licensing.tenants.item'),
        pattern: '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
      })} />
      <Select {...form.input('status', 'select', { required: true })} options={statuses} placeholder={t('common.fields.status')} />
      <FormTextarea.Default {...form.textArea('apply.content', 'text', { placeholder: t('common.fields.notes') })} />
      <FormInput.Default {...form.input('apply.referralCode', 'text', { placeholder: t('licensing.tenantRequests.fields.referralCode') })} />
    </FormSection>
  )
}
