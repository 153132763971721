import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { Tenant, TenantsClient } from 'src/api/licensing/Licensing';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faPause, faPlay, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { QueryOrderDirection } from 'src/api/Base';
import FormInput from 'src/components/Form/FormInput';

interface TenantFilters {
  key?: string;
  name?: string;
  hostname?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<TenantFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.key?.length) f.push({ property: 'Key', value: form.data.key, type: '%' });
  if (form.data.name?.length) f.push({ property: 'Name', value: form.data.name, type: '%' });
  if (form.data.hostname?.length) f.push({ property: 'Hostname', value: form.data.hostname, type: '%' });
  return f;
}

function renderFiltersForm(form: IForm<TenantFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <FormInput.Default {...form.input("key", "text", { placeholder: t('common.fields.key') })} />
        <FormInput.Default {...form.input("name", "text", { placeholder: t('common.fields.name') })} />
        <FormInput.Default {...form.input("hostname", "text", { placeholder: t('licensing.tenants.fields.hostname') })} />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const TenantsTable = () => {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new TenantsClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<Tenant>>();
  const updateClaim = useClaim('LicensesUpdate');
  const deleteClaim = useClaim('LicensesDelete');
  const authGuard = useAuthGuard('/panel/', ['LicensesRead']);
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastTenant() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Booking)}/hubs/TenantsHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: Tenant) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const onClickDisable = (row: Tenant): void => {
    if (row?.id) {
      apiClient.disable(row.id)
        .then(() => Toast.success(t("common.status.success"), t("common.feedback.disabled")))
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.disable")))
        .finally(() => tableRef.current?.refresh());
    }
  }

  const onClickEnable = (row: Tenant): void => {
    if (row?.id) {
      apiClient.enable(row.id)
        .then(() => Toast.success(t("common.status.success"), t("common.feedback.enabled")))
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.enable")))
        .finally(() => tableRef.current?.refresh());
    }
  }

  const columns: ITableColumn<Tenant>[] = [
    { label: '', selector: row => <div className={`size-2 rounded-full ${row.isEnabled ? "bg-green-500" : "bg-red-500"}`} />, isOrderable: true, id: "isEnabled" },
    { label: t('common.fields.key'), selector: row => row.key, isOrderable: true, id: "key" },
    { label: t('common.fields.name'), selector: row => row.name, isOrderable: true, id: "name" },
    { label: t('licensing.tenants.fields.hostname'), selector: row => row.hostname, isOrderable: true, id: "hostname" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="flex justify-end gap-x-3">
        {updateClaim &&
          <>
            <LocalizedLink to={`/panel/licensing/tenants/${row.id}`}>
              <Button colorName="primary">
                <FontAwesomeIcon icon={faEdit} className="w-5" />
              </Button>
            </LocalizedLink>
            {row.isEnabled ? <Button colorName="primary" onClick={() => onClickDisable(row)}>
              <FontAwesomeIcon icon={faPause} className="w-5" />
            </Button> : <Button colorName="primary" onClick={() => onClickEnable(row)}>
              <FontAwesomeIcon icon={faPlay} className="w-5" />
            </Button>}
          </>
        }
        {deleteClaim && row.id && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "licensing.tenants.group", href: "/panel/licensing/tenants/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="pt-2">
          <h1 className="leading-1 text-2xl">{t('licensing.tenants.group')}</h1>
        </div>
        <div className="flex gap-x-3">
          {updateClaim &&
            <LocalizedLink to="/panel/booking/tenants/create">
              <Button colorName="emerald" className="px-5 py-3 text-md">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('licensing.tenants.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<Tenant>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        defaultOrder={{ property: 'created', direction: QueryOrderDirection.DESC }}
        filters={filters}
      />
    </>
  )
}

export default TenantsTable;