import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TenantRequest } from 'src/api/licensing/Licensing';
import FormInput from 'src/components/Form/FormInput'
import PhoneNumberForm from 'src/components/Form/PhoneNumberForm';
import CountrySelect from 'src/components/i18n/CountryComboBox';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface ApplyCenterFormComponentProps {
  form: IForm<TenantRequest>;
}

export default function ApplyCenterForm(props: ApplyCenterFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('licensing.tenantRequests.form.apply.center.header')}
      description={t('licensing.tenantRequests.form.apply.center.subheader')}
    >
      <div className="md:flex md:flex-col gap-y-5">
        <div>
          <FormInput.Default {...form.input('apply.center.name', 'text', { placeholder: t('licensing.tenantRequests.fields.apply.center.name'), required: true })} />
        </div>
        <div>
          <FormInput.Default {...form.input('apply.center.address', 'text', { placeholder: t('address.fields.address'), required: true })} />
        </div>
        <div className="flex flex-row gap-x-3">
          <div className="w-1/4">
            <FormInput.Default {...form.input('apply.center.postCode', 'text', { placeholder: t('address.fields.postCode'), required: true })} />
          </div>
          <div className="flex-grow">
            <FormInput.Default {...form.input('apply.center.city', 'text', { placeholder: t('address.fields.city'), required: true })} />
          </div>
        </div>
        <div>
          <CountrySelect
            value={form.data.apply?.center?.countryCode}
            onChange={(val) => form.set('apply.center.countryCode', val)}
            placeholder={t('address.fields.country')}
          />
        </div>
        <div>
          <FormInput.Default {...form.input('apply.center.email', 'email', { placeholder: t('users.fields.email') })} />
        </div>
        <div>
          <PhoneNumberForm {...form.input('apply.center.phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber') })} />
        </div>
      </div>
    </FormSection>
  )
}
