import useForm, { IForm } from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import { Dictionary } from "lodash";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight, faPlusCircle, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import FormInput from "src/components/Form/FormInput";
import FormCheckbox from "src/components/Form/FormCheckbox";
import { Horse, HorsesClient, HorseTranslation } from "src/api/stable/Stable";
import FormRequired from "src/components/Form/FormRequired";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { useEffect, useMemo } from "react";
import Alert from "src/components/Feedback/Alert";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export interface WizardStepHorsesForm {
  rows: Horse[];
}

export interface WizardStepHorsesProps extends WizardStepProps {
  data: Horse[];
  setData: (data: Horse[]) => void;
}

export interface HorseRowProps {
  form: IForm<WizardStepHorsesForm>;
  row: Horse;
  index: number;
  onClickDelete: () => void;
}

enum HorseValidation {
  Name,
  Access
}

const validateEntity = (row: Horse): HorseValidation[] => {
  const result = [] as HorseValidation[];
  if (!row.translations?.[0]?.name) result.push(HorseValidation.Name);
  if (!row.isAvailableForAnyRider && !row.isAvailableForGuest) result.push(HorseValidation.Access);
  return result;
}

const useValidation = (row: Horse) => {
  return useMemo(() => validateEntity(row), [
    row,
    row?.translations?.[0]?.name,
    row?.isAvailableForAnyRider, row?.isAvailableForGuest
  ]);
}

const HorseRow = (props: HorseRowProps) => {
  const { form, row, index, onClickDelete } = props;
  const _validation = useValidation(row);
  const { t } = useTranslation();

  useEffect(() => {
    form.setData({
      rows: form.data.rows.map(r => {
        if (!r.isAvailableForAnyRider) {
          return { ...r, isAvailableForGuest: false } as Horse;
        }
        return r;
      })
    } as WizardStepHorsesForm);
  }, [row.isAvailableForAnyRider]);

  return (
    <>
      <tr>
        <td className="pr-2 pt-2">{row.id && <FontAwesomeIcon icon={faCircleExclamation} className="text-amber-200" />}</td>
        <td>
          <FormInput.WithoutLabel
            {...form.input(
              `rows.${index}.translations.0.name`,
              'text', {
              placeholder: t('wizard.horses.placeholder'),
              required: true,
              minLength: 2
            })}
          />
        </td>
        <td>
          <div className="flex flex-row-reverse">
            <FormCheckbox.Input
              {...form.input(
                `rows.${index}.isAvailableForAnyRider`,
                'checkbox', {
                value: "true"
              })}
              disabled={row.isAvailableForGuest}
              checked={row.isAvailableForGuest || row.isAvailableForAnyRider}
            />
          </div>
        </td>
        <td>
          <div className="flex flex-row-reverse">
            <FormCheckbox.Input
              {...form.input(
                `rows.${index}.isAvailableForGuest`,
                'checkbox', {
                value: "true",
              })} />
          </div>
        </td>
        <td className="text-end"><FontAwesomeIcon icon={faTimesCircle} className="text-rose-700 cursor-pointer" onClick={onClickDelete} /></td>
      </tr>
    </>
  )
}

export default (props: WizardStepHorsesProps) => {
  const { nextStep, prevStep, data, setData } = props;
  const { t } = useTranslation();

  const form = useForm({ rows: [] } as WizardStepHorsesForm);

  const apiConfiguration = useApiConfiguration();
  const apiClient = new HorsesClient(apiConfiguration);

  useEffect(() => {
    if (data.length > 0) {
      form.setData({ rows: data });
      return;
    }
    fetch();
  }, []);

  const fetch = () => {
    form.setPending(true);
    apiClient
      .get(undefined, undefined, 1000, 0, undefined, undefined)
      .then(response => {
        form.setData({ rows: response.items ?? [] });
        return response;
      })
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const onSubmit = () => {
    setData([...form.data.rows]);
    nextStep();
  }

  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  const onClickDelete = (index: number) => {
    if (form.data.rows[index].id) {
      if (confirm(t('wizard.horses.delete.areYouSure'))) {
        deleteRow(index);
      }
    }
    else {
      deleteRow(index);
    }
  }

  const deleteRow = (index: number) => {
    form.setData({
      ...form.data,
      rows: form.data.rows.filter((_, i) => i !== index)
    } as WizardStepHorsesForm);
  }

  const addRow = () => {
    form.setData({
      ...form.data,
      rows:
        [
          ...form.data.rows,
          {
            translations: [{
              culture: 'pl',
              name: '',
              description: ''
            } as HorseTranslation],
            isAvailableForAnyRider: true,
            isAvailableForGuest: true
          } as Horse
        ]
    } as WizardStepHorsesForm);
  }

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
      {form.data.rows?.some(r => r.id) && (
        <Alert.Warning title={t('wizard.horses.delete.title')} noClose>
          {t('wizard.horses.delete.description')}
        </Alert.Warning>
      )}
      <p className="text-sm text-gray-600 py-4">
        {t('wizard.horses.slogan')}
      </p>
      <p className="text-sm text-gray-600 py-4">
        {t('wizard.horses.description')}
      </p>
      <table className="w-full mt-8">
        <thead>
          <tr>
            <th></th>
            <th className="font-medium text-xs text-start w-3/12">{t('wizard.horses.fields.name')}<FormRequired required /></th>
            <th className="font-medium text-xs text-end">{t('wizard.horses.fields.forAuthenticated')}?</th>
            <th className="font-medium text-xs text-end">{t('wizard.horses.fields.forGuests')}?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {form.data?.rows?.map((row, index) => <HorseRow key={index} form={form} index={index} row={row} onClickDelete={() => onClickDelete(index)} />)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6} className="text-end pt-4">
              <Button type="button" colorName="emerald" className="text-sm px-5 font-medium" onClick={addRow} disabled={form.pending}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-3" />
                {t('common.actions.add')}
              </Button>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          {t('ui.goBack')}
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3" disabled={form.data.rows.length === 0}>
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          {t('ui.goNext')}
        </Button>
      </div>
    </form>
  );
};
