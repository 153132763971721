import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LicensePeriod, Product, ProductsClient, ProductTranslation, TenantRequest } from 'src/api/licensing/Licensing';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormInput, { FormLabel } from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import getLocaleName from 'src/components/i18n/getLocaleName';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { IForm } from 'src/hooks/useForm';

export interface ApplyAppFormComponentProps {
  form: IForm<TenantRequest>;
}

export default function ApplyAppForm(props: ApplyAppFormComponentProps) {
  const { form } = props;
  const { i18n, t } = useTranslation();

  const productsTranslation = useEntityTranslation<Product, ProductTranslation>();
  const [products, setProducts] = useState<Product[] | undefined>([]);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ProductsClient(apiConfiguration);
  useEffect(() => {
    form.setPending(true);
    apiClient.get(undefined, undefined, undefined, undefined, undefined, undefined)
      .then((response) => setProducts(response.items))
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }, []);

  const locales = ['pl', 'en'];
  const timezones = [
    "Etc/GMT+12",
    "Etc/GMT+11",
    "America/Adak",
    "Pacific/Honolulu",
    "Pacific/Marquesas",
    "America/Anchorage",
    "Etc/GMT+9",
    "America/Tijuana",
    "Etc/GMT+8",
    "America/Los_Angeles",
    "America/Phoenix",
    "America/Chihuahua",
    "America/Denver",
    "America/Guatemala",
    "America/Chicago",
    "Pacific/Easter",
    "America/Mexico_City",
    "America/Regina",
    "America/Bogota",
    "America/Cancun",
    "America/New_York",
    "America/Port-au-Prince",
    "America/Havana",
    "America/Indiana/Indianapolis",
    "America/Grand_Turk",
    "America/Asuncion",
    "America/Halifax",
    "America/Caracas",
    "America/Cuiaba",
    "America/La_Paz",
    "America/Santiago",
    "America/St_Johns",
    "America/Araguaina",
    "America/Sao_Paulo",
    "America/Cayenne",
    "America/Argentina/Buenos_Aires",
    "America/Godthab",
    "America/Montevideo",
    "America/Punta_Arenas",
    "America/Miquelon",
    "America/Bahia",
    "Etc/GMT+2",
    "Etc/GMT+2",
    "Atlantic/Azores",
    "Atlantic/Cape_Verde",
    "Etc/UTC",
    "Africa/Casablanca",
    "Europe/London",
    "Atlantic/Reykjavik",
    "Europe/Berlin",
    "Europe/Budapest",
    "Europe/Paris",
    "Africa/Sao_Tome",
    "Europe/Warsaw",
    "Africa/Lagos",
    "Asia/Amman",
    "Europe/Bucharest",
    "Asia/Beirut",
    "Africa/Cairo",
    "Europe/Chisinau",
    "Asia/Damascus",
    "Asia/Hebron",
    "Africa/Johannesburg",
    "Europe/Kiev",
    "Asia/Jerusalem",
    "Europe/Kaliningrad",
    "Africa/Khartoum",
    "Africa/Tripoli",
    "Africa/Windhoek",
    "Asia/Baghdad",
    "Europe/Istanbul",
    "Asia/Riyadh",
    "Europe/Minsk",
    "Europe/Moscow",
    "Africa/Nairobi",
    "Asia/Tehran",
    "Asia/Dubai",
    "Europe/Astrakhan",
    "Asia/Baku",
    "Europe/Samara",
    "Indian/Mauritius",
    "Europe/Saratov",
    "Asia/Tbilisi",
    "Asia/Yerevan",
    "Asia/Kabul",
    "Asia/Tashkent",
    "Asia/Yekaterinburg",
    "Asia/Karachi",
    "Asia/Kolkata",
    "Asia/Colombo",
    "Asia/Kathmandu",
    "Asia/Almaty",
    "Asia/Dhaka",
    "Asia/Omsk",
    "Asia/Yangon",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Hovd",
    "Asia/Krasnoyarsk",
    "Asia/Novosibirsk",
    "Asia/Tomsk",
    "Asia/Shanghai",
    "Asia/Irkutsk",
    "Asia/Singapore",
    "Australia/Perth",
    "Asia/Taipei",
    "Asia/Ulaanbaatar",
    "Asia/Pyongyang",
    "Australia/Eucla",
    "Asia/Chita",
    "Asia/Tokyo",
    "Asia/Seoul",
    "Asia/Yakutsk",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Brisbane",
    "Australia/Sydney",
    "Pacific/Port_Moresby",
    "Australia/Hobart",
    "Asia/Vladivostok",
    "Australia/Lord_Howe",
    "Pacific/Bougainville",
    "Asia/Srednekolymsk",
    "Asia/Magadan",
    "Pacific/Norfolk",
    "Asia/Sakhalin",
    "Pacific/Guadalcanal",
    "Asia/Kamchatka",
    "Pacific/Auckland",
    "Etc/GMT-12",
    "Pacific/Fiji",
    "Asia/Kamchatka",
    "Pacific/Chatham",
    "Etc/GMT-13",
    "Pacific/Tongatapu",
    "Pacific/Apia",
    "Pacific/Kiritimati"
  ];

  const domain = window.location.hostname.split('.').slice(-2).join('.');

  return (
    <FormSection
      title={t('licensing.tenantRequests.form.apply.app.header')}
      description={t('licensing.tenantRequests.form.apply.app.subheader')}
    >
      <div className="flex flex-col gap-y-5">
        <div>
          <FormInput.Default {...form.input('apply.app.subdomain', 'text', { placeholder: t('licensing.tenantRequests.fields.apply.app.subdomain'), required: true })} suffix={`.${domain}`} />
        </div>
        <div className="md:flex md:flex-row gap-x-3">
          <div className="flex-1">
            <Select
              {...form.input('apply.app.productId', 'select', { placeholder: t('licensing.tenantRequests.fields.apply.app.productId'), required: true })}
              options={products?.filter(p => p.key?.startsWith('plan-')).map(p => ({ id: p.id, value: p.id, label: productsTranslation.getCurrentTranslation(p)?.name } as TextOptionProps)) ?? []}
            />
          </div>
          <div className="flex-1">
            <Select
              {...form.input('apply.app.period', 'select', { placeholder: t('licensing.tenantRequests.fields.apply.app.period'), required: true })}
              options={Object.values(LicensePeriod).filter(p => !isNaN(Number(p))).map(p => ({ id: p, value: Number(p), label: t(`licensing.enums.licensePeriod.${p}`) } as TextOptionProps)) ?? []}
            />
          </div>
        </div>
        <div>
          <FormLabel placeholder={t('licensing.tenantRequests.fields.apply.app.additionalProducts')} />
          <FormCheckbox.Set title="Locales">
            {products?.filter(p => p.key?.startsWith('module-')).map(p => <FormCheckbox.Input key={p.id} {...form.input('apply.app.additionalProducts[]', 'checkbox', { value: p.id, placeholder: productsTranslation.getCurrentTranslation(p)?.name })} />)}
          </FormCheckbox.Set>
        </div>
        <div>
          <FormLabel placeholder={t('licensing.tenantRequests.fields.apply.app.locales')} />
          <FormCheckbox.Set title="Locales">
            {locales?.map(l => <FormCheckbox.Input key={l} {...form.input('apply.app.locales[]', 'checkbox', { value: l, placeholder: getLocaleName(l, i18n.resolvedLanguage) })} />)}
          </FormCheckbox.Set>
        </div>
        <div>
          <Select
            {...form.input('apply.app.timezone', 'select', { placeholder: t('licensing.tenantRequests.fields.apply.app.timezone') })}
            options={timezones?.sort((a, b) => a.localeCompare(b)).map(t => ({ id: t, value: t, label: t } as TextOptionProps)) ?? []}
          />
        </div>
      </div>
    </FormSection>
  )
}
