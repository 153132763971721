import { useTranslation } from "react-i18next";
import Button from "src/components/Actions/Button";
import LocalizedLink from "src/components/Router/LocalizedLink";
import SectionDescription from "src/components/Typography/SectionDescription";
import SectionHeader from "src/components/Typography/SectionHeader";

const ReturnView = () => {
  const { t } = useTranslation();
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">

          <SectionHeader className="mb-5">{t('common.thankYou')}</SectionHeader>
          <SectionDescription className="my-3">
            {t('accountancy.payments.thankYou')}
          </SectionDescription>
          <SectionDescription className="my-3">
            {t('stable.trainings.weWillSendYouStatus')}
          </SectionDescription>
        </div>
        <div className="text-center">
          <LocalizedLink to={"/"}>
            <Button colorName="primary" className="mt-16 px-5 py-3">{t('common.errors.actions.goBackHome')}</Button>
          </LocalizedLink>
        </div>
      </div>
    </div>
  )
}

export default ReturnView;