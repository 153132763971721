import _ from "lodash";
import { useTranslation } from "react-i18next";
import FormSection from "src/components/Layout/Panel/FormSection";
import StripeSettingsForm from "./Payments/Stripe";
import Przelewy24SettingsForm from "./Payments/Przelewy24";


const PaymentsSettingsSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col gap-y-10 divide-y divide-gray-100">
        <FormSection title="Stripe" description={t('stripe.section.description')} full>
          <StripeSettingsForm />
        </FormSection>
        <FormSection title="Przelewy24" description={t('przelewy24.section.description')} full>
          <Przelewy24SettingsForm />
        </FormSection>
      </div>
    </>
  );
}

export default PaymentsSettingsSection;