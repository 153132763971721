import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import { ContactClient, ContactRequest } from "src/api/notifications/Notifications";
import Button from "src/components/Actions/Button";
import Page from "src/components/Cms/Page";
import Alert from "src/components/Feedback/Alert";
import FormInput from "src/components/Form/FormInput";
import FormTextarea from "src/components/Form/FormTextarea";
import { MetaHead } from "src/components/Meta/MetaHead";
import Breadcrumbs from "src/components/Navigation/Breadcrumbs";
import LocalizedLink from "src/components/Router/LocalizedLink";
import CountryName from "src/components/i18n/CountryName";
import { mapIsoWeekdaysDictionary } from "src/components/i18n/isoWeekdays";
import configuration from "src/config/config";
import config from "src/config/config";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import useConfigurationState from "src/hooks/useConfigurationState";
import useForm from "src/hooks/useForm";
import { setBreadcrumbs } from "src/store/application/actions";
import { Layout } from "src/store/configuration/state";


const Image = () => {
  return (
    <img
      className="absolute inset-0 h-full w-full object-cover"
      src="/img/backgrounds/newsletter.webp"
      alt=""
    />
  );
}

const ContactData = () => {
  const configuration = useConfigurationState();
  const { t, i18n } = useTranslation();

  const openMapsApp = () => {
    if (!config.meta.address?.coordinates) return;
    const coordinates = config.meta.address?.coordinates;
    if (isAndroid) {
      window.open(`https://maps.google.com/maps?daddr=${coordinates.lat},${coordinates.lng}&amp;ll=`);
    }
    else {
      window.open(`maps://maps.google.com/maps?daddr=${coordinates.lat},${coordinates.lng}&amp;ll=`);
    }
  }

  return (
    <div className="p-3">
      <div className="text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl">
        <Page url="819fe990-5fb3-4b7b-8a2f-cf0912abc884" title raw />
      </div>
      <div className="my-8">
        <Page url="819fe990-5fb3-4b7b-8a2f-cf0912abc884" content />
      </div>
      <h2 className="text-xl font-medium pb-4">{config.meta.owner}</h2>
      <div>
        <div>{config.meta.address?.address}</div>
        {config.meta.address?.extra && <div>{config.meta.address?.extra}</div>}
        <div>{config.meta.address?.postCode} {config.meta.address?.city}</div>
        <div><CountryName countryCode={config.meta.address?.countryCode} /></div>
      </div>
      <div>
        {configuration?.booking?.hours && (
          <>
            <h3 className="text-lg font-medium pt-8 pb-4">{t('stable.openingHours')}</h3>
            <table>
              {mapIsoWeekdaysDictionary(configuration.booking.hours, (day, hours) => {
                if (hours === undefined || hours.start[0] === 0 || hours.end[0] === 0) {
                  return (
                    <tr key={day}>
                      <td className="pr-5 font-medium">
                        {moment()
                          .day(Number(day))
                          .toDate()
                          .toLocaleDateString(i18n.resolvedLanguage, { weekday: 'long' })}
                      </td>
                      <td className="text-left text-gray-500">
                        {t('stable.closed')}
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr key={day}>
                    <td className="pr-5 font-medium">
                      {moment()
                        .day(Number(day))
                        .toDate()
                        .toLocaleDateString(i18n.resolvedLanguage, { weekday: 'long' })}
                    </td>
                    <td className="text-left">
                      {_.padStart(hours.start[0]?.toString(), 2, '0')}:
                      {_.padStart(hours.start[1]?.toString(), 2, '0')} -{' '}
                      {_.padStart(hours.end[0]?.toString(), 2, '0')}:
                      {_.padStart(hours.end[1]?.toString(), 2, '0')}
                    </td>
                  </tr>
                );
              })}
            </table>
          </>
        )}
      </div>
      <div className="flex flex-col xl:flex-row gap-5 w-full mt-8">
        {config.meta.address?.coordinates && (
          <Button type="button" colorName="primary" onClick={openMapsApp} className="py-3 px-5 flex-1 flex justify-center gap-x-3">
            <FontAwesomeIcon icon={faMapLocation} />
            <span className="hidden md:inline">Nawiguj</span>
          </Button>
        )}
        {config.meta.emailAddress && (<a href={`mailto:${config.meta.emailAddress}`} target="_blank" rel="noopener" className="flex-1">
          <Button type="button" colorName="primary" className="py-3 w-full flex gap-x-3 justify-center">
            <FontAwesomeIcon icon={faEnvelope} />
            <span>{config.meta.emailAddress}</span>
          </Button>
        </a>)}
        {config.meta.phoneNumber && (<a href={`tel:${config.meta.phoneNumber}`} target="_blank" rel="noopener" className="flex-1">
          <Button type="button" colorName="primary" className="py-3 w-full flex gap-x-3 justify-center">
            <FontAwesomeIcon icon={faPhone} />
            <span>{config.meta.phoneNumber}</span>
          </Button>
        </a>)}
      </div>
    </div>
  );
}

const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: configuration.integrations!.googleMapsApiKey!
  })

  const [_map, setMap] = useState<google.maps.Map | undefined>(undefined);

  const onLoad = useCallback(function callback(m: google.maps.Map) {
    setMap(m)
  }, [])

  const onUnmount = useCallback(function callback(_m: google.maps.Map) {
    setMap(undefined)
  }, [])

  if (!config.meta.address?.coordinates) return <></>;

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={config.meta.address?.coordinates}
      zoom={13}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ draggable: false, disableDoubleClickZoom: true, streetViewControl: false, fullscreenControl: false, mapTypeControl: false, scaleControl: false, rotateControl: false, clickableIcons: false, gestureHandling: 'none' }}
    >
      <Marker position={config.meta.address?.coordinates} title={config.meta.owner} animation={google.maps.Animation.DROP} />
    </GoogleMap>
  ) : <></>
}

enum ContactFormStatus {
  Initial,
  Error,
  Success
}

const Contact = () => {
  const { t } = useTranslation();
  const configurationState = useConfigurationState();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ContactClient(apiConfiguration);
  const form = useForm<ContactRequest>();
  const [status, setStatus] = useState<ContactFormStatus>(ContactFormStatus.Initial);

  const applicationDispatch = useApplicationDispatch();
  useEffect(() => {
    const breadcrumbs = [{ label: 'crm.contact.header', href: `/contact` }];
    applicationDispatch(setBreadcrumbs(breadcrumbs));
  }, []);

  const onSubmit = () => {
    form.setPending(true);
    apiClient.send(form.data)
      .catch(() => setStatus(ContactFormStatus.Error))
      .then(() => setStatus(ContactFormStatus.Success))
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <MetaHead title={t('crm.contact.header')} />
      <div>
        <div className="px-4 sm:px-6 lg:px-20 xl:px-24"><Breadcrumbs /></div>
        <div className="flex flex-1 min-h-screen bg-gradient-to-t from-[--color-primary-50] to-white">
          <div className="flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:max-w-xl">
              <div className="text-center">
                <h1 className="mt-4 text-3xl font-medium tracking-tight text-gray-900 sm:text-5xl">{t('crm.contact.slogan')}</h1>
                <div className="mt-6 text-base leading-7 text-gray-600">{t('crm.contact.fillForm')}</div>
                {status === ContactFormStatus.Success && <Alert.Success title="Thank you for reach us" noClose />}
                {status === ContactFormStatus.Error && <Alert.Error title="Can't process your request" noClose />}
                <Form onSubmit={e => form.onSubmit(e, onSubmit)}>
                  <div className="flex flex-wrap text-left">
                    <div className="py-3 px-1 w-full lg:w-1/2">
                      <FormInput.Inset {...form.input('personalData.givenName', 'text', { placeholder: t('users.fields.givenName'), minLength: 2, maxLength: 48, required: true })} />
                    </div>
                    <div className="py-3 px-1 w-full lg:w-1/2">
                      <FormInput.Inset {...form.input('personalData.surname', 'text', { placeholder: t('users.fields.surname'), minLength: 2, maxLength: 48, required: true })} />
                    </div>
                    <div className="py-3 px-1 w-full lg:w-1/2">
                      <FormInput.Inset {...form.input('personalData.email', 'email', { placeholder: t('auth.fields.email'), minLength: 5, maxLength: 48, required: true })} />
                    </div>
                    <div className="py-3 px-1 w-full lg:w-1/2">
                      <FormInput.Inset {...form.input('personalData.phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber'), minLength: 7, maxLength: 16 })} />
                    </div>
                    <div className="py-3 px-1 w-full">
                      <FormInput.Inset {...form.input('subject', 'text', { placeholder: t('common.fields.subject'), minLength: 4, maxLength: 96, required: true })} />
                    </div>
                    <div className="py-3 px-1 w-full">
                      <FormTextarea.Inset {...form.textArea('content', 'textarea', { placeholder: t('common.fields.content'), minLength: 16, maxLength: 1024, required: true })} />
                    </div>
                  </div>
                  <div className="text-sm text-gray-400 text-left">{t('crm.contact.privacy')}</div>
                  <div className="mt-10 flex items-center justify-between gap-x-6 mb-12">
                    <LocalizedLink to="/" className="text-sm font-medium text-gray-900">
                      <span aria-hidden="true">&larr;</span> {t('common.errors.actions.goBackHome')}
                    </LocalizedLink>
                    <Button colorName="primary" className="px-5 py-3" disabled={form.pending || status === ContactFormStatus.Success}>
                      {t('common.actions.send')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block">
            {(configurationState.layout.contactFormLayout === Layout.MapHorizontal && configuration.integrations?.googleMapsApiKey && configuration.meta.address?.coordinates) && <Map />}
            {configurationState.layout.contactFormLayout === Layout.PictureHorizontal && <Image />}
            {configurationState.layout.contactFormLayout === Layout.Horizontal && <ContactData />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;