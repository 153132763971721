import { FC, useEffect } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { GroupTraining, GroupTrainingsClient, PeriodicBookingDeleteRequest, PeriodicUpdateDecision, Training, TrainingsClient } from 'src/api/stable/Booking';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import moment from 'moment';
import { Dictionary } from 'lodash';
import { AccountancyProvider } from 'src/store/accountancy/context';
import ParticipantsForm from './Form/Participants';

const GroupTrainingsForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new GroupTrainingsClient(apiConfiguration);
  const trainingsApiClient = new TrainingsClient(apiConfiguration);
  const form = useForm<GroupTraining>();
  const authGuard = useAuthGuard('/panel/', ['TrainingsRead']);
  const TrainingsUpdateClaim = useClaim('TrainingsUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const onDelete = () => {
    if (!form.data.id) return;
    if (form.pending) return;
    form.setPending(true);
    Promise.all([
      trainingsApiClient
        .delete(form.data.id!, {
          periodicUpdateDate: form.data.start,
          periodicUpdateDecision: PeriodicUpdateDecision._0
        } as PeriodicBookingDeleteRequest),
      ...form.data?.participants?.map(p => {
        return trainingsApiClient
          .delete(p.bookingId!, {
            periodicUpdateDate: form.data.start,
            periodicUpdateDecision: PeriodicUpdateDecision._0
          } as PeriodicBookingDeleteRequest)
      }) ?? []
    ])
      .then(() => {
        Toast.success(t("common.status.success"), t("common.form.saved"));
        navigate('/panel/booking/trainings/');
      })
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  const onSubmit = () => {
    if (!form.data.id) return;
    if (form.pending) return;
    form.setPending(true);
    Promise.all([
      trainingsApiClient.find(form.data.id!)
        .then((response) => trainingsApiClient.update(
          form.data.id!,
          {
            ...response,
            instructorId: form.data.instructorId,
            typeId: form.data.typeId,
            placeId: form.data.placeId,
            start: form.data.start,
            end: form.data.end,
            periodicUpdateDate: form.data.start,
            periodicUpdateDecision: PeriodicUpdateDecision._0
          } as Training)),
      ...form.data?.participants?.map(p => {
        return trainingsApiClient.find(p.bookingId!)
          .then((response) => trainingsApiClient.update(
            p.bookingId!,
            {
              ...response,
              instructorId: form.data.instructorId,
              typeId: form.data.typeId,
              placeId: form.data.placeId,
              start: form.data.start,
              end: form.data.end,
              periodicUpdateDate: form.data.start,
              periodicUpdateDecision: PeriodicUpdateDecision._0
            } as Training))
      }) ?? []
    ])
      .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  const validation = () => {
    const result = {} as Dictionary<string[]>;
    if (moment.duration(moment(form.data.end).diff(form.data.start)).asMinutes() < 5) {
      result.end = ['TrainingMustHaveAtLeast5Minutes'];
    }
    return result;
  }

  useEffect(() => {
    if (form.data.id !== id) {
      navigate(`/panel/booking/group-trainings/${form.data.id}`);
    }
  }, [form.data.id]);

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.trainings.group", href: "/panel/booking/trainings/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/booking/group-trainings/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    let data = { ...form.data } as Training;
    if (query.get('date')) {
      data = {
        ...data,
        start: new Date(query.get('date')!),
        end: moment(new Date(query.get('date')!)).add(1, 'hour').toDate()
      } as Training;
    }
    if (query.get('instructorId')) data.instructorId = query.get('instructorId')!;
    if (query.get('riderId')) data.riderId = query.get('riderId')!;
    if (query.get('horseId')) data.horseId = query.get('horseId')!;
    form.setData(data);
  }, [window.location.search]);

  useEffect(() => {
    form.setReadOnly(!TrainingsUpdateClaim);
  }, [TrainingsUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />;

  return (
    <>
      <AccountancyProvider>
        <form onSubmit={e => form.onSubmit(e, onSubmit, validation)}>
          <Header training={form.data} onDelete={onDelete} form={form} />
          <GeneralForm form={form} />
          <ParticipantsForm form={form} fetch={fetch} />
          <div className="mt-8 pt-8 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
              {t('common.actions.save')}
            </Button>
          </div>
        </form >
      </AccountancyProvider>
    </>
  )
}

export default GroupTrainingsForm;