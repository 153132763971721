import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { TenantRequest, TenantRequestsClient } from 'src/api/licensing/Licensing';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { QueryOrderDirection } from 'src/api/Base';
import FormInput from 'src/components/Form/FormInput';

interface TenantRequestFilters {
  subdomain?: string;
  givenName?: string;
  surname?: string;
  centerName?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<TenantRequestFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.subdomain?.length) f.push({ property: 'Apply.App.Subdomain', value: form.data.subdomain, type: '%' });
  if (form.data.givenName?.length) f.push({ property: 'Apply.Owner.GivenName', value: form.data.givenName, type: '%' });
  if (form.data.surname?.length) f.push({ property: 'Apply.Owner.Surname', value: form.data.surname, type: '%' });
  if (form.data.centerName?.length) f.push({ property: 'Apply.Center.Name', value: form.data.centerName, type: '%' });
  return f;
}

function renderFiltersForm(form: IForm<TenantRequestFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <FormInput.Default {...form.input("subdomain", "text", { placeholder: t('licensing.tenantRequests.fields.apply.app.subdomain') })} />
        <FormInput.Default {...form.input("givenName", "text", { placeholder: t('users.fields.givenName') })} />
        <FormInput.Default {...form.input("surname", "text", { placeholder: t('users.fields.surname') })} />
        <FormInput.Default {...form.input("centerName", "text", { placeholder: t('licensing.tenantRequests.fields.apply.center.header') })} />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const TenantRequestsTable = () => {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new TenantRequestsClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<TenantRequest>>();
  const updateClaim = useClaim('LicensesUpdate');
  const deleteClaim = useClaim('LicensesDelete');
  const authGuard = useAuthGuard('/panel/', ['LicensesRead']);
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastTenantRequest() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Booking)}/hubs/TenantRequestsHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: TenantRequest) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<TenantRequest>[] = [
    { label: t('licensing.tenantRequests.fields.apply.app.subdomain'), selector: row => row.apply?.app?.subdomain, isOrderable: true, id: "apply.app.subdomain" },
    { label: t('licensing.tenantRequests.fields.apply.owner.header'), selector: row => `${row.apply?.owner?.givenName} ${row.apply?.owner?.surname}`, isOrderable: true, id: "apply.owner.surname" },
    { label: t('licensing.tenantRequests.fields.apply.center.header'), selector: row => row.apply?.center?.name, isOrderable: true, id: "apply.center.name" },
    { label: t('common.fields.status'), selector: row => t(`licensing.enums.tenantRequestStatus.${row.status}`), isOrderable: true, id: "status" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="flex justify-end gap-x-3">
        {updateClaim &&
          <LocalizedLink to={`/panel/licensing/tenant-requests/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && row.id && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "licensing.tenantRequests.group", href: "/panel/licensing/tenant-requests/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="pt-2">
          <h1 className="leading-1 text-2xl">{t('licensing.tenantRequests.group')}</h1>
        </div>
        <div className="flex gap-x-3">
          {updateClaim &&
            <LocalizedLink to="/panel/licensing/tenant-requests/create">
              <Button colorName="emerald" className="px-5 py-3 text-md">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('licensing.tenantRequests.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<TenantRequest>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        defaultOrder={{ property: 'created', direction: QueryOrderDirection.DESC }}
        filters={filters}
      />
    </>
  )
}

export default TenantRequestsTable;