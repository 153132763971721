import { FC, useEffect, useMemo, useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { License, LicensesClient, Product, ProductsClient, ProductTranslation } from 'src/api/licensing/Licensing';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import Tabs, { Tab } from 'src/components/Tabs/Pills';
import GeneralForm from './Form/General';
import SubscriptionForm from './Form/Subscription';
import useEntityTranslation from 'src/hooks/useEntityTranslation';

const LicensesForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new LicensesClient(apiConfiguration);
  const productsClient = new ProductsClient(apiConfiguration);
  const form = useForm<License>();
  const authGuard = useAuthGuard('/panel/', ['LicensesRead']);
  const LicensesUpdateClaim = useClaim('LicensesUpdate');
  const [ products, setProducts ] = useState<Product[]>([]);
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();
  const productTranslation = useEntityTranslation<Product, ProductTranslation>();

  const [tabs, setTabs] = useState<Tab[]>([
    { id: 'data', name: 'common.data' },
  ]);

  useEffect(() => {
    const tmp: Tab[] = [
      { id: 'data', name: 'common.data' },
    ];
    for(const subscription of form.data.subscriptions ?? []) {
      const tm = {
        id: `subscription-${subscription.id}`,
        name: productTranslation.getCurrentTranslation(products.find(p => p.id === subscription.productId))?.name ?? subscription.id
      } as Tab;
      tmp.push(tm);
    }
    setTabs(tmp);
  }, [ form.data.id, form.data.subscriptions, products ]);
  const [currentTab, setCurrentTab] = useState<string>("");

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/licensing/licenses/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    const data = {...form.data} as License;
    data.subscriptions = data.subscriptions?.filter(s => s.renewals !== undefined && s.renewals.length > 0);
    if (id !== 'create') {
      apiClient.update(id, data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/licensing/licenses/`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetchProducts = () => {
    form.setPending(true);
    productsClient.get(undefined, undefined, undefined, undefined, undefined, undefined)
      .then(response => setProducts(response.items ?? []))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const fetch = () => {
    if (!id) return;
    form.setPending(true);
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  useEffect(() => {
    fetchProducts();
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "licensing.licenses.group", href: "/panel/licensing/licenses/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/licensing/licenses/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    form.setReadOnly(!LicensesUpdateClaim);
  }, [LicensesUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />;

  const currentSubscriptionId = useMemo(() => currentTab.replace('subscription-', ''), [currentTab]);

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header License={form.data} onDelete={onDelete} form={form} products={products} />
        <Tabs tabs={tabs.map(t => ({ ...t, visible: form.data.id ? true : t.visible } as Tab))} currentTab={currentTab} setCurrentTab={setCurrentTab} className="mt-3" />
        {currentTab == 'data' && <>
          <GeneralForm form={form} />
        </>}
        {currentTab.startsWith('subscription-') && <div>
          {form.data.subscriptions?.map((subscription, index) => <SubscriptionForm key={index} index={index} subscription={subscription} form={form} products={products} visible={subscription.id === currentSubscriptionId} />)}
        </div>}
        <div className="text-end mt-5">
          <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
            {t('common.actions.save')}
          </Button>
        </div>
      </form>
    </>
  )
}

export default LicensesForm;