import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Settings, SettingsClient } from "src/api/core/Core";
import { PaymentMethodsClient as PaymentMethodsInfoClient } from "src/api/financial/Accountancy";
import { HttpQueryFilter, PaymentMethod, PaymentMethodsClient } from "src/api/financial/Payments";
import Button from "src/components/Actions/Button";
import Alert from "src/components/Feedback/Alert";
import Spinner from "src/components/Feedback/Spinner";
import classNames from "src/components/Utilities/classNames";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useForm from "src/hooks/useForm";

export interface StripeSettings {
  accountId?: string;
  accountStatus?: string;
}

const StripeSettingsForm = () => {
  const { t } = useTranslation();
  const form = useForm<Settings>();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);
  const paymentMethodsInfoApiClient = new PaymentMethodsInfoClient(apiConfiguration);
  const paymentMethodsApiClient = new PaymentMethodsClient(apiConfiguration);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [link, setLink] = useState<string | undefined>();
  const [dashboardLink, setDashboardLink] = useState<string | undefined>();
  const settings = useMemo(() => form.data?.value as StripeSettings, [form.data.value]);

  useEffect(() => {
    fetch();
    fetchPaymentMethod();
  }, []);

  const fetch = () => {
    form.setPending(true);
    apiClient.get('stripe', undefined)
      .then((response) => form.setData(response))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }
  
  const fetchPaymentMethod = async () => {
    const payment = await paymentMethodsInfoApiClient.get(
      [{ property: 'provider', value: 'Stripe' } as HttpQueryFilter],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    setPaymentMethod(payment.items?.[0]);
  }

  const onClickOnBoard = async () => {
    if (form.pending) return;
    form.setPending(true);
    try {
      if(!paymentMethod) return;
      const onBoardLink = await paymentMethodsApiClient.paymentMethodAccountOnboard(paymentMethod.id!);
      console.log(onBoardLink);
      setLink(onBoardLink);
    }
    catch (e) {
      form.catchAnyException(e);
    }
    finally {
      form.setPending(false);
    }
  }

  const onClickDashboard = async () => {
    if (form.pending) return;
    form.setPending(true);
    try {
      if (!paymentMethod) return;
      const dbLink = await paymentMethodsApiClient.paymentMethodDashboard(paymentMethod.id!);
      console.log(dbLink);
      setDashboardLink(dbLink);
    }
    catch (e) {
      form.catchAnyException(e);
    }
    finally {
      form.setPending(false);
    }
  }

  return (
    <div className="flex flex-col gap-y-10">
      {link && <Alert.Information title={t('stripe.onBoarding.header')} noClose>
        <div className="flex flex-row gap-x-5 justify-between">
          <div>
            <p>{t('stripe.onBoarding.itsYourLink')}</p>
            <p>{link}</p>
          </div>
          <a href={link} target="_blank" rel="noopener">
            <Button colorName="blue" className="px-5 py-3">
              {t('stripe.onBoarding.openLink')}
            </Button>
          </a>
        </div>
      </Alert.Information>}
      {dashboardLink && <Alert.Information title={t('stripe.dash.header')} noClose>
        <div className="flex flex-row gap-x-5 justify-between">
          <div>
            <p>{t('stripe.dash.itsYourLink')}</p>
            <p>{dashboardLink}</p>
          </div>
          <a href={dashboardLink} target="_blank" rel="noopener">
            <Button colorName="blue" className="px-5 py-3">
              {t('stripe.onBoarding.openLink')}
            </Button>
          </a>
        </div>
      </Alert.Information>}
      {settings && (<div className="flex flex-col gap-y-5">
        <div className="flex flex-row justify-between gap-x-5">
          <div>{t('stripe.fields.accountId')}</div>
          <div className="flex flex-row gap-x-2 items-center">
            <span>{settings.accountId}</span>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-5">
          <div>{t('integration.status')}</div>
          <div className="flex flex-row gap-x-2 items-center">
            <div className={classNames([
              "size-2 rounded-full ",
              settings.accountStatus === 'active' ? 'bg-green-500 ' : ' ',
              settings.accountStatus === 'inactive' ? 'bg-amber-500 ' : ' ',
              (settings.accountStatus === 'new' || !settings.accountStatus) ? 'bg-gray-500 ' : ' '
            ])}></div>
            <span>{t(`integration.statuses.${settings.accountStatus}`)}</span>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-5">
          <div>{t('common.fields.isEnabled')}</div>
          <div className="flex flex-row gap-x-2 items-center">
            <div className={classNames([
              "size-2 rounded-full ",
              paymentMethod?.isEnabled === true ? 'bg-green-500 ' : ' ',
              paymentMethod?.isEnabled === false ? 'bg-amber-500 ' : ' ',
            ])}></div>
            <span>{t(`common.bool.${paymentMethod?.isEnabled ? 'yes' : 'no'}`)}</span>
          </div>
        </div>
      </div>)}
      <div className="flex flex-row-reverse justify-between items-center">
        {settings?.accountStatus !== 'active' && <Button colorName="primary" className="px-5 py-3" onClick={onClickOnBoard} disabled={form.pending}>{t('stripe.onBoarding.do')}</Button>}
        {settings?.accountStatus === 'active' && <Button colorName="primary" className="px-5 py-3" onClick={onClickDashboard} disabled={form.pending}>{t('stripe.dashboard')}</Button>}
        {form.pending && <div><Spinner /></div>}
      </div>
    </div>
  )
}

export default StripeSettingsForm;