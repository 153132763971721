import { faLink } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User, UsersClient } from 'src/api/access/Authority';
import { HttpQueryFilter, Rider } from 'src/api/stable/Stable';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import ComboBox from 'src/components/Form/FormComboBox';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps, UserOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Rider>;
  addUserForm: IForm<User>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form, addUserForm } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  const [newUser, setNewUser] = useState(false);
  const apiConfiguration = useApiConfiguration();
  const usersClient = new UsersClient(apiConfiguration);
  const [users, setUsers] = useState<User[]>([]);
  const userAutocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);

  useEffect(() => {
    setUsers([]);
    usersClient
      .get(
        [],
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setUsers(response.items ?? []))
      .catch(console.error);
  }, []);

  useEffect(() => {
    setNewUser(addUserForm.data.email || addUserForm.data.givenName || addUserForm.data.surname || addUserForm.data.password ? true : false);
  }, [addUserForm.data.email, addUserForm.data.givenName, addUserForm.data.surname, addUserForm.data.password]);

  useEffect(() => {
    if (newUser) {
      form.setData({ ...form.data, userId: '' } as Rider);
    } else {
      addUserForm.setData({ email: '', givenName: '', surname: '', password: '' } as User);
    }
  }, [newUser]);

  const onChangeUser: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    form.setData({ ...form.data, userId: value, user: users.find(u => u.id === value) } as Rider);
  }

  const applyUserAutocomplete = (query: string) => {
    usersClient
      .get(
        query
          .split(' ')
          .filter(phrase => phrase.length > 0)
          .map(phrase => ({ type: '%', property: 'GivenName,Surname,Email', value: phrase } as HttpQueryFilter)),
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setUsers(response.items ?? []))
      .catch(console.error);
  }

  const onUserAutocomplete = (query: string) => {
    if (userAutocompleteTimeout.current) {
      clearTimeout(userAutocompleteTimeout.current);
      userAutocompleteTimeout.current = undefined;
    }
    userAutocompleteTimeout.current = setTimeout(() => applyUserAutocomplete(query), 250);
  }

  const userOptions = users?.map(r => ({ value: r.id, user: r }) as UserOptionProps);

  const usersReadClaim = useClaim('UsersRead');

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
      full
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <div className="flex flex-row gap-x-5">
          <span>{t("users.item")}</span>
          {usersReadClaim && form.data.user?.id && <LocalizedLink to={`/panel/users/${form.data.user.id}`} target="_blank" rel="noopener"><span className="text-gray-500"><FontAwesomeIcon icon={faLink} /></span></LocalizedLink>}
        </div>
        {!form.data.id ? (<>
          <div className="flex">
            <div className="mr-5 pt-3">
              <FormCheckbox.Input type="radio" name="addNewUser" checked={!newUser} onClick={() => setNewUser(false)} />
            </div>
            <ComboBox options={userOptions} value={form.data?.userId} onChange={onChangeUser} onAutoComplete={onUserAutocomplete} {...form.extendedValidation('userId')} disabled={form.data?.id ? true : false} />
          </div>
          <div className="flex">
            <div className="mr-5 pt-3">
              <FormCheckbox.Input type="radio" name="addNewUser" checked={newUser} onClick={() => setNewUser(true)} />
            </div>
            <div className="flex-grow">
              <div className="mt-3 mb-5" onClick={() => setNewUser(true)}>{t('users.actions.create')}</div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <FormInput.Default {...addUserForm.input('email', 'email', { placeholder: t('auth.fields.email'), required: newUser, minLength: 2 })} />
                <FormInput.Default {...addUserForm.input('givenName', 'text', { placeholder: t('users.fields.givenName'), required: newUser, minLength: 2 })} />
                <FormInput.Default {...addUserForm.input('surname', 'text', { placeholder: t('users.fields.surname'), required: newUser, minLength: 2 })} />
                <FormInput.Default {...addUserForm.input('password', 'password', { placeholder: t('auth.fields.password'), minLength: 8 })} />
              </div>
            </div>
          </div>
        </>) : (
          <div className="grid md:grid-cols-2 mb-10">
              <FormInput.Clean {...form.input('user.givenName', 'text', { placeholder: t('users.fields.givenName'), disabled: true })} />
              <FormInput.Clean {...form.input('user.surname', 'text', { placeholder: t('users.fields.surname'), disabled: true })} />
              <FormInput.Clean {...form.input('user.email', 'email', { placeholder: t('auth.fields.email'), disabled: true })} />
              <FormInput.Clean {...form.input('user.phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber'), disabled: true })} />
          </div>
        )}
        <div className="mb-10">
          <Select
            id="isVerified"
            placeholder={t('common.fields.isVerified')}
            value={form.data.isVerified !== null ? (form.data.isVerified ? "true" : "false") : "null"}
            onChange={(e) => form.setData({ ...form.data, isVerified: e.target.value === "true" ? true : e.target.value === "false" ? false : null } as Rider)}
            options={[
              { id: "true", value: "true", label: t('common.bool.yes') },
              { id: "false", value: "false", label: t('common.bool.no') },
              { id: "null", value: "null", label: t('ui.choose') }
            ] as TextOptionProps[]}
          />
        </div>
      </div>
    </FormSection>
  )
}