import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Settings, SettingsClient } from "src/api/core/Core";
import { HttpQueryFilter, PaymentMethod, PaymentMethodsClient } from "src/api/financial/Accountancy";
import Button from "src/components/Actions/Button";
import Spinner from "src/components/Feedback/Spinner";
import FormInput from "src/components/Form/FormInput";
import classNames from "src/components/Utilities/classNames";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useForm from "src/hooks/useForm";

export interface Przelewy24Settings {
  merchantId?: number;
  posId?: number;
  userSecret?: string;
  crc?: string;
  accountStatus?: string;
}

const Przelewy24SettingsForm = () => {
  const { t } = useTranslation();
  const form = useForm<Przelewy24Settings>();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);
  const paymentMethodsApiClient = new PaymentMethodsClient(apiConfiguration);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();

  useEffect(() => {
    fetch();
    fetchPaymentMethod();
  }, []);

  const fetchPaymentMethod = async () => {
    const payment = await paymentMethodsApiClient.get(
      [{ property: 'provider', value: 'Przelewy24' } as HttpQueryFilter],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    setPaymentMethod(payment.items?.[0]);
  }

  const fetch = () => {
    form.setPending(true);
    apiClient.get('przelewy24', undefined)
      .then((response) => form.setData(response?.value))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const onSubmit = async () => {
    if (form.pending) return;
    form.setPending(true);
    try {
      const data = {...form.data};
      data.merchantId = Number(data.merchantId);
      data.posId = Number(data.posId) ?? undefined;
      await apiClient.set(
        'przelewy24',
        undefined,
        { name: 'przelewy24', culture: undefined, value: data as Przelewy24Settings } as Settings
      );
    }
    catch (e) {
      form.catchAnyException(e);
    }
    finally {
      form.setPending(false);
    }
  }

  return (
    <>
      <div className="flex flex-col gap-y-5">
        <div>
          <FormInput.Default {...form.input('merchantId', 'number', { placeholder: t('przelewy24.fields.merchantId') })} />
        </div>
        <div>
          <FormInput.Default {...form.input('posId', 'number', { placeholder: t('przelewy24.fields.posId') })} />
        </div>
        <div>
          <FormInput.Default {...form.input('userSecret', 'text', { placeholder: t('przelewy24.fields.userSecret') })} />
        </div>
        <div>
          <FormInput.Default {...form.input('crc', 'text', { placeholder: t('przelewy24.fields.crc') })} />
        </div>
        <div className="flex flex-row justify-between gap-x-5">
          <div>{t('integration.status')}</div>
          <div className="flex flex-row gap-x-2 items-center">
            <div className={classNames([
              "size-2 rounded-full ",
              form.data.accountStatus === 'active' ? 'bg-green-500 ' : ' ',
              form.data.accountStatus === 'inactive' ? 'bg-red-500 ' : ' ',
              (form.data.accountStatus === 'new' || !form.data.accountStatus) ? 'bg-gray-500 ' : ' '
            ])}></div>
            <span>{t(`integration.statuses.${form.data.accountStatus ?? 'new'}`)}</span>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-5">
          <div>{t('common.fields.isEnabled')}</div>
          <div className="flex flex-row gap-x-2 items-center">
            <div className={classNames([
              "size-2 rounded-full ",
              paymentMethod?.isEnabled === true ? 'bg-green-500 ' : ' ',
              paymentMethod?.isEnabled === false ? 'bg-amber-500 ' : ' ',
            ])}></div>
            <span>{t(`common.bool.${paymentMethod?.isEnabled ? 'yes' : 'no'}`)}</span>
          </div>
        </div>
        <div className="flex flex-row-reverse justify-between items-center">
          <Button colorName="primary" className="px-5 py-3" onClick={onSubmit}>{t('common.actions.save')}</Button>
          {form.pending && <div><Spinner /></div>}
        </div>
      </div>
    </>
  )
}

export default Przelewy24SettingsForm;