import { faChevronLeft, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard, faFence, faStopCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Horse, HorseTranslation, Instructor, InstructorTranslation, Place, PlaceTranslation, RiderTrainingsClient, Training, TrainingType, TrainingTypeTranslation } from "src/api/stable/Booking";
import Button from "src/components/Actions/Button";
import Alert from "src/components/Feedback/Alert";
import { BooleanIcon } from "src/components/Feedback/BooleanIcon";
import LoadingOverlay from "src/components/Feedback/LoadingOverlay";
import Spinner from "src/components/Feedback/Spinner";
import MessageToUserButton from "src/components/Messages/MessageToUserButton";
import LocalizedLink from "src/components/Router/LocalizedLink";
import { HorseImage } from "src/components/Stable/HorseImage";
import Avatar from "src/components/User/Avatar";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useConfigurationState from "src/hooks/useConfigurationState";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import useUser from "src/hooks/useUser";

const TrainingView = () => {
  const { id } = useParams<string>();
  const { t, i18n } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const user = useUser();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [training, setTraining] = useState<Training | void>();
  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const instructorTranslation = useEntityTranslation<Instructor, InstructorTranslation>();
  const horseTranslation = useEntityTranslation<Horse, HorseTranslation>();
  const placeTranslation = useEntityTranslation<Place, PlaceTranslation>();
  const configuration = useConfigurationState();

  const trainingsApi = new RiderTrainingsClient(apiConfiguration);

  useEffect(() => window.scrollTo(0, 0), []);

  const onCancel = () => {
    if(!training?.id) return;
    if(loading) return;
    setLoading(true);
    trainingsApi.cancel(training?.id as string)
      .then(() => setTraining({ ...training, isCanceled: true } as Training))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const fetchTraining = () => {
    setLoading(true);
    if (!id) return;
    trainingsApi
      .find(id)
      .catch(() => setError(true))
      .then((response) => setTraining(response))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchTraining();
  }, []);

  const canCancel = useMemo(
    () => configuration?.booking.cancelTimeFrame !== undefined ? moment(training?.start).subtract(configuration?.booking?.cancelTimeFrame, 'hours').isAfter(moment()) : false,
    [configuration?.booking.cancelTimeFrame, training?.start]
  );

  return (
    <div className="relative">
      {loading && <LoadingOverlay />}
      {error && <Alert.Error title={t('common.errors.500')} noClose />}
      <div>
        <div className="flex">
          <div>
            <BooleanIcon
              value={training?.isConfirmed}
              titleNull={t('stable.trainings.isConfirmed.null')}
              titleFalse={t('stable.trainings.isConfirmed.false')}
              titleTrue={t('stable.trainings.fields.isConfirmed')}
              className="inline h-8 mr-5 mt-3"
            />
          </div>
          <div>
            <h3 className="text-2xl">{trainingTypeTranslation.getCurrentTranslation(training?.type)?.name}</h3>
            <div className="text-gray-500">
              <span className="mr-3">{training?.number || training?.id}</span>&bull;
              <span className="ml-3">
                {training?.isConfirmed === true && t('stable.trainings.fields.isConfirmed')}
                {training?.isConfirmed === false && t('stable.trainings.isConfirmed.false')}
                {training?.isConfirmed === null && t('stable.trainings.isConfirmed.null')}
              </span>
              <span className="ml-3">
                {training?.isCanceled === true && t('stable.trainings.fields.isCanceled')}
              </span>
            </div>
          </div>
        </div>
        <div className="my-5 text-lg">
          <div>{training?.start.toLocaleString(i18n.resolvedLanguage, { weekday: 'long', day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit' })}</div>
        </div>
      </div>
      <div className="divide-y gap-y-3 divide-gray-100 border-t border-gray-100 mt-3 py-3">
        {user?.id === training?.rider?.userId && <div className="py-3 flex">
          <div className="flex-1">{t('stable.instructors.item')}</div>
          <div className="font-medium flex-1 flex">
            <Avatar userId={training?.instructor?.userId} className="size-8 object-cover rounded-full ring-1 mr-3 -mt-1" />
            {instructorTranslation.getCurrentTranslation(training?.instructor)?.name}
            <div className="relative ml-3">
              <MessageToUserButton userId={training?.instructor?.userId} />
            </div>
          </div>
        </div>}
        {user?.id === training?.instructor?.userId && <div className="py-3 flex">
          <div className="flex-1">{t('stable.riders.item')}</div>
          <div className="font-medium flex-1 flex">
            <Avatar userId={training?.rider?.userId} className="size-8 object-cover rounded-full ring-1 mr-3 -mt-1" />
            {training?.rider?.user?.fullName}
            <div className="relative ml-3">
              <MessageToUserButton userId={training?.rider?.userId} />
            </div>
            <div className="relative ml-3">
              <button type="button" className="inline bg-[--color-primary-500] hover:bg-[--color-primary-700] text-white font-medium h-6 w-6 p-1 rounded-full">
                <FontAwesomeIcon icon={faPhone} />
              </button>
            </div>
          </div>
        </div>}
        <div className="py-3 flex">
          <div className="flex-1">{t('stable.horses.item')}</div>
          <div className="font-medium flex-1 flex">
            <HorseImage id={training?.horseId ?? ""} className="size-8 object-cover rounded-full ring-1 mr-3 -mt-1" />
            <div>
              <div>{horseTranslation.getCurrentTranslation(training?.horse)?.name}</div>
              {configuration.booking.isHorseOccupiedBeforeEnabled && training?.isHorseOccupiedBefore && <div className="text-gray-500 text-sm">{t('stable.trainings.horseIsOccupiedBefore')}</div>}
            </div>
          </div>
        </div>
        <div className="py-3 flex">
          <div className="flex-1">{t('stable.places.item')}</div>
          <div className="font-medium flex-1 flex">
            <FontAwesomeIcon icon={faFence} className="inline h-8 w-8 text-gray-500 mr-3 -mt-2" />
            {placeTranslation.getCurrentTranslation(training?.place)?.name}
          </div>
        </div>
        <div className="py-3 flex">
          <div className="flex-1">{t('stable.trainings.fields.isPaid')}</div>
          <div className="font-medium flex-1 flex">
            {t(`common.bool.${training?.isPaid ? 'yes' : 'no'}`)}
          </div>
        </div>
        <div className="hidden">
          <div className="flex-1">{t('accountancy.paymentMethods.item')}</div>
          <div className="font-medium flex-1 flex"><BooleanIcon value={training?.isPaid} className="inline h-8 mr-3 -mt-2" /> On-line</div>
        </div>
        <div></div>
      </div>
      <div className="flex flex-row justify-between">
        <div>
          <LocalizedLink to="/user/trainings">
            <Button type="button" colorName="gray" className="px-5 py-3">
              <FontAwesomeIcon icon={faChevronLeft} /> {t('ui.goBack')}
            </Button>
          </LocalizedLink>
        </div>
        <div className="flex flex-row gap-x-3 items-center">
          {loading && <Spinner />}
          {training?.paymentMethodId && !training?.isPaid && (
            <LocalizedLink to={`/stable/book-training/${training?.typeId}/${training?.id}`}>
              <Button type="button" colorName="primary" className="px-5 py-3" disabled={loading}>
                <FontAwesomeIcon icon={faCreditCard} /> {t('financial.payments.do')}
              </Button>
            </LocalizedLink>
          )}
          {!training?.isCanceled && canCancel && (
            <Button type="button" colorName="red" className="px-5 py-3" onClick={onCancel} disabled={loading}>
              <FontAwesomeIcon icon={faStopCircle} /> {t('stable.trainings.cancel')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default TrainingView;