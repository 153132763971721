import { faCheckCircle, faMerge, faPlusCircle, faSave, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TenantRequest, TenantRequestStatus } from "src/api/licensing/Licensing";
import Button from "src/components/Actions/Button";
import useClaim from "src/hooks/useClaim";
import { IForm } from "src/hooks/useForm";

export interface TenantRequestHeaderComponentProps {
  tenantRequest: TenantRequest;
  onDelete: () => void;
  onDeploy: () => void;
  onAccept: () => void;
  onDeny: () => void;
  form: IForm<TenantRequest>
}

export default (props: TenantRequestHeaderComponentProps) => {
  const { tenantRequest, onDelete, onDeploy, onAccept, onDeny, form } = props;
  const { t } = useTranslation();

  const isAvailableToMerge = useMemo(() => {
    if (form.data.apply?.app?.locales === undefined || form.data.apply.app.locales.length === 0) return false;
    if (form.data.apply?.app?.period === undefined) return false;
    if (form.data.apply?.app?.productId === undefined) return false;
    if (form.data.apply?.app?.subdomain === undefined) return false;
    if (form.data.apply?.app?.timezone === undefined) return false;
    if (form.data.apply?.owner?.givenName === undefined) return false;
    if (form.data.apply?.owner?.surname === undefined) return false;
    if (form.data.apply?.owner?.email === undefined) return false;
    if (form.data.apply?.owner?.phoneNumber === undefined) return false;
    if (form.data.apply?.center?.name === undefined) return false;
    if (form.data.apply?.center?.address === undefined) return false;
    if (form.data.apply?.center?.city === undefined) return false;
    if (form.data.apply?.center?.postCode === undefined) return false;
    return true;
  }, [
    form.data.apply?.app?.locales?.length,
    form.data.apply?.app?.period,
    form.data.apply?.app?.productId,
    form.data.apply?.app?.subdomain,
    form.data.apply?.app?.timezone,
    form.data.apply?.owner?.givenName,
    form.data.apply?.owner?.surname,
    form.data.apply?.owner?.email,
    form.data.apply?.owner?.phoneNumber,
    form.data.apply?.center?.name,
    form.data.apply?.center?.address,
    form.data.apply?.center?.city,
    form.data.apply?.center?.postCode,
  ])

  const updateClaim = useClaim('LicensesUpdate');
  const deleteClaim = useClaim('LicensesDelete');
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div className="pt-1.5">
          <h1 className="text-2xl font-medium text-gray-900">{tenantRequest?.apply?.app?.subdomain || tenantRequest.id || t('licensing.tenantRequests.actions.create')}</h1>
          <div className="text-sm font-medium text-gray-500">
            {tenantRequest.created && <span>{t('common.fields.created')}: {tenantRequest.created?.toLocaleString()}</span>}
            {tenantRequest.updated && <span>, {t('common.fields.updated')}: {tenantRequest.updated?.toLocaleString()}</span>}
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {updateClaim && <Button className="text-md px-5 py-3" colorName="primary" disabled={form.pending}><FontAwesomeIcon icon={form.data.id ? faSave : faPlusCircle} className="mr-1" /> {form.data.id ? t('common.actions.save') : t('common.actions.add')}</Button>}
        {updateClaim && form.data.id && <Button type="button" className="text-md px-5 py-3" colorName="primary" onClick={onDeploy} disabled={form.pending || !isAvailableToMerge}><FontAwesomeIcon icon={faMerge} className="mr-1" /> {t('licensing.tenantRequest.deployment.do')}</Button>}
        {updateClaim && form.data.id && form.data.status === TenantRequestStatus.Pending && <Button type="button" className="text-md px-5 py-3" colorName="green" onClick={onAccept} disabled={form.pending || form.dirty}><FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> {t('licensing.tenantRequest.accept')}</Button>}
        {updateClaim && form.data.id && form.data.status === TenantRequestStatus.Pending && <Button type="button" className="text-md px-5 py-3" colorName="red" onClick={onDeny} disabled={form.pending || form.dirty}><FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> {t('licensing.tenantRequest.deny')}</Button>}
        {deleteClaim && form.data.id && form.data.status !== TenantRequestStatus.Active && <Button className="text-md px-5 py-3" type="button" colorName="red" onClick={onDelete} disabled={form.pending}><FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> {t('common.actions.delete')}</Button>}
      </div>
    </div >
  )
}
