import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { License, LicensesClient } from 'src/api/licensing/Licensing';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { QueryOrderDirection } from 'src/api/Base';

interface LicenseFilters {
  key?: string;
  name?: string;
  hostname?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(_form: IForm<LicenseFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  return f;
}

function renderFiltersForm(form: IForm<LicenseFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const LicensesTable = () => {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new LicensesClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<License>>();
  const updateClaim = useClaim('LicensesUpdate');
  const deleteClaim = useClaim('LicensesDelete');
  const authGuard = useAuthGuard('/panel/', ['LicensesRead']);
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastLicense() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Booking)}/hubs/LicensesHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: License) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<License>[] = [
    { label: t('common.fields.key'), selector: row => row.id, isOrderable: true, id: "id" },
    { label: t('users.item'), selector: row => row.userId, isOrderable: true, id: "userId" },
    { label: t('common.fields.name'), selector: row => row.name, isOrderable: true, id: "name" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="flex justify-end gap-x-3">
        {updateClaim &&
          <LocalizedLink to={`/panel/licensing/licenses/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && row.id && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "licensing.licenses.group", href: "/panel/licensing/licenses/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="pt-2">
          <h1 className="leading-1 text-2xl">{t('licensing.licenses.group')}</h1>
        </div>
        <div className="flex gap-x-3">
          {updateClaim &&
            <LocalizedLink to="/panel/booking/licenses/create">
              <Button colorName="emerald" className="px-5 py-3 text-md">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('licensing.licenses.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<License>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        defaultOrder={{ property: 'created', direction: QueryOrderDirection.DESC }}
        filters={filters}
      />
    </>
  )
}

export default LicensesTable;