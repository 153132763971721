import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TenantRequest } from 'src/api/licensing/Licensing';
import FormInput from 'src/components/Form/FormInput'
import PhoneNumberForm from 'src/components/Form/PhoneNumberForm';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface ApplyOwnerFormComponentProps {
  form: IForm<TenantRequest>;
}

export default function ApplyOwnerForm(props: ApplyOwnerFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('licensing.tenantRequests.form.apply.owner.header')}
      description={t('licensing.tenantRequests.form.apply.owner.subheader')}
    >
      <div className="md:flex md:flex-col gap-y-5">
        <div className="md:flex md:flex-row gap-x-3">
          <div className="flex-1">
            <FormInput.Default {...form.input('apply.owner.givenName', 'text', { placeholder: t('users.fields.givenName'), required: true })} />
          </div>
          <div className="flex-1">
            <FormInput.Default {...form.input('apply.owner.surname', 'text', { placeholder: t('users.fields.surname'), required: true })} />
          </div>
        </div>
        <div>
          <FormInput.Default {...form.input('apply.owner.email', 'email', { placeholder: t('users.fields.email'), required: true })} />
        </div>
        <div>
          <PhoneNumberForm {...form.input('apply.owner.phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber'), required: true })} />
        </div>
      </div>
    </FormSection>
  )
}
