import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { setBreadcrumbs } from "src/store/application/actions";
import HelpData from "src/data/Help.json";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleUp, faChevronDown, faChevronUp, faEnvelope, faPhone } from "@fortawesome/pro-duotone-svg-icons";
import Button from "src/components/Actions/Button";
import LocalizedLink from "src/components/Router/LocalizedLink";
import Alert from "src/components/Feedback/Alert";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import useTenant from "src/hooks/useTenant";
import moment from "moment";

interface HelpCategory {
  id: string;
  translations?: HelpCategoryTranslation[];
  articles?: HelpArticle[];
}
interface HelpCategoryTranslation {
  culture?: string;
  title?: string;
  description?: string;
}
interface HelpArticle {
  id: string;
  translations?: HelpArticleTranslation[];
  href?: string;
}
interface HelpArticleTranslation {
  culture?: string;
  title?: string;
  content?: string;
  steps?: string[];
  notes?: string[];
  href?: string;
}

interface HelpCategoryViewProps {
  category: HelpCategory;
}
interface HelpArticleViewProps {
  article: HelpArticle;
}

const HelpCategoryView = (props: HelpCategoryViewProps) => {
  const { category } = props;
  const [isOpen, setOpen] = useState(false);
  const translation = useEntityTranslation<HelpCategory, HelpCategoryTranslation>();

  return (
    <>
      <div className="ring ring-gray-200 rounded-md overflow-hidden">
        <div className="bg-gray-100 px-5 py-2 overflow-hidden">
          <div className="flex flex-row justify-between cursor-pointer items-center" onClick={() => setOpen(!isOpen)}>
            <div className="flex flex-col">
              <h2 className="text-gray-900 text-xl">{translation.getCurrentTranslation(category)?.title}</h2>
              <p className="text-gray-600">{translation.getCurrentTranslation(category)?.description}</p>
            </div>
            <div>
              <FontAwesomeIcon icon={isOpen ? faChevronCircleUp : faChevronCircleDown} className="text-gray-600 size-8" />
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="overflow-hidden flex flex-col divide-y divide-gray-100">
            {category.articles?.map((article, index) => (
              <HelpArticleView key={index} article={article} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

const HelpArticleView = (props: HelpArticleViewProps) => {
  const { article } = props;
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const translation = useEntityTranslation<HelpArticle, HelpArticleTranslation>();

  const content = translation.getCurrentTranslation(article)?.content;
  const href = translation.getCurrentTranslation(article)?.href;
  const steps = translation.getCurrentTranslation(article)?.steps;

  return (
    <>
      <div className="overflow-hidden">
        <div className="bg-gray-50 px-5 py-3">
          <div className="flex flex-row justify-between cursor-pointer" onClick={() => setOpen(!isOpen)}>
            <div className="flex flex-col">
              <h2 className="text-gray-900 text-lg">{translation.getCurrentTranslation(article)?.title}</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="text-gray-500 pr-2" />
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="p-5 overflow-hidden flex flex-col gap-y-5">
            {content && <p>{content}</p>}
            {steps !== undefined && steps.length > 0 && (
              <>
                <div className="text-medium">{t('panel.help.steps')}:</div>
                <ul className="flex flex-col gap-y-2 lg:pl-3">
                  {steps.map((step, i) => <li key={i} className="flex flex-row gap-x-3 items-center group">
                    <div className="flex-shrink-0 bg-gray-700 group-hover:bg-[--color-primary-700] text-white size-6 text-center rounded-full text-xs pt-1 font-semibold transition-colors duration-300 delay-100">{i + 1}</div>
                    <div className="group-hover:font-medium">{step}</div>
                  </li>)}
                </ul>
              </>
            )}
            {translation.getCurrentTranslation(article)?.notes && (
              <p>{translation.getCurrentTranslation(article)?.notes}</p>
            )}
            {article.href && href && (
              <>
                <div className="flex flex-col gap-y-2 lg:items-end">
                  <div className="text-sm">{t('panel.help.href')}:</div>
                  <div>
                    {article.href.startsWith('/') ? (
                      <LocalizedLink to={article.href}>
                        <Button type="button" colorName="primary" className="text-xs px-3">{href}</Button>
                      </LocalizedLink>
                    ) : (
                      <a href={article.href} target="_blank" rel="noopener">
                        <Button type="button" colorName="primary" className="text-xs px-3">{href}</Button>
                      </a>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}


const Help = () => {
  const { t, i18n } = useTranslation();
  const applicationDispatch = useApplicationDispatch();
  const tenant = useTenant();
  const data = HelpData as HelpCategory[];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "panel.help.header", href: "/panel/help" }
      ])
    );
  }, []);

  return (
    <>
      <div className="mb-5">
        <h1 className="leading-1 text-2xl">{t('panel.help.header')}</h1>
      </div>
      <div className="flex flex-col gap-y-5 divide-y divide-gray-200">
        {data.map((category, index) => (
          <HelpCategoryView key={index} category={category} />
        ))}
      </div>

      <Alert.Information title={t('panel.help.other.title')} noClose>
        <div className="flex flex-col lg:flex-row lg:justify-between gap-y-5">
          <div className="flex flex-col gap-y-5">
            <p>
              {t('panel.help.other.description')}
            </p>
            <div>
              <p className="text-sm font-medium">
                {t('panel.help.other.workingHours')}
              </p>
              <table>
                <tr>
                  <td className="pr-5">
                    {moment()
                      .startOf('isoWeek')
                      .toDate()
                      .toLocaleDateString(i18n.resolvedLanguage, { weekday: 'long' })}
                    {" "}-{" "}
                    {moment()
                      .startOf('isoWeek')
                      .add(4, 'days')
                      .toDate()
                      .toLocaleDateString(i18n.resolvedLanguage, { weekday: 'long' })}
                  </td>
                  <td>08:00</td>
                  <td>-</td>
                  <td>16:00</td>
                </tr>
                <tr>
                  <td className="pr-5">
                    {moment()
                      .startOf('isoWeek')
                      .add(5, 'days')
                      .toDate()
                      .toLocaleDateString(i18n.resolvedLanguage, { weekday: 'long' })}
                  </td>
                  <td>09:00</td>
                  <td>-</td>
                  <td>13:00</td>
                </tr>
              </table>
              <p className="text-xs">
                {t('panel.help.other.workingHoursExclamation')}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-3 lg:min-w-48">
            <a href="mailto:wsparcie@redini.app" target="_blank" rel="noopener">
              <Button type="button" colorName="sky" className="text-xs px-3 w-full">
                <FontAwesomeIcon icon={faEnvelope} className="mr-1" /> wsparcie@redini.app
              </Button>
            </a>
            <a href="tel:48585008439" target="_blank" rel="noopener">
              <Button type="button" colorName="sky" className="text-xs px-3 w-full">
                <FontAwesomeIcon icon={faPhone} className="mr-1" /> 58 500 84 39
              </Button>
            </a>
            <a href={`http://m.me/rediniapp?ref=Help-Panel-${tenant}`} target="_blank" rel="noopener">
              <Button type="button" colorName="sky" className="text-xs px-3 w-full">
                <FontAwesomeIcon icon={faFacebookMessenger} className="mr-1" /> m.me/redini.app
              </Button>
            </a>
          </div>
        </div>
      </Alert.Information>
    </>
  )
}

export default Help;