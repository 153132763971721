import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TenantRequest } from 'src/api/licensing/Licensing';
import FormInput from 'src/components/Form/FormInput'
import CountrySelect from 'src/components/i18n/CountryComboBox';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface ApplyBusinessFormComponentProps {
  form: IForm<TenantRequest>;
}

export default function ApplyBusinessForm(props: ApplyBusinessFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('licensing.tenantRequests.form.apply.business.header')}
      description={t('licensing.tenantRequests.form.apply.business.subheader')}
    >
      <div className="md:flex md:flex-col gap-y-5">
        <div>
          <FormInput.Default {...form.input('apply.business.name', 'text', { placeholder: t('licensing.tenantRequests.fields.apply.business.name') })} />
        </div>
        <div>
          <FormInput.Default {...form.input('apply.business.vatId', 'text', { placeholder: t('accountancy.sides.fields.vatId') })} />
        </div>
        <div>
          <FormInput.Default {...form.input('apply.business.address', 'text', { placeholder: t('address.fields.address') })} />
        </div>
        <div className="flex flex-row gap-x-3">
          <div className="w-1/4">
            <FormInput.Default {...form.input('apply.business.postCode', 'text', { placeholder: t('address.fields.postCode') })} />
          </div>
          <div className="flex-grow">
            <FormInput.Default {...form.input('apply.business.city', 'text', { placeholder: t('address.fields.city') })} />
          </div>
        </div>
        <div>
          <CountrySelect
            value={form.data.apply?.business?.countryCode}
            onChange={(val) => form.set('apply.business.countryCode', val)}
            placeholder={t('address.fields.country')}
            allowNull
          />
        </div>
        <div>
          <FormInput.Default {...form.input('apply.business.email', 'email', { placeholder: t('users.fields.email') })} />
        </div>
      </div>
    </FormSection>
  )
}
