import moment from "moment";
import { useState, useEffect, useRef, useMemo } from "react";
import { Training, GeneralTask, BookingsClient, GeneralTasksClient, PeriodicBookingDeleteRequest, PeriodicUpdateDecision, TrainingsClient, HorseTask, HorseAbsence, HorseTasksClient, GroupTraining } from "src/api/stable/Booking";
import { Horse, HorseTranslation, Instructor, InstructorTranslation, StableUser, StableUserAbsence, StableUserAbsencesClient, TrainingType, TrainingTypeTranslation } from "src/api/stable/Stable";
import CalendarWeekController from "src/components/Calendar/CalendarWeekController";
import CalendarWeekView, { AvailableHours, CalendarEvent } from "src/components/Calendar/CalendarWeekView";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import useConfigurationState from "src/hooks/useConfigurationState";
import _ from "lodash";
import useLocalizedNavigate from "src/hooks/useNavigate";
import PeriodicOperationModal, { PeriodicOperation, PeriodicOperationModalRef } from "src/components/Booking/PeriodicOperationModal";
import Toast from "src/components/Feedback/Toast";
import { useTranslation } from "react-i18next";
import BookingAddContext, { BookingAddContextRef } from "src/components/Booking/BookingAddContext";
import useClaim from "src/hooks/useClaim";
import useUser from "src/hooks/useUser";

interface AgendaWeekProps {
  user?: StableUser;
  horse?: Horse;
  showInstructors?: boolean;
  showHorses?: boolean;
}

const AgendaWeek = (props: AgendaWeekProps) => {
  const { horse, user, showInstructors, showHorses } = props;
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const [deletedEvent, setDeletedEvent] = useState<CalendarEvent | undefined>();
  const currentUser = useUser();

  const instructorTranslation = useEntityTranslation<Instructor, InstructorTranslation>();
  const horseTranslation = useEntityTranslation<Horse, HorseTranslation>();

  const [trainings, setTraingings] = useState<Training[]>([]);
  const [groupTrainings, setGroupTrainings] = useState<GroupTraining[]>([]);
  const [generalTasks, setGeneralTasks] = useState<GeneralTask[]>([]);
  const [horseTasks, setHorseTasks] = useState<HorseTask[]>([]);
  const [horseAbsences, setHorseAbsences] = useState<HorseAbsence[]>([]);
  const [stableUserAbsences, setStableUserAbsences] = useState<StableUserAbsence[]>([]);

  const apiConfiguration = useApiConfiguration();
  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const [startCalendar, setStartCalendar] = useState(moment().startOf('isoWeek').toDate());
  const deleteModal = useRef<PeriodicOperationModalRef>(null);
  const bookingAddContext = useRef<BookingAddContextRef>(null);

  const bookingsClient = new BookingsClient(apiConfiguration);
  const generalTasksClient = new GeneralTasksClient(apiConfiguration);
  const horseTasksClient = new HorseTasksClient(apiConfiguration);
  const trainingsClient = new TrainingsClient(apiConfiguration);
  const stableUserAbsencesClient = new StableUserAbsencesClient(apiConfiguration);
  const horseAbsencesClient = new StableUserAbsencesClient(apiConfiguration);

  const canUpdateHorse = useClaim('HorsesUpdate');
  const canUpdateInstructor = useClaim('InstructorsUpdate');
  const canUpdateTrainings = useClaim('TrainingsUpdate');
  const canUpdateTasks = useClaim('TasksUpdate');
  const canDeleteTrainings = useClaim('TrainingsDelete');
  const canDeleteTasks = useClaim('TasksDelete');
  const canDeleteAllTrainings = useClaim('AllTrainingsDelete');

  const fetch = () => {
    if (horse) {
      bookingsClient
        .horseAgenda(horse.id!, startCalendar, moment(startCalendar).add(7, 'days').toDate())
        .then(response => {
          setGeneralTasks(response.generalTasks ?? []);
          setHorseTasks(response.horseTasks ?? []);
          setTraingings(response.trainings ?? []);
          setGroupTrainings(response.groupTrainings ?? []);
          setHorseAbsences(response.horseAbsences ?? []);
          setStableUserAbsences(response.stableUserAbsences ?? []);
        });
    }
    if (user) {
      bookingsClient
        .stableUserAgenda(user?.id ?? "00000000-0000-0000-0000-000000000000", startCalendar, moment(startCalendar).add(7, 'days').toDate())
        .then(response => {
          setGeneralTasks(response.generalTasks ?? []);
          setHorseTasks(response.horseTasks ?? []);
          setTraingings(response.trainings ?? []);
          setGroupTrainings(response.groupTrainings ?? []);
          setHorseAbsences(response.horseAbsences ?? []);
          setStableUserAbsences(response.stableUserAbsences ?? []);
        });
    }
    else {
      bookingsClient
        .globalAgenda(startCalendar, moment(startCalendar).add(7, 'days').toDate())
        .then(response => {
          setGeneralTasks(response.generalTasks ?? []);
          setHorseTasks(response.horseTasks ?? []);
          setTraingings(response.trainings ?? []);
          setGroupTrainings(response.groupTrainings ?? []);
          setHorseAbsences(response.horseAbsences ?? []);
          setStableUserAbsences(response.stableUserAbsences ?? []);
        });
    }
  }

  useEffect(() => {
    fetch();
  }, [startCalendar]);

  const configurationState = useConfigurationState();
  const hours: AvailableHours[] = _.map(
    configurationState?.booking?.hours,
    (v, k) => ({ key: Number(k), start: [Number(v.start[0]), Number(v.start[1])], end: [Number(v.end[0]), Number(v.end[1])] })
  )
    .sort((a, b) => a.key - b.key)
    .map(v => ({ start: v.start, end: v.end }));

  const calendarEvents = useMemo<CalendarEvent[]>((): CalendarEvent[] => [
    ...stableUserAbsences.map(absence => ({
      id: absence.id!,
      start: absence.start!,
      end: absence.end!,
      label: "Nieobecność",
      colorName: "red",
      active: true,
      deletable: canUpdateInstructor,
      description: [
        showInstructors ? absence.user?.user?.givenName : undefined,
      ]
    } as CalendarEvent)),
    ...horseAbsences.map(absence => ({
      id: absence.id!,
      start: absence.start!,
      end: absence.end!,
      label: "Nieobecność",
      colorName: "red",
      active: true,
      deletable: canUpdateHorse,
      description: [
        showHorses ? horseTranslation.getCurrentTranslation(absence.horse)?.name : undefined
      ]
    } as CalendarEvent)),
    ...generalTasks.map(task => ({
      id: task.id!,
      start: task.start!,
      end: task.end!,
      label: task.title,
      colorName: "amber",
      active: true,
      isConfirmed: task.isDone,
      deletable: canDeleteTasks,
      isPeriodic: task.parentId,
      description: [
        showInstructors ? task.user?.user?.givenName : undefined,
      ]
    } as CalendarEvent)),
    ...horseTasks.map(task => ({
      id: task.id!,
      start: task.start!,
      end: task.end!,
      label: task.title,
      colorName: "pink",
      active: true,
      isConfirmed: task.isDone,
      deletable: canDeleteTasks,
      isPeriodic: task.parentId,
      description: [
        showInstructors ? task.user?.user?.givenName : undefined,
        showHorses ? horseTranslation.getCurrentTranslation(task.horse)?.name : undefined
      ]
    } as CalendarEvent)),
    ...trainings.map(training => ({
      id: training.id!,
      start: training.start!,
      end: training.end!,
      label: trainingTypeTranslation.getCurrentTranslation(training.type)?.name ?? t('stable.trainings.item'),
      colorName: "indigo",
      active: true,
      deletable: canDeleteAllTrainings || (canDeleteTrainings && training.instructor?.userId === currentUser?.id),
      isPeriodic: training.parentId,
      isConfirmed: training.isConfirmed,
      description: [
        showInstructors ? instructorTranslation.getCurrentTranslation(training.instructor)?.name : undefined,
        showHorses ? horseTranslation.getCurrentTranslation(training.horse)?.name : undefined
      ],
    } as CalendarEvent)),
    ...groupTrainings.map(training => ({
      id: training.id!,
      start: training.start!,
      end: training.end!,
      label: trainingTypeTranslation.getCurrentTranslation(training.type)?.name ?? t('stable.trainings.groupTraining'),
      colorName: "sky",
      active: true,
      deletable: canDeleteAllTrainings || (canDeleteTrainings && training.instructor?.userId === currentUser?.id),
      isConfirmed: training.participants?.some(p => p.isConfirmed),
      isGroup: true,
      description: [
        `${training?.participants?.filter(p => p.riderId && p.isConfirmed !== false)?.length}/${training.type?.maximalRiders ?? 1}`,
        showInstructors ? instructorTranslation.getCurrentTranslation(training.instructor)?.name : undefined
      ],
    } as CalendarEvent)),
  ], [trainings, groupTrainings, generalTasks, horseTasks, stableUserAbsences, horseAbsences, currentUser]);

  const onClickTerm = (start: Date, _end: Date) => {
    bookingAddContext.current?.setDate(start);
    bookingAddContext.current?.open();
  }

  function onClickEvent(event: CalendarEvent): void {
    const training = trainings.find(t => t.id === event.id);
    const groupTraining = groupTrainings.find(t => t.id === event.id);
    const generalTask = generalTasks.find(t => t.id === event.id);
    const horseTask = horseTasks.find(t => t.id === event.id);
    const horseAbsence = horseAbsences.find(t => t.id === event.id);
    const stableUserAbsence = stableUserAbsences.find(t => t.id === event.id);
    if (generalTask) {
      window.scrollTo(0, 0);
      navigate(`/panel/stable/workers/tasks/${generalTask.id}`);
    }
    if (horseTask) {
      window.scrollTo(0, 0);
      navigate(`/panel/stable/horses/tasks/${horseTask.id}`);
    }
    else if (training) {
      window.scrollTo(0, 0);
      navigate(`/panel/booking/trainings/${training.id}`);
    }
    else if (groupTraining) {
      window.scrollTo(0, 0);
      navigate(`/panel/booking/group-trainings/${groupTraining.id}`);
    }
    else if (stableUserAbsence) {
      window.scrollTo(0, 0);
      navigate(`/panel/stable/instructors/${stableUserAbsence.userId}`);
    }
    else if (horseAbsence) {
      window.scrollTo(0, 0);
      navigate(`/panel/stable/horses/${horseAbsence.horseId}`);
    }
  }

  const onDeleteEvent = (event: CalendarEvent) => {
    const training = trainings.find(t => t.id === event.id);
    const groupTraining = groupTrainings.find(t => t.id === event.id);
    const generalTask = generalTasks.find(t => t.id === event.id);
    const horseTask = horseTasks.find(t => t.id === event.id);
    const horseAbsence = horseAbsences.find(t => t.id === event.id);
    const stableUserAbsence = stableUserAbsences.find(t => t.id === event.id);
    if (generalTask) {
      generalTasksClient.find(generalTask.id!)
        .then((taskResponse) => {
          if (taskResponse.periodic || taskResponse.parentId) {
            setDeletedEvent(event);
            deleteModal.current?.setDate(event.start);
            deleteModal.current?.open();
          } else {
            generalTasksClient
              .delete(generalTask.id!, { periodicUpdateDate: generalTask.start, periodicUpdateDecision: PeriodicUpdateDecision._0 } as PeriodicBookingDeleteRequest)
              .then(() => {
                setDeletedEvent(undefined);
                fetch();
              })
              .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
          }
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    } else if (horseTask) {
      horseTasksClient.find(horseTask.id!)
        .then((taskResponse) => {
          if (taskResponse.periodic || taskResponse.parentId) {
            setDeletedEvent(event);
            deleteModal.current?.setDate(event.start);
            deleteModal.current?.open();
          } else {
            horseTasksClient
              .delete(horseTask.id!, { periodicUpdateDate: horseTask.start, periodicUpdateDecision: PeriodicUpdateDecision._0 } as PeriodicBookingDeleteRequest)
              .then(() => {
                setDeletedEvent(undefined);
                fetch();
              })
              .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
          }
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    } else if (training) {
      trainingsClient.find(training.id!)
        .then((taskResponse) => {
          if (taskResponse.periodic || taskResponse.parentId) {
            setDeletedEvent(event);
            deleteModal.current?.setDate(event.start);
            deleteModal.current?.open();
          } else {
            trainingsClient
              .delete(training.id!, { periodicUpdateDate: training.start, periodicUpdateDecision: PeriodicUpdateDecision._0 } as PeriodicBookingDeleteRequest)
              .then(() => {
                setDeletedEvent(undefined);
                fetch();
              })
              .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
          }
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    } else if (groupTraining) {
      const sessionTrainingId = groupTraining.id!;
      const participantTrainingIds = groupTraining.participants?.map(p => p.bookingId!);

      trainingsClient.find(sessionTrainingId)
        .then((taskResponse) => {
          if (taskResponse.periodic || taskResponse.parentId) {
            setDeletedEvent(event);
            deleteModal.current?.setDate(event.start);
            deleteModal.current?.open();
          } else {
            const promises = [
              trainingsClient.delete(sessionTrainingId, { periodicUpdateDate: groupTraining.start, periodicUpdateDecision: PeriodicUpdateDecision._0 } as PeriodicBookingDeleteRequest),
              ...(participantTrainingIds?.map(participantId => {
                return trainingsClient
                  .delete(participantId, { periodicUpdateDate: groupTraining.start, periodicUpdateDecision: PeriodicUpdateDecision._0 } as PeriodicBookingDeleteRequest);
              }) ?? [])
            ];
            Promise.all(promises)
              .then(() => {
                setDeletedEvent(undefined);
                fetch();
              })
              .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
          }
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    } else if (stableUserAbsence) {
      stableUserAbsencesClient
        .delete(stableUserAbsence.id!)
        .then(() => {
          setDeletedEvent(undefined);
          fetch();
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    } else if (horseAbsence) {
      horseAbsencesClient
        .delete(horseAbsence.id!)
        .then(() => {
          setDeletedEvent(undefined);
          fetch();
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const onConfirmDelete = (decision: PeriodicUpdateDecision, date: Date) => {
    if (!deletedEvent) return;
    const training = trainings.find(t => t.id === deletedEvent.id);
    const groupTraining = groupTrainings.find(t => t.id === deletedEvent.id);
    const generalTask = generalTasks.find(t => t.id === deletedEvent.id);
    const horseTask = horseTasks.find(t => t.id === deletedEvent.id);
    if (generalTask) {
      generalTasksClient
        .delete(generalTask.id!, { periodicUpdateDate: date, periodicUpdateDecision: decision } as PeriodicBookingDeleteRequest)
        .then(() => {
          setDeletedEvent(undefined);
          fetch();
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
    else if (horseTask) {
      horseTasksClient
        .delete(horseTask.id!, { periodicUpdateDate: date, periodicUpdateDecision: decision } as PeriodicBookingDeleteRequest)
        .then(() => {
          setDeletedEvent(undefined);
          fetch();
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
    else if (training) {
      trainingsClient
        .delete(training.id!, { periodicUpdateDate: date, periodicUpdateDecision: decision } as PeriodicBookingDeleteRequest)
        .then(() => {
          setDeletedEvent(undefined);
          fetch();
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
    else if (groupTraining) {
      const sessionTrainingId = groupTraining.id!;
      const participantTrainingIds = groupTraining.participants?.map(p => p.bookingId!);

      const promises = [
        trainingsClient.delete(sessionTrainingId, { periodicUpdateDate: groupTraining.start, periodicUpdateDecision: PeriodicUpdateDecision._0 } as PeriodicBookingDeleteRequest),
        ...(participantTrainingIds?.map(participantId => {
          return trainingsClient
            .delete(participantId, { periodicUpdateDate: groupTraining.start, periodicUpdateDecision: PeriodicUpdateDecision._0 } as PeriodicBookingDeleteRequest);
        }) ?? [])
      ];
      Promise.all(promises)
        .then(() => {
          setDeletedEvent(undefined);
          fetch();
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const startHour = useMemo(() => {
    const h = [
      ...hours.map(h => h.start[0]),
      ...calendarEvents.map(e => e.start.getHours())
    ].filter(h => h !== 0);
    return _.min(h) || 7
  }, [hours]);
  const endHour = useMemo(() => {
    const h = [
      ...hours.map(h => h.end[1] ? h.end[0] + 1 : h.end[0]),
      ...calendarEvents.map(e => e.end.getHours() + 1)
    ].filter(h => h !== 0);
    return _.max(h) || 18;
  }, [hours]);

  return (
    <>
      <div className="cleafix">
        <div className="float-end">
          <CalendarWeekController currentDate={startCalendar} onChangeDate={setStartCalendar} />
        </div>
      </div>
      <CalendarWeekView
        startCalendar={startCalendar}
        startHour={startHour}
        endHour={endHour}
        events={calendarEvents}
        availableHours={hours}
        onClickTerm={onClickTerm}
        onClickEvent={onClickEvent}
        onDeleteEvent={onDeleteEvent}
        className="bg-white overflow-hidden w-full h-full"
        termInterval={30}
        termGranulation={30}
        hideTerms={!canUpdateTrainings && !canUpdateTasks}
        hasOverlappings
      />
      <PeriodicOperationModal ref={deleteModal} onConfirm={onConfirmDelete} operation={PeriodicOperation.Delete} />
      <BookingAddContext
        ref={bookingAddContext}
        horseId={horse?.id ?? undefined}
        userId={user?.id ?? undefined}
        riderId={user?.discriminator === "Rider" ? user.id : undefined}
        instructorId={user?.discriminator === "Instructor" ? user.id : undefined}
      />
    </>
  )
}

export default AgendaWeek;