import { faCheckCircle, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'src/components/Feedback/Toast';
import { GroupTraining, GroupTrainingParticipant, GroupTrainingsClient, HttpQueryFilter, HttpQueryOrder, PeriodicBookingDeleteRequest, PeriodicUpdateDecision, QueryOrderDirection, Training, TrainingsClient } from 'src/api/stable/Booking';
import PaymentMethodSelect from 'src/components/Accountancy/PaymentMethodSelect';
import Button from 'src/components/Actions/Button';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import HorseSelect from 'src/components/Stable/HorseSelect';
import RiderSelect from 'src/components/Stable/RiderSelect';
import useFunctionality from 'src/hooks/stores/useFunctionality';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useClaim from 'src/hooks/useClaim';
import useForm, { IForm } from 'src/hooks/useForm';
import { Dialog } from '@headlessui/react';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { Instructor, InstructorTranslation, TrainingType, TrainingTypeTranslation } from 'src/api/stable/Stable';
import Alert from 'src/components/Feedback/Alert';
import Spinner from 'src/components/Feedback/Spinner';

export interface ParticipantsFormComponentProps {
  form: IForm<GroupTraining>;
  fetch: () => void;
}

export interface GroupTrainingParticipantRowProps {
  groupTraining: GroupTraining;
  participant?: GroupTrainingParticipant;
  onDelete?: () => void;
  onCreate?: (response: Training) => void;
  onUpdate?: (response: Training) => void;
}

export function ParticipantRow(props: GroupTrainingParticipantRowProps) {
  const { groupTraining, participant, onDelete, onCreate } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');
  const paymentsFunctionality = useFunctionality('module-payments');
  const form = useForm(participant ?? {} as GroupTrainingParticipant);

  const apiConfiguration = useApiConfiguration();
  const apiClient = new TrainingsClient(apiConfiguration);

  const onSubmit = () => {
    if (form.pending) return;
    if (form.data.bookingId) {
      form.setPending(true);
      apiClient
        .find(form.data.bookingId)
        .then(
          (response) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { horse, type, rider, instructor, place, ...dto } = response;
            dto.riderId = form.data.riderId;
            dto.horseId = form.data.horseId;
            dto.isConfirmed = form.data.isConfirmed;
            dto.isPaid = form.data.isPaid;
            dto.paymentMethodId = form.data.paymentMethodId;
            dto.periodicUpdateDate = groupTraining.start;
            dto.periodicUpdateDecision = PeriodicUpdateDecision._0;
            if (dto.paymentMethodId === "undefined" || dto.paymentMethodId === "null") {
              delete dto.paymentMethodId;
            }
            return apiClient
              .update(response.id!, dto as Training)
              .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
              .catch(e => form.catchAnyException(e, true));
          }
        )
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      form.setPending(true);
      apiClient
        .create({
          instructorId: groupTraining.instructorId,
          typeId: groupTraining.typeId,
          placeId: groupTraining.placeId,
          start: groupTraining.start,
          end: groupTraining.end,
          riderId: form.data.riderId,
          horseId: form.data.horseId,
          isConfirmed: form.data.isConfirmed,
          isPaid: form.data.isPaid,
          paymentMethodId: form.data.paymentMethodId
        } as Training)
        .then((response) => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          form.setData({} as GroupTrainingParticipant);
          if (onCreate) {
            onCreate(response);
          }
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const onClickDelete = () => {
    if (!form.data.bookingId) return;
    if (form.pending) return;
    form.setPending(true);
    apiClient
      .delete(form.data.bookingId, {
        periodicUpdateDate: groupTraining.start,
        periodicUpdateDecision: PeriodicUpdateDecision._0
      } as PeriodicBookingDeleteRequest)
      .then(() => {
        if (onDelete) {
          onDelete();
        }
      })
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  return (
    <tr className="flex flex-col gap-y-3 md:table-row border-b border-gray-200 md:border-0 pb-5 md:pb-0">
      {isDeveloper && <td>
        <th className="md:hidden font-medium uppercase text-sm text-gray-500">Id</th>
        <FormInput.WithoutLabel name="id" placeholder="Id" value={form.data.bookingId} readOnly />
      </td>}
      <td>
        <th className="md:hidden font-medium uppercase text-sm text-gray-500">{t('stable.riders.item')}</th>
        <RiderSelect
          {...form.input('riderId', 'select', {})}
          onChange={(riderId, rider) => form.setData({ ...form.data, riderId: riderId as string, rider: rider } as Training)}
          value={form.data.riderId} />
      </td>
      <td>
        <th className="md:hidden font-medium uppercase text-sm text-gray-500">{t('stable.horses.item')}</th>
        <HorseSelect
          {...form.input('horseId', 'select', {})}
          onChange={(horseId, horse) => form.setData({ ...form.data, horseId: horseId as string, horse: horse } as Training)}
          value={form.data.horseId} />
      </td>
      <td>
        <th className="md:hidden font-medium uppercase text-sm text-gray-500">{t('stable.trainings.fields.isConfirmed')}</th>
        <Select
          id="isConfirmed"
          value={form.data.isConfirmed !== null ? (form.data.isConfirmed ? "true" : "false") : "null"}
          onChange={(e) => form.setData({ ...form.data, isConfirmed: e.target.value === "true" ? true : e.target.value === "false" ? false : null } as Training)}
          options={[
            { id: "true", value: "true", label: t('common.bool.yes') },
            { id: "false", value: "false", label: t('common.bool.no') },
            { id: "null", value: "null", label: t('ui.choose') }
          ] as TextOptionProps[]}
        />
      </td>
      <td>
        <th className="md:hidden font-medium uppercase text-sm text-gray-500">{t('stable.trainings.fields.isPaid')}</th>
        <FormCheckbox.Input {...form.input('isPaid', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
      </td>
      {paymentsFunctionality && (
        <td>
          <th className="md:hidden font-medium uppercase text-sm text-gray-500">{t('accountancy.paymentMethods.item')}</th>
          <PaymentMethodSelect
            {...form.input('paymentMethodId', 'select', {})}
            onChange={(methodId) => form.setData({ ...form.data, paymentMethodId: methodId as string } as Training)}
            value={form.data.paymentMethodId} />
        </td>
      )}
      <td>
        <div className="flex flex-row gap-x-5">
          {form.data.bookingId ? (
            <>
              <Button type="button" colorName="sky" className="flex-1 px-5 py-3" onClick={onSubmit} disabled={!form.data.riderId}>
                <FontAwesomeIcon icon={faSave} />
              </Button>
              <Button type="button" colorName="red" className="flex-1 px-5 py-3" onClick={onClickDelete}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </>
          ) : (
            <>
              <Button type="button" colorName="sky" className="flex-1 px-5 py-3" onClick={onSubmit} disabled={!form.data.riderId}>
                <FontAwesomeIcon icon={faSave} /> {t('common.actions.add')}
              </Button>
            </>
          )}
        </div>
      </td>
    </tr>
  )
}

export default function ParticipantsForm(props: ParticipantsFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const paymentsFunctionality = useFunctionality('module-payments');
  const isDeveloper = useClaim('Developer');
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (!form.data.start) return;
    const endDate = new Date(form.data.start.getTime());
    if (form.data.end) {
      endDate.setHours(form.data.end.getHours());
      endDate.setMinutes(form.data.end.getMinutes());
      endDate.setSeconds(form.data.end.getSeconds());
    }
    form.setData({ ...form.data, end: endDate } as Training)
  }, [form.data.start]);

  useEffect(() => {
    if (!form.data.periodic) return;
    if (!form.data.start) return;
    if (form.data.periodic.period?.start) return;
    form.set('periodic.period.start', moment(form.data.start).add(1, 'day').startOf('day').toDate());
  }, [form.data.periodic])

  const onDeleteParticipant = (id: string) => {
    form.setData({
      ...form.data, participants: _.filter(form.data.participants, (p) => p.bookingId !== id)
    } as GroupTraining);
  };

  const onCreateParticipant = (training: Training) => {
    const map = {
      bookingId: training.id,
      riderId: training.riderId,
      horseId: training.horseId,
      isConfirmed: training.isConfirmed,
      isPaid: training.isPaid,
      paymentMethodId: training.paymentMethodId
    } as GroupTrainingParticipant;
    form.setData({
      ...form.data,
      participants: [...form.data.participants ?? [], map]
    } as GroupTraining);
  };

  useEffect(() => {
    if (!isOpen) return;
    props.fetch();
  }, [isOpen])

  const onSubmit = () => {
    props.fetch();
  }

  return (
    <>
      <FormSection
        title={t('stable.trainings.form.participants.header')}
        description={t('stable.trainings.form.participants.subheader')}
        full
      >
        <table className="w-full">
          <thead>
            <tr className="hidden md:table-row">
              {isDeveloper && <th className="font-medium uppercase text-sm text-gray-500">Id</th>}
              <th className="font-medium uppercase text-sm text-gray-500">{t('stable.riders.item')}</th>
              <th className="font-medium uppercase text-sm text-gray-500">{t('stable.horses.item')}</th>
              <th className="font-medium uppercase text-sm text-gray-500">{t('stable.trainings.fields.isConfirmed')}</th>
              <th className="font-medium uppercase text-sm text-gray-500">{t('stable.trainings.fields.isPaid')}</th>
              {paymentsFunctionality && <th className="font-medium uppercase text-sm text-gray-500">{t('accountancy.paymentMethods.item')}</th>}
            </tr>
          </thead>
          <tbody>
            {form.data.participants?.map(
              (participant, index) => <ParticipantRow
                key={index}
                participant={participant}
                groupTraining={form.data}
                onDelete={() => onDeleteParticipant(participant.bookingId!)}
              />)}
          </tbody>
          <tfoot>
            <ParticipantRow
              groupTraining={form.data}
              onCreate={onCreateParticipant}
            />
          </tfoot>
        </table>
        {form.data.participants !== undefined && form.data.participants.length > 0 && (
          <div className="flex flex-1 flex-row justify-end mt-5">
            <Button type="button" colorName="emerald" className="px-5 py-3" onClick={() => setOpen(true)}>
              {t('stable.trainings.groupTrainings.moveParticipants.title')}
            </Button>
          </div>
        )}
      </FormSection>
      <MoveParticipantsDialog
        isOpen={isOpen}
        setOpen={setOpen}
        groupTraining={form.data}
        onSubmit={onSubmit}
      />
    </>
  )
}

interface MoveParticipantsForm {
  participants: string[];
  trainings: string[];
  deleteFromCurrent?: boolean;
}

const MoveParticipantsDialog = ({
  isOpen, setOpen, groupTraining, onSubmit
}: {
  isOpen: boolean,
  setOpen: (open: boolean) => void,
  groupTraining: GroupTraining,
  onSubmit?: () => void
}) => {
  const { t } = useTranslation();
  const form = useForm<MoveParticipantsForm>({
    participants: [],
    trainings: [],
    deleteFromCurrent: false
  });
  const [trainings, setTrainings] = useState<GroupTraining[]>([]);
  const [step, setStep] = useState(0);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new TrainingsClient(apiConfiguration);
  const groupApiClient = new GroupTrainingsClient(apiConfiguration);

  useEffect(() => {
    if (!isOpen) return;
    form.setPending(true);
    fetchPossibleTrainings()
      .then((response) => setTrainings(response))
      .catch((e) => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }, [isOpen]);

  const disabledPrevStep = useMemo(() => {
    if (form.pending) return true;
    return false;
  }, [step, form.pending]);
  const disabledNextStep = useMemo(() => {
    if (form.pending) return true;
    if (step === 0 && form.data.participants.length === 0) return true;
    if (step === 1 && form.data.trainings.length === 0) return true;
    return false;
  }, [step, form.pending, form.data.participants, form.data.trainings]);

  const reset = () => {
    form.setPending(false);
    form.setData({ participants: [], trainings: [], deleteFromCurrent: false });
    setStep(0);
  }

  const prevStep = () => {
    if (step === 0) {
      reset();
      setOpen(false);
      return;
    }
    setStep(step - 1);
  }

  const nextStep = () => {
    if (step === 2) {
      submit();
      return;
    }
    setStep(step + 1);
  }

  const fetchPossibleTrainings = async (): Promise<GroupTraining[]> => {
    const response = await apiClient
      .get(
        [
          //{ property: 'start', value: groupTraining.start?.toISOString(), type: '>' },
          { property: 'typeId', value: groupTraining.typeId, type: '=' },
          { property: 'id', value: groupTraining.id, type: '!=' },
          { property: 'riderId', value: '', type: 'isNull' },

        ] as HttpQueryFilter[],
        [
          { property: 'start', direction: QueryOrderDirection.DESC }
        ] as HttpQueryOrder[],
        100,
        0,
        undefined,
        undefined
      );
    if (response?.items === undefined || response.items.length === 0) return [];
    const result = [];
    const tmp = response.items.reverse().filter(t => moment(t.start).isAfter(moment()));
    for (const r of tmp) {
      const resp = await groupApiClient.find(r.id!);
      if (resp) {
        result.push(resp);
      }
    }
    return result;
  }

  const submit = async () => {
    form.setPending(true);

    for (const p of form.data.participants) {
      const participant = groupTraining.participants?.find((pp) => pp.riderId === p);
      if (!participant) continue;
      for (const t of form.data.trainings) {
        const training = trainings.find((tt) => tt.id === t);
        if (!training) continue;
        if (training.participants !== undefined && training.participants?.filter(pp => pp.riderId === participant.riderId).length > 0) continue;
        await apiClient.create({
          riderId: participant.riderId,
          horseId: participant.horseId,
          instructorId: training.instructorId!,
          typeId: training.typeId!,
          placeId: training.placeId!,
          start: training.start!,
          end: training.end!,
        } as Training);
      }
    }

    if (form.data.deleteFromCurrent) {
      for (const p of form.data.participants) {
        const participant = groupTraining.participants?.find((pp) => pp.riderId === p);
        if (!participant) continue;
        await apiClient.delete(
          participant.bookingId!,
          {
            periodicUpdateDate: groupTraining.start,
            periodicUpdateDecision: PeriodicUpdateDecision._0
          } as PeriodicBookingDeleteRequest
        );
      }

    }


    Toast.success(t('common.status.success'), t('stable.trainings.groupTrainings.moveParticipants.moved'));
    setOpen(false);
    if (onSubmit) onSubmit();
    return true;
  }

  return (
    <Dialog open={isOpen} onClose={() => setOpen(false)} className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-4xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <Dialog.Title className="text-lg leading-6 font-medium text-gray-900">
              {t('stable.trainings.groupTrainings.moveParticipants.title')}
            </Dialog.Title>
            <Dialog.Title className="text-sm leading-4 text-gray-700 mt-3">
              {t('ui.steps.step')} {step + 1}/3
            </Dialog.Title>
            <hr className="mt-3" />
            <div className="mt-2">
              {step === 0 && <MoveParticipantsFirstStep
                form={form}
                participants={groupTraining.participants}
                groupTraining={groupTraining}
              />}
              {step === 1 && <MoveParticipantsSecondStep
                form={form}
                participants={groupTraining.participants}
                trainings={trainings}
                groupTraining={groupTraining}
              />}
              {step === 2 && <MoveParticipantsThirdStep
                form={form}
                participants={groupTraining.participants}
                trainings={trainings}
                groupTraining={groupTraining}
              />}
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-between sm:items-center">
            <Button type="button" colorName="red" className="px-5 py-3" onClick={() => prevStep()} disabled={disabledPrevStep}>
              {step === 0 ? t('common.actions.cancel') : t('ui.steps.previousStep')}
            </Button>
            <div className="sm:ml-auto sm:mr-5">{form.pending && <Spinner />}</div>
            <Button type="button" colorName="primary" className="px-5 py-3" onClick={() => nextStep()} disabled={disabledNextStep}>
              {step === 2 ? t('stable.trainings.groupTrainings.moveParticipants.do') : t('ui.steps.nextStep')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

interface MoveParticipantsFirstStepProps {
  form: IForm<MoveParticipantsForm>;
  participants?: GroupTrainingParticipant[];
  groupTraining: GroupTraining;
}
interface MoveParticipantsSecondStepProps extends MoveParticipantsFirstStepProps {
  trainings: GroupTraining[];
}

const MoveParticipantsFirstStep = ({ form, participants, groupTraining }: MoveParticipantsFirstStepProps) => {
  const { i18n, t } = useTranslation();
  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  return (
    <>
      <p className="mb-5">
        {t('stable.trainings.groupTrainings.moveParticipants.selectParticipants', {
          type: trainingTypeTranslation.getCurrentTranslation(groupTraining.type)?.name,
        })}
      </p>
      <div className="flex flex-col gap-y-5 bg-gray-50 shadow-inner p-5 rounded overflow-y-auto max-h-96">
        {participants?.map((p, index) => (
          <label
            key={index}
            htmlFor={`participants[]_${p.riderId}-${p.riderId}`}
            className="bg-white p-5 rounded flex flex-row justify-between items-center cursor-pointer"
          >
            <div className="px-5">
              <FormCheckbox.Input
                {...form.input(`participants[]`, 'checkbox', { value: p.riderId })}
              />
            </div>
            <div className="flex-grow">
              <p className="font-medium text-gray-800">{p.rider?.user?.fullName}</p>
              <div className="text-sm text-gray-500 flex gap-x-3 items-center">
                <div>{t('users.fields.email')}: {p.rider?.user?.email ?? 'brak'}</div>
                <div>{t('auth.fields.phoneNumber')}: {p.rider?.user?.phoneNumber ?? 'brak'}</div>
                <div className="flex flex-row gap-x-2 items-center">
                  <FontAwesomeIcon icon={p.isConfirmed === false ? faTimesCircle : faCheckCircle} className={p.isConfirmed === false ? 'text-red-700' : (p.isConfirmed === true ? 'text-emerald-600' : 'text-gray-300')} />
                  {t(`stable.trainings.isConfirmed.${p.isConfirmed === false ? 'false' : (p.isConfirmed === true ? 'true' : 'null')}`)}
                </div>
                {p.isPaid && <div className="flex flex-row gap-x-2 items-center">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-600" />
                  {t(`stable.trainings.fields.isPaid`)}
                </div>}
              </div>
            </div>
          </label>
        ))}
      </div>
      <div className="p-5 pb-3">
        <FormCheckbox.Input
          {...form.input(`deleteFromCurrent`, 'checkbox', {
            value: 'true', placeholder: t('stable.trainings.groupTrainings.moveParticipants.deleteFromCurrent', {
              date: groupTraining.start?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'long', timeStyle: 'short' })
            })
          })}
        />
      </div>
    </>
  )
}

const MoveParticipantsSecondStep = ({ form, trainings, groupTraining }: MoveParticipantsSecondStepProps) => {
  const { t, i18n } = useTranslation();
  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const instructorTranslation = useEntityTranslation<Instructor, InstructorTranslation>();
  return (
    <>
      <p className="mb-5">
        {t('stable.trainings.groupTrainings.moveParticipants.selectTrainings', {
          type: trainingTypeTranslation.getCurrentTranslation(groupTraining.type)?.name,
          participants: form.data.participants?.map((p) => {
            const participant = groupTraining.participants?.find((pp) => pp.riderId === p);
            if (participant === undefined) return '';
            return participant.rider?.user?.fullName;
          }).join(', ')
        })}
      </p>
      {trainings.length > 0 ? (
        <div className="flex flex-col gap-y-5 bg-gray-50 shadow-inner p-5 rounded overflow-y-auto max-h-96">
          {trainings?.map((training, index) => {
            const slots = training.type?.maximalRiders ?? 1;
            const freeSlots = slots - (training.participants?.length ?? 0);
            const addedSlots = form.data.participants?.filter(p => !training.participants?.some(pp => pp.riderId! === p)).length;
            const haveEnoughSlots = addedSlots <= freeSlots;
            return (
              <label
                key={index}
                htmlFor={`trainings[]_${training.id}-${training.id}`}
                className="bg-white p-5 rounded flex flex-row justify-between items-center cursor-pointer"
              >
                <div className="px-5">
                  <FormCheckbox.Input
                    {...form.input(`trainings[]`, 'checkbox', { value: training.id })}
                  />
                </div>
                <div className="flex-grow">
                  <p className="font-medium text-gray-800">{training.start?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'full', timeStyle: 'short' })}</p>
                  <div className="text-sm text-gray-500 flex flex-col md:flex-row gap-x-3 gap-y-2 items-center flex-wrap">
                    <div>{t('stable.instructors.item')}: {instructorTranslation.getCurrentTranslation(training.instructor)?.name}</div>
                    <div>{t('stable.trainings.groupTrainings.availableSlots')}: {freeSlots}/{slots}</div>
                    {training.participants !== undefined && training.participants.length > 0 && (
                      <>
                        {form.data.participants.map((p, index) => {
                          const participant = groupTraining.participants?.find((pp) => pp.riderId === p);
                          if (participant === undefined) return '';
                          const isParticipant = training.participants!.filter(pp => pp.riderId === participant.riderId).length > 0;
                          if (!isParticipant) return '';
                          return (
                            <div key={index} className="flex flex-row gap-x-2 items-center">
                              <FontAwesomeIcon icon={isParticipant ? faCheckCircle : faTimesCircle} className={isParticipant ? 'text-emerald-600' : 'text-red-700'} />
                              {participant.rider?.user?.fullName}
                            </div>
                          )
                        })}
                      </>
                    )}
                    {!haveEnoughSlots &&
                      (<div className="flex flex-row gap-x-2 items-center text-red-700">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <span>{t('stable.trainings.groupTrainings.moveParticipants.notEnoughSlots')}</span>
                      </div>)}
                  </div>
                </div>
              </label>
            );
          })}
        </div>
      ) : (
        <Alert.Information title={t('booking.noAvailableTerms')} noClose />
      )}
    </>
  )
}

const MoveParticipantsThirdStep = ({ form, participants, trainings, groupTraining }: MoveParticipantsSecondStepProps) => {
  const { t, i18n } = useTranslation();
  const instructorTranslation = useEntityTranslation<Instructor, InstructorTranslation>();
  return (
    <>
      <Alert.Information title={t('stable.trainings.groupTrainings.moveParticipants.confirm')} noClose />
      <div className="flex flex-col md:flex-row gap-5 overflow-y-auto max-h-96">
        <div className="md:flex-1">
          <b>{t('stable.trainings.groupTrainings.moveParticipants.selectedParticipants')}:</b>
          <ul className="flex flex-col gap-y-3">
            {form.data.participants?.map((p, index) => {
              const participant = participants?.find((pp) => pp.riderId === p);
              if (participant === undefined) return '';
              return (
                <>
                  <li key={index} className="text-gray-700 border-l-2 border-gray-300 pl-5 py-3">
                    <p className="font-medium">{participant?.rider?.user?.fullName}</p>
                    <div className="flex flex-row gap-x-3">
                      {!participant?.rider?.user?.email?.endsWith('@localhost') && <p className="text-white bg-gray-600 px-2 py-1 rounded text-sm">{participant?.rider?.user?.email}</p>}
                      {participant?.rider?.user?.phoneNumber && <p className="text-white bg-gray-600 px-2 py-1 rounded text-sm">{participant?.rider?.user?.phoneNumber}</p>}
                    </div>
                  </li>
                </>
              )
            })}
          </ul>
        </div>
        <div className="md:flex-1">
          <b>{t('stable.trainings.groupTrainings.moveParticipants.selectedTrainings')}:</b>
          <ul className="flex flex-col gap-y-3">
            {form.data.trainings?.map((tr, index) => {
              const training = trainings.find((tt) => tt.id === tr);
              if (training === undefined) return '';
              return (
                <>
                  <li key={index} className="text-gray-700 border-l-2 border-gray-300 pl-5 py-3">
                    <p className="font-medium">{training?.start?.toLocaleString()}</p>
                    <div className="flex flex-row gap-x-3">
                      <p className="text-white bg-gray-600 px-2 py-1 rounded text-sm">{t('stable.instructors.item')}: {instructorTranslation.getCurrentTranslation(training.instructor)?.name}</p>
                      <p className="text-white bg-gray-600 px-2 py-1 rounded text-sm">{t('stable.trainings.groupTrainings.availableSlots')}: {(training.type?.maximalRiders ?? 1) - (training.participants?.length ?? 0)}/{training.type?.maximalRiders}</p>
                    </div>
                  </li>
                </>
              )
            })}
          </ul>
        </div>
      </div>
      {form.data.deleteFromCurrent && (
        <Alert.Warning title={t('stable.trainings.groupTrainings.moveParticipants.participantsWillBeDeleted.title')} noClose>
          <p>
            {t('stable.trainings.groupTrainings.moveParticipants.participantsWillBeDeleted.description', {
              date: groupTraining.start?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'full', timeStyle: 'short' })
            })}
          </p>
        </Alert.Warning>
      )}
      {!form.data.deleteFromCurrent && (
        <Alert.Information title={t('stable.trainings.groupTrainings.moveParticipants.participantsWillRemain.title')} noClose>
          <p>
            {t('stable.trainings.groupTrainings.moveParticipants.participantsWillRemain.description', {
              date: groupTraining.start?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'full', timeStyle: 'short' })
            })}
          </p>
        </Alert.Information>
      )}
    </>
  )
}
