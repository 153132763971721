import { Training } from "src/api/stable/Booking";
import { useTranslation } from "react-i18next";
import { PaymentMethod, PaymentMethodTranslation, PaymentMethodsClient, PaymentSubMethod, PaymentsClient } from "src/api/financial/Payments";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import Button from "src/components/Actions/Button";
import { FormEvent, useEffect, useState } from "react";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import GridSelect, { GridSelectOption } from "src/components/Form/GridSelect";
import Spinner from "src/components/Feedback/Spinner";
import { HttpQueryFilter, PaymentRequestsClient } from "src/api/financial/Accountancy";
import ContentParse from "src/components/Cms/ContentParse";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js";
import Alert from "src/components/Feedback/Alert";
import useCurrency from "src/hooks/useCurrency";

export interface PaymentComponentProps {
  paymentMethod: PaymentMethod;
  training: Training;
}

interface StripeEndpointResponse {
  paymentIntentSecret: string;
  customerId: string;
  ephemeralKey: string;
}

const Payment = (props: PaymentComponentProps) => {
  const { paymentMethod } = props;
  if (paymentMethod?.provider === 'Stripe') {
    return <PaymentStripe {...props} />
  } else {
    return <PaymentCommon {...props} />
  }
}

function PaymentStripe(props: PaymentComponentProps) {
  const { training, paymentMethod } = props;
  const { t, i18n } = useTranslation();
  const [secret, setSecret] = useState<string | undefined>(undefined);

  const apiConfiguration = useApiConfiguration();
  const paymentsClient = new PaymentsClient(apiConfiguration);
  const paymentRequestsClient = new PaymentRequestsClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();

  useEffect(() => {
    getPaymentClientSecret();
  }, [paymentMethod]);

  const getPaymentClientSecret = async () => {
    const requests = await paymentRequestsClient
      .get([
        { property: "ExternalId", value: `Training-${training.id!}`, type: '=' } as HttpQueryFilter
      ], undefined, undefined, undefined, undefined, undefined);
    if (requests.items?.length === 0)
      throw new Error("Request not found");
    const request = requests.items![0]!;
    const response = await paymentsClient.requestPayment(request.id!, paymentMethod.id!, undefined);
    const responseObj = JSON.parse(response) as StripeEndpointResponse;
    setSecret(responseObj.paymentIntentSecret);
    return responseObj;
  }

  const stripePromise = loadStripe(
    'pk_test_51QytqVIjP5GBz8fxUJncSU0UI1jL7wIYVsMm5U3twDELbD5tnts3LlVUUFO50wzFLxFH8SW1ELGBIigFDQCWNiMx00RU07SlDY',
    {
      locale: i18n.resolvedLanguage as StripeElementLocale ?? "en"
    }
  );

  if (!secret) return <Spinner className="mx-auto h-8" />;

  return (
    <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
      <div className="lg:flex lg:justify-between mb-8 lg:mb-0">
        <div>
          <h2 className="text-3xl text-gray-300 mb-5">{t('stable.trainings.booking.header')}</h2>
        </div>
      </div>
      <div className="lg:flex lg:justify-between">
        <div className="lg:w-5/12 lg:pr-3">
          <h2 className="text-4xl text-[--color-primary-700] mb-4">{t('accountancy.payments.item')}</h2>
          <h2 className="text-2xl text-gray-500 mb-4">{entityTranslation.getCurrentTranslation(paymentMethod)?.title}</h2>
          <h2 className="text-xl text-gray-500 mb-4">{training.number || training.id}</h2>
        </div>
        <div className="flex-grow">
          <Elements stripe={stripePromise} options={{ clientSecret: secret }}>
            <StripeCheckoutForm {...props} />
          </Elements>
        </div>
      </div>
    </div>
  )
}

function StripeCheckoutForm(props: PaymentComponentProps) {
  const { training } = props;
  const { i18n, t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setMessage(undefined);
    setLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${i18n.resolvedLanguage}/payment/return`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    setLoading(false);
  }

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      {message && <Alert.Error title={message} />}
      <PaymentElement options={
        {
          layout: 'accordion',
          defaultValues: {
            billingDetails: {
              name: training?.rider?.user?.fullName,
              email: training?.rider?.user?.email,
              phone: training?.rider?.user?.phoneNumber,

            }
          }
        }
      } />
      <Button colorName="primary" className="px-5 py-3 mt-8">
        {t('accountancy.payments.do')}
        {loading && <Spinner className="ml-3 h-4" />}
      </Button>
    </form>
  );
}

function PaymentCommon(props: PaymentComponentProps) {
  const { training, paymentMethod } = props;
  const { t } = useTranslation();
  const [subMethods, setSubMethods] = useState<PaymentSubMethod[]>([]);
  const [subMethod, setSubMethod] = useState<PaymentSubMethod | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const apiConfiguration = useApiConfiguration();
  const paymentMethodsClient = new PaymentMethodsClient(apiConfiguration);
  const paymentsClient = new PaymentsClient(apiConfiguration);
  const paymentRequestsClient = new PaymentRequestsClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();
  const currency = useCurrency();

  useEffect(() => {
    if(!currency) return;
    if (paymentMethod.haveSubMethods) {
      setLoading(true);
      paymentMethodsClient
        .listSubMethods(paymentMethod.id!, 100, currency)
        .then(response => setSubMethods(response ?? []))
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [paymentMethod]);

  const onClickPayment = () => {
    setPaymentLoading(true);
    paymentRequestsClient
      .get([{ property: "ExternalId", value: `Training-${training.id!}`, type: '=' } as HttpQueryFilter], undefined, undefined, undefined, undefined, undefined)
      .then(requestResponse => {
        const request = requestResponse.items?.[0];
        if (!request) {
          console.error("Request not found");
          setPaymentLoading(false);
          return;
        }
        paymentsClient
          .requestPayment(request.id!, paymentMethod.id!, subMethod?.id)
          .then(response => {
            window.location.href = response;
            return;
          })
          .catch(error => console.error(error))
          .finally(() => setPaymentLoading(false));
      })
      .catch(error => console.error(error))
  }

  const options = subMethods.map(m => ({
    id: m.id,
    value: m.id,
    label: m.name,
    description: m.description,
    image: m.imageUrl
  }) as GridSelectOption);

  return (
    <>
      <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
        <div className="lg:flex lg:justify-between mb-8 lg:mb-0">
          <div>
            <h2 className="text-3xl text-gray-300 mb-5">{t('stable.trainings.booking.header')}</h2>
          </div>
        </div>
        <div className="lg:flex lg:justify-between">
          <div className="lg:w-5/12 lg:pr-3">
            <h2 className="text-4xl text-[--color-primary-700] mb-4">{t('accountancy.payments.item')}</h2>
            <h2 className="text-2xl text-gray-500 mb-4">{entityTranslation.getCurrentTranslation(paymentMethod)?.title}</h2>
            <h2 className="text-xl text-gray-500 mb-4">{training.number || training.id}</h2>
          </div>
          <div className="lg:w-7/12">
            {loading && <Spinner className="mx-auto h-8" />}
            {paymentMethod.haveSubMethods &&
              <div className="h-96 overflow-y-scroll">
                <GridSelect value={paymentMethod?.id} options={options} onChange={(e) => setSubMethod(subMethods.find(i => i.id == e.target.value))} small />
              </div>
            }
            <div className="text-end">
              <Button colorName="primary" className="px-5 py-3 mt-8" onClick={onClickPayment} disabled={loading || (paymentMethod.haveSubMethods && subMethod === undefined)}>
                {t('accountancy.payments.do')}
                {paymentLoading && <Spinner className="ml-3 h-4" />}
              </Button>
              {entityTranslation.getCurrentTranslation(paymentMethod)?.agreements && <div className="text-sm text-gray-400 mt-8"><ContentParse>{entityTranslation.getCurrentTranslation(paymentMethod)?.agreements}</ContentParse></div>}
              <div className="text-sm text-gray-400 mt-8">{t('accountancy.payments.externalRedirect')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payment;