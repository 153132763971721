import useForm from "src/hooks/useForm";
import FormInput from "../../components/Form/FormInput";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { PasswordClient, PasswordResetConfirmRequest, PasswordResetRequest } from "src/api/access/Auth";
import FormAlert from "src/components/Form/FormAlert";
import Alert from "src/components/Feedback/Alert";
import { useParams } from "react-router-dom";
import LocalizedLink from "src/components/Router/LocalizedLink";
import Button from "src/components/Actions/Button";
import Spinner from "src/components/Feedback/Spinner";
import { MetaHead } from "src/components/Meta/MetaHead";



enum ResetPasswordStep {
  Prompt,
  Confirm,
  Done
}

export interface ResetPasswordPromptFormComponentProps {
  onPrompt: (e: string) => void;
}
export interface ResetPasswordConfirmFormComponentProps {
  onConfirm: () => void;
  email: string;
  token?: string;
}

const ResetPasswordPromptForm = (props: ResetPasswordPromptFormComponentProps) => {
  const { onPrompt } = props;

  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const authClient = new PasswordClient(apiConfiguration);

  const form = useForm<PasswordResetRequest>();
  const onSubmit = () => {
    authClient
      .passwordReset(form.data)
      .then(_ => onPrompt(form.data.email))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }
  return (
    <>
      <div>
        <h2 className="mt-8 text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('auth.resetPassword.header')}
        </h2>
      </div >
      <div className="mt-10 text-gray-600 text-sm">{t('auth.resetPassword.description')}</div>
      <div className="mt-10">
        <div>
          <FormAlert
            code={form.error}
            errorMessages={{
              ...t("common.errors", { returnObjects: true }),
              ...t("auth.errors", { returnObjects: true })
            }}
          />

          <form onSubmit={e => form.onSubmit(e, onSubmit)} className="space-y-6">
            <FormInput.Overlapping {...form.input('email', 'email', { placeholder: t('auth.fields.email'), autoComplete: 'email', required: true })} />
            <div>
              <Button colorName="primary" className="w-full justify-center" disabled={form.pending}>
                {t('auth.resetPassword.sendToken')}
              </Button>
              {form.pending && <Spinner />}
            </div>
          </form>
        </div >
      </div >
    </>
  );
}

const ResetPasswordConfirmForm = (props: ResetPasswordConfirmFormComponentProps) => {
  const { email, token, onConfirm } = props;

  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const authClient = new PasswordClient(apiConfiguration);

  const form = useForm<PasswordResetConfirmRequest>({ email: email, token: token } as PasswordResetConfirmRequest);

  useEffect(() => {
    form.setData({ ...form.data, token: form.data?.token?.replace(' ', '') } as PasswordResetConfirmRequest)
  }, [form.data?.token]);

  const onSubmit = () => {
    authClient
      .passwordResetConfirm(form.data)
      .then(_ => onConfirm())
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <MetaHead title={t('auth.resetPassword.header')} />
      <div>
        <h2 className="mt-8 text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('auth.resetPassword.header')}
        </h2>
      </div >
      <div className="mt-10 text-gray-600 text-sm">{t('auth.resetPassword.sentToken')}</div>
      <div className="mt-10">
        <div>
          <FormAlert
            code={form.error}
            errorMessages={{
              ...t("common.errors", { returnObjects: true }),
              ...t("auth.errors", { returnObjects: true })
            }}
          />

          <form onSubmit={e => form.onSubmit(e, onSubmit)} className="space-y-6">
            <FormInput.Overlapping {...form.input('email', 'email', { placeholder: t('auth.fields.email'), autoComplete: 'email', required: true })} />
            <FormInput.Overlapping {...form.input('newPassword', 'password', { placeholder: t('auth.fields.newPassword'), required: true })} />
            <FormInput.Overlapping {...form.input('token', 'text', { placeholder: t('auth.token'), required: true })} />
            <div>
              <Button colorName="primary" className="w-full justify-center" disabled={form.pending}>
                {t('auth.resetPassword.action')}
              </Button>
              {form.pending && <Spinner />}
            </div>
          </form>
        </div >
      </div >
    </>
  );
}

const ResetPasswordDoneForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h2 className="mt-8 text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('auth.resetPassword.header')}
        </h2>
      </div >
      <div className="mt-10">
        <div>
          <Alert.Success title={t('auth.resetPassword.passwordChanged')} />
          <div>
            <LocalizedLink to="/auth/sign-in">
              <Button colorName="primary" className="w-full justify-center">
                {t('auth.signIn.action')}
              </Button>
            </LocalizedLink>
          </div>
        </div >
      </div >
    </>
  );
}

export default () => {
  const { token, email } = useParams<string>();
  const [step, setStep] = useState(ResetPasswordStep.Prompt);
  const [formEmail, setFormEmail] = useState('');

  useEffect(() => {
    if (token) {
      setStep(ResetPasswordStep.Confirm);
    }
  }, [token]);

  useEffect(() => {
    if (email) {
      setStep(ResetPasswordStep.Confirm);
    }
  }, [email]);

  const onPrompt = (e: string) => {
    setStep(ResetPasswordStep.Confirm);
    setFormEmail(e);
  }

  const onConfirm = () => {
    setStep(ResetPasswordStep.Done);
  }

  switch (step) {
    case ResetPasswordStep.Prompt:
      return <ResetPasswordPromptForm onPrompt={onPrompt} />;
    case ResetPasswordStep.Confirm:
      return <ResetPasswordConfirmForm email={email ?? formEmail} token={token} onConfirm={onConfirm} />;
    case ResetPasswordStep.Done:
      return <ResetPasswordDoneForm />;
  }

}