import { faFileText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralTask } from 'src/api/stable/Booking';
import PeriodicBookingForm from 'src/components/Booking/PeriodicBookingForm';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import Editor from 'src/components/Form/FormEditor';
import FormInput from 'src/components/Form/FormInput'
import FormSection from 'src/components/Layout/Panel/FormSection';
import StaffSelect from 'src/components/Stable/StaffSelect';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<GeneralTask>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (!form.data.start) return;
    const endDate = new Date(form.data.start.getTime());
    if (form.data.end) {
      endDate.setHours(form.data.end.getHours());
      endDate.setMinutes(form.data.end.getMinutes());
      endDate.setSeconds(form.data.end.getSeconds());
    }
    form.setData({ ...form.data, end: endDate } as GeneralTask)
  }, [form.data.start])

  useEffect(() => {
    if (!form.data.periodic) return;
    if (!form.data.start) return;
    if (form.data.periodic.period?.start) return;
    form.set('periodic.period.start', moment(form.data.start).add(1, 'day').startOf('day').toDate());
  }, [form.data.periodic])

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
      full
    >
      <div className="lg:w-1/2">
        <div className="mb-10">
          <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
        </div>
        <div className="mb-10">
          <div className="flex justify-between">
            <div>
              <FormDatePicker
                {...form.input('start', 'date', { placeholder: t('stable.trainings.fields.start') })}
                value={form.data.start}
                onChange={(date) => form.setData({ ...form.data, start: date } as GeneralTask)}
                time={15}
              />
            </div>
            <div>
              <FormDatePicker
                {...form.input('end', 'date', { placeholder: t('stable.trainings.fields.end') })}
                value={form.data.end}
                onChange={(date) => form.setData({ ...form.data, end: date } as GeneralTask)}
                time={15}
                timeOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="mb-10">
          {!form.pending && <StaffSelect
            {...form.input('userId', 'select', {})}
            onChange={(staffId, staff) => form.setData({ ...form.data, userId: staffId as string, user: staff } as GeneralTask)}
            value={form.data.userId}
            placeholder={t('stable.workers.item')} />}
        </div>
      </div>
      <div className="mb-10">
        <FormCheckbox.Input {...form.input("isDone", "checkbox", { value: "true", placeholder: t('common.fields.isDone') })} />
      </div>
      <div className="mb-10">
        <FormInput.Default {...form.input("title", "text", { placeholder: t('common.fields.title') })} icon={<FontAwesomeIcon icon={faFileText} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
      </div>
      <div className="mb-10">
        <Editor {...form.input("description", "text", { placeholder: t('common.fields.description') })} key={`${form.data.id}_content`} style={{ height: '50vh' }} />
      </div>
      <PeriodicBookingForm value={form.data.periodic} onChange={(periodic) => form.set('periodic', periodic)} />
    </FormSection>
  )
}