import useForm, { IForm } from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import { Dictionary } from "lodash";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight, faCircleExclamation, faCirclePlus, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import FormInput from "src/components/Form/FormInput";
import FormCheckbox from "src/components/Form/FormCheckbox";
import { Horse, HorseTrainingType, Instructor, InstructorTrainingType, Place, TrainingType, TrainingTypePlace, TrainingTypesClient, TrainingTypeTranslation } from "src/api/stable/Stable";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import Alert from "src/components/Feedback/Alert";
import Editor from "src/components/Form/FormEditor";
import useCurrency from "src/hooks/useCurrency";

export interface WizardStepTrainingTypesForm {
  rows: TrainingType[];
}

export interface WizardStepTrainingTypesProps extends WizardStepProps {
  data: TrainingType[];
  setData: (data: TrainingType[]) => void;
  horses: Horse[];
  instructors: Instructor[];
  places: Place[];
}

interface TrainingTypeFormProps {
  form: IForm<WizardStepTrainingTypesForm>;
  row: TrainingType;
  index: number;
  onClickDelete: () => void;
  horses?: Horse[];
  instructors?: Instructor[];
  places?: Place[];
}

interface TrainingTypePillProps {
  row: TrainingType;
  onClick: () => void;
  active?: boolean;
}

enum TrainingTypeValidation {
  Name,
  Price,
  Duration,
  Horses,
  Instructors,
  Places,
  Access
}

const validateEntity = (row: TrainingType): TrainingTypeValidation[] => {
  const result = [] as TrainingTypeValidation[];
  if (!row.translations?.[0]?.name) result.push(TrainingTypeValidation.Name);
  if (!row.price) result.push(TrainingTypeValidation.Price);
  if (!row.duration) result.push(TrainingTypeValidation.Duration);
  if (!row.isAvailableForAnyHorse && (row.horseTrainingTypes ?? []).length === 0) result.push(TrainingTypeValidation.Horses);
  if (!row.isAvailableForAnyInstructor && (row.instructorTrainingTypes ?? []).length === 0) result.push(TrainingTypeValidation.Instructors);
  if ((row.trainingTypePlaces ?? []).length === 0) result.push(TrainingTypeValidation.Places);
  if (!row.isAvailableForAnyRider && !row.isAvailableForGuest) result.push(TrainingTypeValidation.Access);
  return result;
}


const useValidation = (row: TrainingType) => {
  return useMemo(() => validateEntity(row), [
    row,
    row?.translations?.[0]?.name,
    row?.price, row?.duration,
    row?.isAvailableForAnyHorse, row?.isAvailableForAnyInstructor,
    row?.horseTrainingTypes?.length, row?.instructorTrainingTypes?.length,
    row?.trainingTypePlaces?.length,
    row?.isAvailableForAnyRider, row?.isAvailableForGuest
  ]);
}

const TrainingTypeForm = (props: TrainingTypeFormProps) => {
  const { form, row, index, horses, instructors, places, onClickDelete } = props;
  const { t } = useTranslation();
  const currency = useCurrency();

  const validation = useValidation(row);

  const onChangeHorse: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, checked } = e.target;
    const stringValue = String(value);
    let relations = [...row.horseTrainingTypes || []] as HorseTrainingType[];
    if (checked && !relations.some(hi => hi.horseId === value)) {
      relations = [...relations, { horseId: stringValue }] as HorseTrainingType[];
    }
    else if (!checked && relations.some(hi => hi.horseId === stringValue)) {
      relations = [...relations.filter(hi => hi.horseId !== stringValue)] as HorseTrainingType[];
    }
    form.set(`rows.${index}.horseTrainingTypes`, relations);
  }

  const onChangeInstructor: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, checked } = e.target;
    const stringValue = String(value);
    let relations = [...row.instructorTrainingTypes || []] as InstructorTrainingType[];
    if (checked && !relations.some(hi => hi.instructorId === value)) {
      relations = [...relations, { instructorId: stringValue }] as InstructorTrainingType[];
    }
    else if (!checked && relations.some(hi => hi.instructorId === stringValue)) {
      relations = [...relations.filter(hi => hi.instructorId !== stringValue)] as InstructorTrainingType[];
    }
    form.set(`rows.${index}.instructorTrainingTypes`, relations);
  }

  const onChangePlace: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, checked } = e.target;
    const stringValue = String(value);
    let relations = [...row.trainingTypePlaces || []] as TrainingTypePlace[];
    if (checked && !relations.some(hi => hi.placeId === value)) {
      relations = [...relations, { placeId: stringValue }] as TrainingTypePlace[];
    }
    else if (!checked && relations.some(hi => hi.placeId === stringValue)) {
      relations = [...relations.filter(hi => hi.placeId !== stringValue)] as TrainingTypePlace[];
    }
    form.set(`rows.${index}.trainingTypePlaces`, relations);
  }


  return (
    <div className="border-t border-gray-100 pt-4 mt-4">
      {row.id && (
        <Alert.Warning title={t('wizard.horses.delete.title')} noClose>
          {t('wizard.trainingTypes.delete.description')}
        </Alert.Warning>
      )}
      <div className="flex gap-x-5">
        <div className="flex-1 flex flex-col gap-y-5">
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.translations.0.name`,
              'text',
              {
                placeholder: t('common.fields.name'),
                required: true
              })
            }
          />
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.price`,
              'number',
              {
                min: 0,
                step: 0.01,
                placeholder: t('stable.trainingTypes.fields.price'),
                required: true
              })
            }
            suffix={currency}
          />
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.duration`,
              'number',
              {
                min: 5,
                step: 1,
                placeholder: t('stable.trainingTypes.fields.duration'),
                required: true
              })
            }
            suffix="min"
          />

          <Editor
            {...form.input(`rows.${index}.translations.0.description`, "text", { placeholder: t('common.fields.description') })}
            key={`rows.${index}.translations.0.description`}
            style={{ height: '50vh' }}
            minimal
          />
          <div>
            <span className="font-medium">{t('stable.trainings.fields.groupTraining')}</span>
          </div>
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.maximalRiders`,
              'number',
              {
                min: 1,
                step: 1,
                placeholder: t('stable.trainingTypes.fields.maximalRiders')
              })
            }
            aria-description={t('stable.trainingTypes.descriptions.maximalRiders')}
          />
          <FormCheckbox.Input
            {...form.input(`rows.${index}.isLimitedToDefinedTerms`, 'checkbox', { value: 'true' })}
            disabled={row.maximalRiders === undefined || row.maximalRiders <= 1}
            placeholder={t('stable.trainings.fields.isLimitedToDefinedTerms')}
            aria-description={t('stable.trainings.descriptions.isLimitedToDefinedTerms')}
          />
        </div>
        <div className="flex-1 flex flex-col gap-y-5">
          <div>
            <p className="font-medium text-sm color-gray-600 mb-2">{t('wizard.trainingTypes.fields.riders')}</p>
            <div className="mt-2">
              <FormCheckbox.Input {...form.input(`rows.${index}.isAvailableForAnyRider`, 'checkbox', { value: 'true' })} checked={row.isAvailableForAnyRider || row.isAvailableForGuest} disabled={row.isAvailableForGuest} aria-description={t('stable.trainingTypes.fields.isAvailableForAnyRider')} />
            </div>
            <div className="mt-2">
              <FormCheckbox.Input {...form.input(`rows.${index}.isAvailableForGuest`, 'checkbox', { value: 'true' })} aria-description={t('stable.trainingTypes.fields.isAvailableForGuest')} />
            </div>
            {validation.includes(TrainingTypeValidation.Access) && <span className="text-xs text-rose-700">{t('validation.AtLeastOneItem')}</span>}
          </div>
          <hr />
          <div>
            <p className="font-medium text-sm color-gray-600 mb-2">{t('wizard.trainingTypes.fields.instructors')}</p>
            <div className="text-center mb-4">
              <FormCheckbox.Input {...form.input(`rows.${index}.isAvailableForAnyInstructor`, 'checkbox', { value: 'true' })} aria-description={t('stable.trainingTypes.fields.isAvailableForAnyInstructor')} />
            </div>
            {!row.isAvailableForAnyInstructor &&
              <FormCheckbox.Set title={t('stable.instructors.group')}>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                  {instructors?.map((instructor, instructorId) => <FormCheckbox.Input
                    value={instructorId}
                    checked={row.instructorTrainingTypes?.some(itt => itt.instructorId === String(instructorId))}
                    aria-description={instructor.user?.givenName}
                    onChange={onChangeInstructor}
                  />)}
                </div>
              </FormCheckbox.Set>
            }
            {validation.includes(TrainingTypeValidation.Instructors) && <span className="text-xs text-rose-700">{t('validation.AtLeastOneItem')}</span>}
          </div>
          <hr />
          <div>
            <p className="font-medium text-sm color-gray-600 mb-2">{t('wizard.trainingTypes.fields.horses')}</p>
            <div className="text-center mb-4">
              <FormCheckbox.Input {...form.input(`rows.${index}.isAvailableForAnyHorse`, 'checkbox', { value: 'true' })} aria-description={t('stable.trainingTypes.fields.isAvailableForAnyHorse')} />
            </div>
            {!row.isAvailableForAnyHorse &&
              <FormCheckbox.Set title={t('stable.horses.group')}>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                  {horses?.map((horse, horseId) => <FormCheckbox.Input
                    value={horseId}
                    checked={row.horseTrainingTypes?.some(htt => htt.horseId === String(horseId))}
                    aria-description={horse.translations?.find(_t => true)?.name} onChange={onChangeHorse}
                  />)}
                </div>
              </FormCheckbox.Set>
            }
            {validation.includes(TrainingTypeValidation.Horses) && <span className="text-xs text-rose-700">{t('validation.AtLeastOneItem')}</span>}
          </div>
          <hr className="my-4" />
          <div>
            <p className="font-medium text-sm color-gray-600 mb-2">{t('wizard.trainingTypes.fields.places')}</p>
            <FormCheckbox.Set title={t('stable.places.group')}>
              <div className="grid grid-cols-1 lg:grid-cols-2">
                {places?.map((place, placeId) => <FormCheckbox.Input
                  value={placeId}
                  checked={row.trainingTypePlaces?.some(ttp => ttp.placeId === String(placeId))}
                  aria-description={place.translations?.find(_t => true)?.name}
                  onChange={onChangePlace}
                />)}
              </div>
            </FormCheckbox.Set>
            {validation.includes(TrainingTypeValidation.Places) && <span className="text-xs text-rose-700">{t('validation.AtLeastOneItem')}</span>}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <Button type="button" colorName="rose" className="text-sm px-5 font-medium" onClick={onClickDelete}>
          <FontAwesomeIcon icon={faTimesCircle} className="mr-3" />
          {t('common.actions.delete')}
        </Button>
      </div>
    </div>
  )
}

const TrainingTypePill = (props: TrainingTypePillProps) => {
  const { row, onClick, active } = props;
  const validation = useValidation(row);

  if (active) {
    return (
      <div className="bg-[--color-primary-100] rounded-md text-xs px-5 py-3 text-[--color-primary-700] cursor-pointer" onClick={onClick}>
        {validation.length > 0 && <FontAwesomeIcon icon={faCircleExclamation} className="mr-1 text-red-700" />}
        {row?.translations?.find(_t => true)?.name}
      </div>
    );
  }

  return (
    <div className="bg-gray-100 rounded-md text-xs px-5 py-3 text-gray-700 cursor-pointer" onClick={onClick}>
      {validation.length > 0 && <FontAwesomeIcon icon={faCircleExclamation} className="mr-1 text-red-700" />}
      {row?.translations?.find(_t => true)?.name}
    </div>
  );
}

export default (props: WizardStepTrainingTypesProps) => {
  const { nextStep, prevStep, data, setData, horses, places, instructors } = props;
  const { t } = useTranslation();
  const form = useForm({ rows: [] } as WizardStepTrainingTypesForm);
  const [currentTrainingType, setCurrentTrainingType] = useState<number | undefined>(undefined);

  const apiConfiguration = useApiConfiguration();
  const apiClient = new TrainingTypesClient(apiConfiguration);

  useEffect(() => {
    if (data.length > 0) {
      form.setData({ rows: data.map(normalizeEntity) });
      return;
    }
    fetch();
  }, []);

  const normalizeEntity = (entity: TrainingType) => {
    entity.trainingTypePlaces = entity?.trainingTypePlaces?.filter(ttp => ttp.placeId !== undefined && places[Number(ttp.placeId)] !== undefined);
    entity.horseTrainingTypes = entity?.horseTrainingTypes?.filter(htt => htt.horseId !== undefined && horses[Number(htt.horseId)] !== undefined);
    entity.instructorTrainingTypes = entity?.instructorTrainingTypes?.filter(itt => itt.instructorId !== undefined && instructors[Number(itt.instructorId)] !== undefined);
    return entity;
  }

  const mapEntity = (entity: TrainingType) => {
    entity.trainingTypePlaces = entity?.trainingTypePlaces?.map(ttp => {
      const index = places.findIndex(p => p.id === ttp.placeId);
      ttp.placeId = index !== -1 ? String(index) : undefined;
      return ttp;
    });
    entity.horseTrainingTypes = entity?.horseTrainingTypes?.map(htt => {
      const index = horses.findIndex(h => h.id === htt.horseId);
      htt.horseId = index !== -1 ? String(index) : undefined;
      return htt;
    });
    entity.instructorTrainingTypes = entity?.instructorTrainingTypes?.map(itt => {
      const index = instructors.findIndex(i => i.id === itt.instructorId);
      itt.instructorId = index !== -1 ? String(index) : undefined;
      return itt;
    });
    return entity;
  }

  const fetch = () => {
    form.setPending(true);
    apiClient
      .get(undefined, undefined, 1000, 0, undefined, undefined)
      .then(response => {
        form.setData({ rows: response.items?.map(mapEntity).map(normalizeEntity) ?? [] });
        return response;
      })
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const onSubmit = () => {
    setData([...form.data.rows]);
    nextStep();
  }

  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  const onClickDelete = (index: number) => {
    if (form.data.rows[index].id) {
      if (confirm(t('wizard.trainingTypes.delete.areYouSure'))) {
        deleteRow(index);
      }
    }
    else {
      deleteRow(index);
    }
  }

  const deleteRow = (index: number) => {
    form.setData({
      ...form.data,
      rows: form.data.rows.filter((_, i) => i !== index)
    } as WizardStepTrainingTypesForm);
  }

  const addRow = () => {
    form.setData({
      ...form.data,
      rows:
        [
          ...form.data.rows,
          {
            duration: 0,
            price: 0,
            translations: [{
              culture: 'pl',
              name: '',
              description: ''
            } as TrainingTypeTranslation],
            isAvailableForAnyRider: true,
            isAvailableForGuest: true,
            isAvailableForAnyHorse: false,
            isAvailableForAnyInstructor: false,
            horseTrainingTypes: [] as HorseTrainingType[],
            instructorTrainingTypes: [] as InstructorTrainingType[],
            trainingTypePlaces: [] as TrainingTypePlace[],
            maximalRiders: 1,
            isLimitedToDefinedTerms: false,
          } as TrainingType
        ]
    } as WizardStepTrainingTypesForm);
    setCurrentTrainingType(form.data.rows.length);
  }

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
      <p className="text-sm text-gray-600">
        {t('wizard.trainingTypes.slogan')}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
        {form.data.rows?.map((row, index) => <TrainingTypePill key={index} row={row} onClick={() => setCurrentTrainingType(index)} active={index === currentTrainingType} />)}
        <div className="bg-emerald-100 rounded-md text-xs px-5 py-3 text-emerald-700 hover:bg-emerald-200 active:bg-emerald-400 cursor-pointer" onClick={addRow}>
          <FontAwesomeIcon icon={faCirclePlus} className="mr-1" /> {t('wizard.trainingTypes.add')}
        </div>
      </div>
      {currentTrainingType !== undefined && form.data.rows.some((_row, index) => index === currentTrainingType) !== false && <TrainingTypeForm row={form.data.rows[currentTrainingType]} index={currentTrainingType} onClickDelete={() => onClickDelete(currentTrainingType!)} horses={horses} instructors={instructors} places={places} form={form} />}
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          {t('ui.goBack')}
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3" disabled={form.data.rows.length === 0 || form.data.rows.some(t => t.translations?.some(trans => !trans.name) || !t.duration || !t.price || (!t.isAvailableForAnyInstructor && (t.instructorTrainingTypes ?? []).length === 0) || (!t.isAvailableForAnyHorse && (t.horseTrainingTypes ?? []).length === 0) || (t.trainingTypePlaces ?? []).length === 0)}>
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          {t('ui.goNext')}
        </Button>
      </div>
    </form>
  );
};
