import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import FormInput from 'src/components/Form/FormInput';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { PaymentMethod, PaymentMethodTranslation, PaymentMethodsClient } from 'src/api/financial/Accountancy';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { QueryOrderDirection } from 'src/api/Base';
import { BoolIcon } from '../../Booking/Trainings/Table';
import { faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { SettingsClient } from 'src/api/core/Core';
import Spinner from 'src/components/Feedback/Spinner';

interface PaymentMethodFilters {
  url?: string;
  title?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<PaymentMethodFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.url) f.push({ property: 'translations.url', value: form.data.url, type: '%' });
  if (form.data.title) f.push({ property: 'translations.title', value: form.data.title, type: '%' });
  return f;
}

function renderFiltersForm(form: IForm<PaymentMethodFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <FormInput.Default {...form.input("url", "text", { placeholder: t('common.fields.url') })} />
        <FormInput.Default {...form.input("title", "text", { placeholder: t('common.fields.title') })} />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const PaymentMethodsTable = () => {
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new PaymentMethodsClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<PaymentMethod>>();
  const updateClaim = useClaim('PaymentMethodsUpdate');
  const deleteClaim = useClaim('PaymentMethodsDelete');
  const authGuard = useAuthGuard('/panel/', ['PaymentMethodsRead']);
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastPaymentMethod() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Accountancy)}/hubs/PaymentMethodsHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: PaymentMethod) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<PaymentMethod>[] = [
    { label: t('common.fields.title'), selector: row => entityTranslation.getCurrentTranslation(row)?.title, id: "translations.title" },
    { label: t('accountancy.paymentMethods.fields.invoiceMethod'), selector: row => t(`accountancy.enums.invoicePaymentMethod.${row.invoiceMethod}`), isOrderable: true, id: "invoiceMethod" },
    { label: t('common.fields.isEnabled'), selector: row => <BoolIcon value={row.isEnabled} className="size-4" />, isOrderable: true, id: "isEnabled" },
    { label: t('accountancy.paymentMethods.fields.isAvailableForUsers'), selector: row => <BoolIcon value={row.isAvailableForUsers} className="size-4" />, isOrderable: true, id: "isAvailableForUsers" },
    { label: t('accountancy.paymentMethods.fields.isAvailableForGuests'), selector: row => <BoolIcon value={row.isAvailableForGuests} className="size-4" />, isOrderable: true, id: "isAvailableForGuests" },
    { label: t('accountancy.currencies.group'), selector: row => row.currencies?.map(c => c.id).join(', '), isOrderable: false, id: "currencies" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    { label: '', selector: row => row.provider ? <CheckIntegrationState value={row.provider} /> : <></>, id: "provider" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="flex justify-end gap-x-3">
        {updateClaim &&
          <LocalizedLink to={`/panel/accountancy/payment-methods/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && row.id && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "accountancy.paymentMethods.group", href: "/panel/accountancy/payment-methods/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="pt-2">
          <h1 className="leading-1 text-2xl">{t('accountancy.paymentMethods.group')}</h1>
        </div>
        <div className="flex gap-x-3">
          {updateClaim &&
            <LocalizedLink to="/panel/accountancy/payment-methods/create">
              <Button colorName="emerald" className="px-5 py-3 text-md">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('accountancy.paymentMethods.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<PaymentMethod>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        defaultOrder={{ property: 'created', direction: QueryOrderDirection.DESC }}
        filters={filters}
      />
    </>
  )
}

function CheckIntegrationState({value}: {value: string}) {
  const { t } = useTranslation();
  const [configurationExists, setConfigurationExists] = useState<boolean | undefined>();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);

  useEffect(() => {
    checkIsConfigurationExists();
  }, [ ]);

  const checkIsConfigurationExists = async () => {
    try
    {
      const configuration = await apiClient.get(value.toLowerCase(), null);
      setConfigurationExists(configuration !== undefined);
    }
    catch
    {
      setConfigurationExists(false);
    }
  }

  if(configurationExists === undefined) return <Spinner className="size-4" />;

  return !configurationExists ? 
    <FontAwesomeIcon icon={faTriangleExclamation} className="text-amber-700" title={t('accountancy.paymentMethods.requireSettings') } /> :
    <></>;
}


export default PaymentMethodsTable;