import Button from "src/components/Actions/Button";
import { WizardStepProps } from "./Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/pro-duotone-svg-icons";
import LocalizedLink from "src/components/Router/LocalizedLink";
import { useTranslation } from "react-i18next";

export default (props: WizardStepProps) => {
  const { nextStep } = props;
  const { t } = useTranslation();
  return (
    <>
      <h3 className="text-sm">{(t('users.hello'))},</h3>
      <p className="text-sm text-gray-600">
        {t('wizard.welcome.slogan')}
      </p>
      <p className="text-sm text-gray-600 py-4">
        {t('wizard.welcome.description')}
      </p>
      <p className="text-sm text-gray-600 py-4 font-medium">{t('wizard.welcome.requirements.header')}</p>
      <ul className="list-disc list-inside pl-3 text-gray-600 text-sm">
        {(t('wizard.welcome.requirements.items', { returnObjects: true }) as string[] | undefined)?.map((item: string, index: number) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p className="text-sm text-gray-600 py-4">{t('wizard.welcome.areYouAbleToEditAfter')}</p>
      <p className="text-sm text-gray-600 py-4">{t('wizard.welcome.time')}</p>
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <LocalizedLink to="/panel">
          <Button type="button" colorName="gray" className="px-5 py-3">
            <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
            {t('ui.goBack')}
          </Button>
        </LocalizedLink>
        <Button colorName="primary" className="px-5 py-3" onClick={nextStep}>
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          {t('ui.goNext')}
        </Button>
      </div>
    </>
  );
};
