import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormInput from "src/components/Form/FormInput";
import useForm from "src/hooks/useForm";
import { EmailClient, EmailConfirmRequest } from "src/api/access/Auth";
import Button from "src/components/Actions/Button";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { useEffect, useState } from "react";
import FormAlert from "src/components/Form/FormAlert";
import { useTranslation } from "react-i18next";
import Alert from "src/components/Feedback/Alert";
import classNames from "src/components/Utilities/classNames";
import { useParams } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";


const Confirm = () => {
  const { token } = useParams<string>();
  const apiConfiguration = useApiConfiguration();

  const authClient = new EmailClient(apiConfiguration);
  const [tokenSent, setTokenSent] = useState(false);
  const [tokenSentTimeout, setTokenSentTimeout] = useState<NodeJS.Timeout | undefined>();
  const [confirmed, setConfirmed] = useState(false);
  const { t } = useTranslation();

  const form = useForm<EmailConfirmRequest>({ token: token || "" } as EmailConfirmRequest);

  const onRequestToken = () => {
    authClient
      .emailToken()
      .then(_ => {
        setTokenSent(true);
        setTokenSentTimeout(setTimeout(() => setTokenSentTimeout(undefined), 60000));
      })
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  useEffect(() => {
    form.setData({ ...form.data, token: form.data?.token?.replace(' ', '') } as EmailConfirmRequest)
  }, [form.data?.token]);

  const onSubmit = () => {
    authClient
      .emailConfirm(form.data)
      .then(_ => setConfirmed(true))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <div className="text-center">
        <FontAwesomeIcon icon={faEnvelope} className="h-48 text-gray-600 mx-auto" />
        <h2 className="text-2xl my-5">{t('auth.confirmEmail.header')}</h2>
      </div>
      <div className="mb-10 text-gray-700">
        {t('auth.confirmEmail.description')}
      </div>

      <FormAlert
        code={form.error}
        errorMessages={{
          ...t("common.errors", { returnObjects: true }),
          ...t("auth.errors", { returnObjects: true })
        }}
      />

      {tokenSent && <Alert.Success title={t('auth.confirmEmail.tokenSent')} noClose />}
      {confirmed && <Alert.Success title={t('auth.confirmEmail.emailConfirmed')} noClose />}

      <form onSubmit={e => form.onSubmit(e, onSubmit)} className={classNames([confirmed && "hidden"])}>
        <FormInput.Overlapping {...form.input("token", "text", { placeholder: "Token", required: true })} />
        <div className="flex justify-between my-5">
          <Button type="button" colorName="slate" className="px-5 py-3" onClick={onRequestToken} disabled={tokenSentTimeout !== undefined}>
            {t('auth.confirmEmail.requestToken')}
          </Button>
          <Button type="submit" colorName="primary" className="px-5 py-3" disabled={form.pending}>
            {t('auth.confirmEmail.action')}
          </Button>
        </div>
      </form >
    </>
  );
}

export default Confirm;