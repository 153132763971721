import { FC, useEffect, useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { HttpQueryFilter, License, LicensesClient, Subscription, TenantApplyBillingInfo, TenantApplyData, TenantNotify, TenantRequest, TenantRequestsClient, TenantRequestStatus, TenantsClient } from 'src/api/licensing/Licensing';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import ApplyAppForm from './Form/ApplyApp';
import ApplyOwnerForm from './Form/ApplyOwner';
import ApplyCenterForm from './Form/ApplyCenter';
import ApplyBusinessForm from './Form/ApplyBusiness';
import { IConfig, UsersClient } from 'src/api/access/Authority';
import { AuthClient, SignInRequest, User } from 'src/api/access/Auth';
import { Buyer, BuyersClient } from 'src/api/financial/Accountancy';
import Alert from 'src/components/Feedback/Alert';
import moment from 'moment';
import countryCodeToRegion from 'src/components/i18n/countryCodeToRegion';

export interface Passwords {
  crm?: string;
  tenant?: string;
}

const TenantRequestsForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new TenantRequestsClient(apiConfiguration);
  const licensesClient = new LicensesClient(apiConfiguration);
  const usersClient = new UsersClient(apiConfiguration);
  const buyersClient = new BuyersClient(apiConfiguration);
  const tenantsClient = new TenantsClient(apiConfiguration);
  const [passwords, setPasswords] = useState<Passwords | undefined>(undefined);
  const form = useForm({ apply: {}, status: TenantRequestStatus.Pending } as TenantRequest);
  const authGuard = useAuthGuard('/panel/', ['LicensesRead']);
  const updateClaim = useClaim('LicensesUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/licensing/tenant-requests/`)
      })
  }

  const generateRandomPassword = (length: number): string => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    const hasSpecialCharacter = /[!@#$%&*]/.test(retVal);
    const hasLowercase = /[a-z]/.test(retVal);
    const hasUppercase = /[A-Z]/.test(retVal);
    const hasNumber = /[0-9]/.test(retVal);

    if (!hasSpecialCharacter || !hasLowercase || !hasUppercase || !hasNumber) {
      return generateRandomPassword(length);
    }
    return retVal;
  }

  const onDeploy = async () => {
    let crmUser: User | undefined = undefined;
    let buyer: Buyer | undefined = undefined;
    let license: License | undefined = undefined;
    try {
      if (!form.data.id) throw new Error("No id");
      form.setPending(true);
      const data = {
        ...form.data
      } as TenantRequest;

      const crmPassword = generateRandomPassword(12);
      const tenantPassword = generateRandomPassword(12);
      //data.status = TenantRequestStatus.Deployment;
      //await apiClient.update(form.data.id, data);

      Toast.information("Wdrazanie (1/6)", "Tworzenie konta klienta w CRM");

      crmUser = await usersClient.create({
        culture: form.data.apply?.app?.locales?.[0],
        userName: form.data.apply?.owner?.email,
        email: form.data.apply?.owner?.email,
        emailConfirmed: true,
        phoneNumber: form.data.apply?.owner?.phoneNumber,
        phoneNumberConfirmed: true,
        password: crmPassword,
        givenName: form.data.apply?.owner?.givenName,
        surname: form.data.apply?.owner?.surname,
      } as User);
      Toast.information("Wdrazanie (2/6)", "Tworzenie konta rozliczeniowego");

      if (form.data.apply?.business?.name) {
        buyer = await buyersClient.create({
          userId: crmUser.id,
          name: form.data.apply?.business?.name,
          vatId: form.data.apply?.business.vatId,
          address: form.data.apply?.business.address,
          city: form.data.apply?.business.city,
          postCode: form.data.apply?.business.postCode,
          countryCode: form.data.apply?.business.countryCode,
          email: form.data.apply?.owner?.email,
        } as Buyer);
      }

      Toast.information("Wdrazanie (3/6)", "Tworzenie licencji");

      const subscriptions = [];

      const productIds = [form.data.apply?.app?.productId, ...(form.data.apply?.app?.additionalProducts ?? [])];
      for (const productId of productIds) {
        subscriptions.push({
          productId: productId,
          period: form.data.apply?.app?.period,
          isCanceled: false,
          isAutomaticRenewalEnabled: false,
          renewals: [
            {
              renewalDate: new Date(),
              expiryDate: moment().add(1, 'month').toDate(),
              price: 0,
              isPaid: true
            }
          ]
        } as Subscription)
      }

      license = await licensesClient.create({
        userId: crmUser.id,
        region: countryCodeToRegion(form.data.apply?.center?.countryCode),
        name: form.data.apply?.center?.name,
        buyerId: buyer?.id,
        subscriptions: subscriptions
      } as License);

      Toast.information("Wdrazanie (4/6)", "Zapisywanie tenanta");
      data.apply!.app!.period = Number(form.data!.apply!.app!.period);
      data.licenseId = license.id;
      await apiClient.update(form.data.id, data);

      Toast.information("Wdrazanie (4/6)", "Tworzenie konfiguracji (to moze potrwac kilka sekund)");
      const tenantKey = await apiClient.deploy(form.data.id!);
      Toast.information("Wdrazanie (4/6)", "Utworzono konfigurację");

      const tenants = await tenantsClient.get(
        [{ property: 'key', value: tenantKey }] as HttpQueryFilter[], 
        undefined, undefined, undefined, undefined, undefined
      );
      data.tenantId = tenants.items?.[0]?.id ?? undefined;
      data.status = TenantRequestStatus.Active;
      await apiClient.update(form.data.id, data);

      let token: string | undefined = undefined;
      for (let i = 1; i <= 10; i++) {
        await new Promise(r => setTimeout(r, 10000));
        Toast.information(`Wdrazanie (5/6) [${i}/10]`, "Logowanie sie na konto developera tenanta");
        try {
          const tenantAuthClient = new AuthClient({ tenant: tenantKey } as IConfig);
          token = await tenantAuthClient.signIn({
            email: "developer@localhost",
            password: `Developer123#${tenantKey}!`
          } as SignInRequest);
          break;
        }
        catch (e) {
          console.log(`failed ${i} attempt`)
          if (i == 10) {
            throw e;
          }
        }
      }

      const tenantUsersClient = new UsersClient({ tenant: tenantKey, token: token } as IConfig);
      for (let i = 1; i <= 10; i++) {
        await new Promise(r => setTimeout(r, 10000));
        Toast.information(`Wdrazanie (6/6) [${i}/10]`, "Tworzenie konta administratora tenanta");
        try {
          await tenantUsersClient.create({
            culture: form.data.apply?.app?.locales?.[0],
            userName: form.data.apply?.owner?.email,
            email: form.data.apply?.owner?.email,
            emailConfirmed: true,
            phoneNumber: form.data.apply?.owner?.phoneNumber,
            phoneNumberConfirmed: true,
            password: tenantPassword,
            givenName: form.data.apply?.owner?.givenName,
            surname: form.data.apply?.owner?.surname,
            roles: ['Administrator']
          } as User);
          break;
        }
        catch (e) {
          console.log(`failed ${i} attempt`)
          if (i == 10) {
            throw e;
          }
        }
      }

      Toast.success("Wdrazanie", "Wdrazanie zakonczone");

      setPasswords({ crm: crmPassword, tenant: tenantPassword });

      form.setPending(false);
    }
    catch (error) {
      if (error instanceof Error) {
        Toast.error(t("common.status.error"), error.message);
      } else {
        Toast.error(t("common.status.error"), "Nieznany błąd");
      }
      if (crmUser) {
        usersClient.delete(crmUser.id!);
      }
      if(buyer) {
        buyersClient.delete(buyer.id!);
      }
      if(license) {
        licensesClient.delete(license.id!);
      }

      const data = {...form.data} as TenantRequest;
      data.licenseId = undefined;
      data.tenantId = undefined;
      data.status = TenantRequestStatus.Failed;
      await apiClient.update(data.id!, data);
    }
  }

  const updateStatus = (status: TenantRequestStatus) => {
    if (!form.data.id) return;
    if (form.pending) return;
    form.setPending(true);
    apiClient.update(form.data.id, { ...form.data, status: status } as TenantRequest)
      .then(() => {
        Toast.success(t("common.status.success"), t("common.form.saved"));
        form.setPending(false);
        fetch();
      })
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  const onAccept = () => updateStatus(TenantRequestStatus.Verivied);
  const onDeny = () => updateStatus(TenantRequestStatus.Declined);

  const isBillingInfoFilled = (billingInfo?: TenantApplyBillingInfo) =>
    (billingInfo?.address || billingInfo?.city || billingInfo?.countryCode || billingInfo?.email || billingInfo?.name || billingInfo?.postCode || billingInfo?.vatId);

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    const data = {
      ...form.data,
      apply: {
        owner: form.data.apply?.owner,
        center: form.data.apply?.center,
        app: form.data.apply?.app,
        business: isBillingInfoFilled(form.data.apply?.business) ? form.data.apply?.business as TenantApplyBillingInfo : undefined,
        content: form.data.apply?.content,
        referralCode: form.data.apply?.referralCode,
      } as TenantApplyData
    } as TenantRequest;
    if (data.apply?.center?.email === '') data.apply.center.email = undefined;
    if(data.apply?.center?.phoneNumber === '') data.apply.center.phoneNumber = undefined;
    data.status = Number(data.status);
    if (data.apply?.app && form.data.apply?.app) {
      data.apply.app.period = Number(form.data.apply.app.period);
    }
    if (id !== 'create') {
      apiClient.update(id, data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/licensing/tenant-requests/`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const notify = () => {
    if (!form.data.id) return;
    form.setPending(true);
    apiClient.notify(form.data.id, { id: form.data.id, crmPassword: passwords?.crm, appPassword: passwords?.tenant } as TenantNotify)
      .then(() => {
        Toast.success(t("common.status.success"), t('common.mail.sent'));
        form.setPending(false);
      })
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "licensing.tenantRequests.group", href: "/panel/licensing/tenant-requests/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/licensing/tenant-requests/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    form.setReadOnly(!updateClaim);
  }, [updateClaim])

  const normalizeSubdomain = (text?: string) =>
    // eslint-disable-next-line no-control-regex
    text?.replace(/[^\x00-\x7F]/g, "").toLowerCase().replace(' ', '-').replace('.', '-').replace('_', '-') ?? undefined;

  useEffect(
    () => form.setClean('apply.app.subdomain', normalizeSubdomain(form.data?.apply?.app?.subdomain)),
    [form.data?.apply?.app?.subdomain]
  );

  if (authGuard === undefined) return <AuthGuardLoading />

  const domain = window.location.hostname.split('.').slice(-2).join('.');

  console.log(form.data, form.data.apply, form.data.apply?.business);

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header tenantRequest={form.data} onDelete={onDelete} onDeploy={onDeploy} onAccept={onAccept} onDeny={onDeny} form={form} />
        {passwords && <Alert.Success title="Utworzono tenanta" noClose>
          <div className="flex flex-row justify-between">
            <div>
              <h4>Dane do logowania w CRM:</h4>
              <p>Adres: https://{domain}/</p>
              <p>Użytkownik: {form.data.apply?.owner?.email}</p>
              <p>Hasło: {passwords.crm}</p>
              <h4>Dane do logowania w tenancie:</h4>
              <p>Adres: https://{form.data.apply?.app?.subdomain}.{domain}/</p>
              <p>Użytkownik: {form.data.apply?.owner?.email}</p>
              <p>Hasło: {passwords.tenant}</p>
            </div>
            <div>
              <Button colorName="green" className="px-5 py-3" onClick={notify}>
                Wyślij mail on-boardingowy
              </Button>
            </div>
          </div>
        </Alert.Success>}
        <GeneralForm form={form} />
        <ApplyOwnerForm form={form} />
        <ApplyCenterForm form={form} />
        <ApplyAppForm form={form} />
        <ApplyBusinessForm form={form} />
        <div className="text-end mt-5">
          <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
            {t('common.actions.save')}
          </Button>
        </div>
      </form >
    </>
  )
}

export default TenantRequestsForm;