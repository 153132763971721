
interface Region {
  key: string;
  countryCodes: string[];
}

const regions: Region[] = [
  {
    key: "eu",
    countryCodes: ["at", "be", "bg", "cy", "cz", "de", "dk", "ee", "es", "fi", "fr", "gb", "gr", "hr", "hu", "ie", "it", "lt", "lu", "lv", "mt", "nl", "pt", "ro", "se", "si", "sk"]
  },
];

export default function countryCodeToRegion(countryCode?: string): string | undefined {
  countryCode = countryCode?.toLowerCase();
  if (!countryCode) return undefined;
  return regions.find(region => region.countryCodes.includes(countryCode!))?.key || countryCode;
}