import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import FormInput from 'src/components/Form/FormInput';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { Rider, RidersClient } from 'src/api/stable/Stable';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faCheck, faEdit, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'src/components/User/Avatar';
import { QueryOrderDirection } from 'src/api/Base';
import { BoolIcon } from '../../Booking/Trainings/Table';
import Spinner from 'src/components/Feedback/Spinner';
import FormCheckbox from 'src/components/Form/FormCheckbox';

interface RiderFilters {
  givenName?: string;
  surname?: string;
  email?: string;
  phoneNumber?: string;
  unverified?: boolean;
}

function prepareFilters(form: IForm<RiderFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.givenName) f.push({ property: 'user.givenName', value: form.data.givenName, type: '%' });
  if (form.data.surname) f.push({ property: 'user.surname', value: form.data.surname, type: '%' });
  if (form.data.email) f.push({ property: 'user.email', value: form.data.email, type: '%' });
  if (form.data.phoneNumber) f.push({ property: 'user.phoneNumber', value: form.data.phoneNumber, type: '%' });
  if (form.data.unverified) f.push({ property: 'isVerified', value: '', type: 'isNull' });
  return f;
}

function renderFiltersForm(form: IForm<RiderFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <FormInput.Default {...form.input("givenName", "text", { placeholder: t('users.fields.givenName') })} />
        <FormInput.Default {...form.input("surname", "text", { placeholder: t('users.fields.surname') })} />
        <FormInput.Default {...form.input("email", "text", { placeholder: t('auth.fields.email') })} />
        <FormInput.Default {...form.input("phoneNumber", "text", { placeholder: t('auth.fields.phoneNumber') })} />
        <FormCheckbox.Input {...form.input("unverified", "checkbox", { value: 'true', placeholder: t('common.fields.unverified') })} />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const RidersTable = () => {
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new RidersClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<Rider>>();
  const updateClaim = useClaim('RidersUpdate');
  const deleteClaim = useClaim('RidersDelete');
  const authGuard = useAuthGuard('/panel/', ['RidersRead']);
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastRider() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Stable)}/hubs/RidersHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: Rider) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
      }
  }

  const columns: ITableColumn<Rider>[] = [
    { label: '', selector: row => <Avatar userId={row.userId} className="h-8 w-8 rounded-full ring-1" />, id: "translations.name" },
    { label: t('users.item'), selector: row => <span>{row.user?.fullName}</span>, id: "user.fullName" },
    { label: t('auth.fields.email'), selector: row => <span>{row.user?.email}</span>, id: "user.email" },
    { label: t('auth.fields.phoneNumber'), selector: row => <span>{row.user?.phoneNumber}</span>, id: "user.phoneNumber" },
    { label: t('common.fields.isVerified'), selector: row => row.isVerified !== null ? <BoolIcon value={row.isVerified} className="size-4" /> : <RiderVerification row={row} onSubmit={()=>tableRef.current?.refresh()} />, isOrderable: true, id: "user.isVerified" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="flex justify-end gap-x-3">
        {updateClaim &&
          <LocalizedLink to={`/panel/stable/riders/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && row.id && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.riders.group", href: "/panel/stable/riders/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="pt-2">
          <h1 className="leading-1 text-2xl">{t('stable.riders.group')}</h1>
        </div>
        <div className="flex gap-x-3">
          {updateClaim &&
            <LocalizedLink to="/panel/stable/riders/create">
              <Button colorName="emerald" className="px-5 py-3 text-md">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('stable.riders.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<Rider>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        defaultOrder={{ property: 'created', direction: QueryOrderDirection.DESC }}
        filters={filters}
      />
    </>
  )
}

function RiderVerification({ row, onSubmit }: { row: Rider, onSubmit: () => void }) {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new RidersClient(apiConfiguration);
  const [ loading, setLoading ] = useState(false);

  const onClickAccept = () => {
    setLoading(true);
    apiClient.update(row.id!, {...row, isVerified: true} as Rider)
      .then(() => {
        Toast.success(t("common.status.success"), t("common.feedback.updated"));
        if(onSubmit) onSubmit();
      })
      .catch(() => Toast.error(t("common.status.error"), t("common.errors.update")))
      .finally(() => setLoading(false));
  }

  const onClickDeny = () => {
    setLoading(true);
    apiClient.update(row.id!, {...row, isVerified: false} as Rider)
      .then(() => {
        Toast.success(t("common.status.success"), t("common.feedback.updated"));
        if (onSubmit) onSubmit();
      })
      .catch(() => Toast.error(t("common.status.error"), t("common.errors.update")))
      .finally(() => setLoading(false));
  }
  
  return (
    <div className="flex flex-row gap-x-3">
      {loading && <Spinner />}
      <Button colorName="green" className="flex-1" onClick={onClickAccept} disabled={loading}>
        <FontAwesomeIcon icon={faCheck} />
      </Button>
      <Button colorName="red" className="flex-1" onClick={onClickDeny} disabled={loading}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </div>
  );
}

export default RidersTable;