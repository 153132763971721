import { faHatCowboySide, faSave, faTimesCircle, faPlusCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { License, LicensePeriod, Product, ProductTranslation, Subscription, SubscriptionsClient } from 'src/api/licensing/Licensing';
import Button from "src/components/Actions/Button";
import { PromptDialog } from 'src/components/Dialogs/Dialog';
import Toast from 'src/components/Feedback/Toast';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import Avatar from 'src/components/User/Avatar';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useClaim from "src/hooks/useClaim";
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { IForm } from "src/hooks/useForm";

export interface LicenseHeaderComponentProps {
  License: License;
  onDelete: () => void;
  products: Product[];
  form: IForm<License>
}

export default (props: LicenseHeaderComponentProps) => {
  const { License, onDelete, form, products } = props;
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new SubscriptionsClient(apiConfiguration);

  const updateClaim = useClaim('LicensesUpdate');
  const deleteClaim = useClaim('LicensesDelete');

  const [open, setOpen] = useState(false);
  const [selectedProductId, selectProductId] = useState<string | undefined>(undefined);

  const add = async () => {
    form.setPending(true);
    try
    {
      if (!selectedProductId) return;
      if(!form.data.id) return;
      const dto = {
        licenseId: form.data.id,
        productId: selectedProductId,
        period: form.data.subscriptions?.[0]?.period ?? LicensePeriod.Monthly
      } as Subscription;
      const subscription = await apiClient.create(dto);
      const updatedData = {
        ...form.data,
        subscriptions: [
          ...form.data.subscriptions ?? [],
          subscription
        ]
      } as License;
      form.setData(updatedData);
      console.log(subscription, updatedData);
      Toast.success(t('common.status.success'), t('common.feedback.updated'));
    }
    catch
    {
      Toast.error(t('common.status.error'), t('common.errors.500'));
    }
    finally
    {
      form.setPending(false);
    }
  }

  const productTranslation = useEntityTranslation<Product, ProductTranslation>();
  
  return (
    <>
      <PromptDialog
        open={open}
        setOpen={setOpen}
        title={t('stable.riders.verifyAll')}
        onClickYes={() => add()}
        yesText={t('common.actions.add')}
        closeOnFeedback
      >
        <div className="flex flex-col gap-y-5">
          <Select
            value={selectedProductId}
            onChange={(e) => selectProductId(e.target.value)}
            options={products
              ?.filter(p => !form.data.subscriptions?.map(s => s.productId).some(pid => pid === p.id))
              .map(p => ({ id: p.id, value: p.id, label: productTranslation.getCurrentTranslation(p)?.name })) as TextOptionProps[]}
          />
        </div>
      </PromptDialog>
      <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
        <div className="flex items-start space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              {!form.data.userId && <FontAwesomeIcon icon={faHatCowboySide} className="h-16 p-3 text-[--color-primary-500]" />}
              {form.data.userId && <Avatar userId={form.data.userId} className="h-16 w-16 rounded-full ring-1" />}
              <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
            </div>
          </div>
          <div className="pt-1.5">
            <h1 className="text-2xl font-medium text-gray-900">{form.data.id || t('licensing.licenses.actions.create')}</h1>
            <div className="text-sm font-medium text-gray-500">
              {License.created && <span>{t('common.fields.created')}: {License.created?.toLocaleString()}</span>}
              {License.updated && <span>, {t('common.fields.updated')}: {License.updated?.toLocaleString()}</span>}
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          {updateClaim && form.data.id && <Button className="text-md px-5 py-3" type="button" colorName="green" onClick={() => setOpen(true)} disabled={form.pending}><FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> {t('common.actions.add')}</Button>}
          {updateClaim && <Button className="text-md px-5 py-3" colorName="primary" disabled={form.pending}><FontAwesomeIcon icon={form.data.id ? faSave : faPlusCircle} className="mr-1" /> {form.data.id ? t('common.actions.save') : t('common.actions.add')}</Button>}
          {deleteClaim && form.data.id && <Button className="text-md px-5 py-3" type="button" colorName="red" onClick={onDelete} disabled={form.pending}><FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> {t('common.actions.delete')}</Button>}
        </div>
      </div>
    </>
  )
}
