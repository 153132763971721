import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { License, LicensePeriod, Product, ProductTranslation, Subscription, SubscriptionRenewal } from 'src/api/licensing/Licensing';
import Button from 'src/components/Actions/Button';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import FormInput from 'src/components/Form/FormInput';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import useForm, { IForm } from 'src/hooks/useForm';

export interface SubscriptionFormComponentProps {
  form: IForm<License>;
  subscription: Subscription;
  index: number;
  products: Product[];
  visible?: boolean;
}

export default function SubscriptionForm(props: SubscriptionFormComponentProps) {
  const { form, subscription, index, products, visible } = props;
  const { i18n, t } = useTranslation();
  const productTranslation = useEntityTranslation<Product, ProductTranslation>();

  const renewalForm = useForm<SubscriptionRenewal>({ price: 0} as SubscriptionRenewal);

  function removeRenewal(i: number): void {
    form.setData({
      ...form.data,
      subscriptions: form.data.subscriptions?.map((s, j) => j === index ? { ...s, renewals: s.renewals?.filter((r, k) => k !== i) } : s)
    } as License);
  }

  function onClickAddRenewal(): void {
    if (!renewalForm.data.renewalDate) return;
    if (!renewalForm.data.expiryDate) return;
    if (!renewalForm.data.price && !renewalForm.data.isPaid) return;
    form.setData({
      ...form.data,
      subscriptions: 
        form.data.subscriptions
          ?.map((s, i) => i === index ? {
            ...s,
            renewals: [...s.renewals ?? [], { ...renewalForm.data, price: Number(renewalForm.data.price) } as SubscriptionRenewal]
          } : s)
    } as License);
  }

  if(!visible) return <></>;

  return (
    <FormSection
      title={t('licensing.license.form.subscription.header')}
      description={t('licensing.license.form.subscription.subheader')}
      full
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <FormInput.Clean
            value={productTranslation.getCurrentTranslation(products.find(p => p.id === subscription.productId))?.name}
            placeholder={t('licensing.products.item')}
            readOnly
          />
        </div>
        <div>
          <Select
            {...form.input(`subscriptions.${index}.period`, 'select', { placeholder: t('licensing.tenantRequests.fields.apply.app.period'), required: true })}
            options={Object.values(LicensePeriod).filter(p => !isNaN(Number(p))).map(p => ({ id: p, value: Number(p), label: t(`licensing.enums.licensePeriod.${p}`) } as TextOptionProps)) ?? []}
          />
        </div>
        <div>
          <FormCheckbox.Input
            {...form.input(`subscriptions.${index}.isAutomaticRenewalEnabled`, 'checkbox', { value: 'true', placeholder: t('licensing.subscriptions.fields.isAutomaticRenewalEnabled') })}
          />
        </div>
        <div>
          <FormCheckbox.Input
            {...form.input(`subscriptions.${index}.isCanceled`, 'checkbox', { value: 'true', placeholder: t('licensing.subscriptions.fields.isCanceled') })}
          />
        </div>
        <div>
          <FormInput.Clean value={t(`licensing.enums.licenseStatus.${subscription.status}`)} placeholder={t('common.fields.status')} readOnly />
        </div>
      </div>
      <h3 className="text-lg mt-10">Odnownienia subksrypcji</h3>
      <table className="w-full mt-5">
        <thead>
          <tr>
            <th className="text-left font-medium text-xs uppercase">Id</th>
            <th className="text-left font-medium text-xs uppercase">{t('licensing.subscriptions.fields.renewalDate')}</th>
            <th className="text-left font-medium text-xs uppercase">{t('licensing.subscriptions.fields.expiryDate')}</th>
            <th className="text-left font-medium text-xs uppercase">{t('licensing.subscriptions.fields.price')}</th>
            <th className="text-left font-medium text-xs uppercase">{t('common.fields.isPaid')}</th>
            <th className="text-left font-medium text-xs uppercase">{t('common.fields.created')}</th>
            <th className="text-right"></th>
          </tr>
        </thead>
        <tbody>
          {subscription.renewals?.map((r, i) => (
            <tr key={i} className="text-sm">
              <td>{r.id}</td>
              <td>{r.renewalDate?.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'short' })}</td>
              <td>{r.expiryDate?.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'short' })}</td>
              <td>{r.price?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</td>
              <td>{t(`common.bool.${r.isPaid ? 'yes' : 'no'}`)}</td>
              <td>{r.created?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'short', timeStyle: 'short' })}</td>
              <td>
                <Button type="button" colorName="red" onClick={() => removeRenewal(i)}>
                  <FontAwesomeIcon icon={faTimes} className="size-4" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="bg-gray-50">
          <tr>
            <td>
              {t('common.actions.add')}:
            </td>
            <td>
              <FormDatePicker
                {...renewalForm.input('renewalDate', 'date', { })}
                value={renewalForm.data.renewalDate}
                onChange={(date) => renewalForm.setData({ ...renewalForm.data, renewalDate: date, expiryDate: moment(date).add(1, 'month').toDate() } as SubscriptionRenewal)}
              />
            </td>
            <td>
              <FormDatePicker
                {...renewalForm.input('expiryDate', 'date', {  })}
                value={renewalForm.data.expiryDate}
                onChange={(date) => renewalForm.setData({ ...renewalForm.data, expiryDate: date } as SubscriptionRenewal)}
              />
            </td>
            <td>
              <FormInput.Default
                {...renewalForm.input('price', 'number', {  })}
              />
            </td>
            <td>
              <FormCheckbox.Input
                {...renewalForm.input('isPaid', 'checkbox', { value: 'true', placeholder: t('common.bool.yes') })}
              />
            </td>
            <td>

            </td>
            <td>
              <Button type="button" colorName="green" onClick={onClickAddRenewal} disabled={!renewalForm.data.renewalDate || !renewalForm.data.expiryDate || (!renewalForm.data.price && !renewalForm.data.isPaid)}>
                <FontAwesomeIcon icon={faPlus} className="size-4" />
              </Button>
            </td>
          </tr>
        </tfoot>
      </table>
    </FormSection>
  )
}